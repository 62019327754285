import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { clearErrors, deleteUser, getAllUsers } from "../../actions/userAction";
import { DELETE_USER_RESET } from "../../constants/userConstants";
import Actions from "../../components/Admin/Actions";
import "./colstyle.css";

const useUserTable = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    users,
    error,
    usersCount,
    loading: usersLoading,
  } = useSelector((state) => state.users);
  const {
    loading,
    isDeleted,
    error: deleteError,
  } = useSelector((state) => state.profile);

  const [filterModel, setFilterModel] = useState({ items: [] });

  const [sortModel, setSortModel] = useState([]);

  const [rowCountState, setRowCountState] = useState(
    typeof usersCount === "number" ? usersCount : 100
  );

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: Math.min(100, rowCountState),
  });

  useEffect(() => {
    if (usersCount) {
      setRowCountState(usersCount);
    }
  }, [usersCount]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (deleteError) {
      enqueueSnackbar(` ${deleteError}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (isDeleted) {
      enqueueSnackbar("User Deleted Successfully", {
        variant: "success",
      });
      dispatch({ type: DELETE_USER_RESET });
    }

    dispatch(
      getAllUsers(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel,
        sortModel
      )
    );
    // eslint-disable-next-line
  }, [
    dispatch,
    paginationModel.page,
    paginationModel.pageSize,
    sortModel,
    filterModel,
    error,
    deleteError,
    isDeleted,
    enqueueSnackbar,
  ]);

  const deleteUserHandler = (id) => {
    dispatch(deleteUser(id));
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-2 scrollable overflow-x-auto">
            {params.row.name}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 0.2,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 120,
      flex: 0.2,
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 100,
      flex: 0.2,
      renderCell: (params) => {
        return (
          <>
            {params.row.role === "admin" ? (
              <span className="text-sm bg-green-100 p-1 px-2 font-poppins500 rounded-full text-green-800 capitalize">
                {params.row.role}
              </span>
            ) : (
              <span className="text-sm bg-purple-100 p-1 px-2 font-poppins500 rounded-full text-purple-800 capitalize">
                {params.row.role}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Registered On",
      type: "date",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.3,
      type: "number",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Actions
            editRoute={"user"}
            deleteHandler={deleteUserHandler}
            id={params.row.id}
            name={params.row.name}
          />
        );
      },
    },
  ];

  const rows = [];

  users?.forEach((item) => {
    rows.push({
      id: item._id,
      name: item.name,
      email: item.email,
      phone: item.phone,
      role: item.role,
      createdAt: new Date(item.createdAt),
    });
  });

  return {
    columns,
    rows,
    usersLoading,
    loading,
    paginationModel,
    setPaginationModel,
    filterModel,
    setFilterModel,
    rowCountState,
    sortModel,
    setSortModel,
  };
};

export default useUserTable;
