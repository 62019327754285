import React from "react";

const TermsofService = () => {
  return (
    <div className="flex flex-col gap-5 mt-5 p-5 bg-primary-lightGrey rounded-xl">
      <div className="text-2xl font-poppins600  text-primary-blue align-center">
        Terms of Service
      </div>
      <div className="flex flex-col justify-between gap-2 text-gray-600 font-poppins400">
        <p className="font-poppins600 text-lg">
          Welcome to BooksDehra by Ramesh Book Depot! By accessing or using our
          website, you agree to comply with and be bound by the following terms
          and conditions:
        </p>
        <ul className="list-disc pl-5">
          <li className="mb-3">
            <strong>Acceptance of Terms:</strong> By using our website, you
            agree to these Terms of Service. If you do not agree with any part
            of these terms, you may not use our website.
          </li>
          <li className="mb-3">
            <strong>Account Registration:</strong> In order to make purchases
            and access certain features of our website, you may be required to
            create an account. You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            that occur under your account.
          </li>
          <li className="mb-3">
            <strong>Ordering and Payment:</strong> By placing an order on our
            website, you agree to pay the specified price for the products or
            services ordered. Payment can be made through the available payment
            methods listed on our website.
          </li>
          <li className="mb-3">
            <strong>Shipping and Delivery:</strong> We will make every effort to
            ensure prompt delivery of your orders. However, we are not
            responsible for delays caused by factors beyond our control, such as
            weather conditions or logistical issues.
          </li>
          <li className="mb-3">
            <strong>Intellectual Property:</strong> All content on our website,
            including text, images, and logos, is the property of BooksDehra by
            Ramesh Book Depot and is protected by copyright laws. You may not
            use, reproduce, or distribute any content from our website without
            our prior written consent.
          </li>
          <li className="mb-3">
            <strong>Limitation of Liability:</strong> We make no warranties or
            representations about the accuracy or completeness of the
            information on our website. In no event shall BooksDehra by Ramesh
            Book Depot be liable for any direct, indirect, incidental, special,
            or consequential damages arising out of or in connection with your
            use of our website.
          </li>
          <li className="mb-3">
            <strong>Governing Law:</strong> These Terms of Service shall be
            governed by and construed in accordance with the laws of India.
          </li>
          <li className="mb-3">
            <strong>Changes to Terms:</strong> We reserve the right to update or
            modify these Terms of Service at any time without prior notice. Your
            continued use of our website after any changes indicates your
            acceptance of the updated terms.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsofService;
