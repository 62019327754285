import React from "react";
import Rating from "@mui/material/Rating";
import { quote } from "../../../utils/images";

const TestimonialCard = ({
  name,
  imageSrc,
  testimonial,
  rating,
  isSmallScreen,
}) => {
  return (
    <div className="lg:w-1/3 flex flex-col items-center justify-center gap-6 ">
      <img
        src={`${imageSrc}`}
        className="w-16 sm:w-32 rounded-full shadow-lg dark:shadow-black/20"
        alt="user"
      />
      <div className="text-lg font-poppins600 text-center">{name}</div>
      <img src={quote} alt="quote" className="w-4 h-4 sm:w-8 sm:h-8" />
      {isSmallScreen ? (
        <p className="font-poppins400 text-center min-w-[15em] max-h-[10em] leading-[1.25em] text-ellipsis overflow-hidden ">
          {testimonial}
        </p>
      ) : (
        <p className="font-poppins400 text-center ">{testimonial}</p>
      )}
      <Rating
        name="read-only"
        value={rating}
        readOnly
        size={isSmallScreen ? "medium" : "large"}
        precision={0.5}
        sx={{
          color: "#FFC107",
        }}
      />
    </div>
  );
};

export default TestimonialCard;
