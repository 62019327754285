export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const ADD_TO_WISHLIST_FAIL = "ADD_TO_WISHLIST_FAIL";

export const REMOVE_FROM_WISHLIST_REQUEST = "REMOVE_FROM_WISHLIST_REQUEST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";
export const REMOVE_FROM_WISHLIST_FAIL = "REMOVE_FROM_WISHLIST_FAIL";

export const GET_WISHLIST_ITEMS_REQUEST = "GET_WISHLIST_ITEMS_REQUEST";
export const GET_WISHLIST_ITEMS = "GET_WISHLIST_ITEMS";
export const GET_WISHLIST_ITEMS_FAIL = "GET_WISHLIST_ITEMS_FAIL";

export const CLEAR_WISHLIST_REQUEST = "CLEAR_WISHLIST_REQUEST";
export const CLEAR_WISHLIST = "CLEAR_WISHLIST";
export const CLEAR_WISHLIST_FAIL = "CLEAR_WISHLIST_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
