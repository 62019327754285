import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useOrderConfirm = () => {
  const navigate = useNavigate();

  const purchaseItems = useSelector((state) => state.purchase.purchasing);
  const { user } = useSelector((state) => state.user);

  const continueHandler = () => {
    navigate("/process/payment");
  };

  return { purchaseItems, user, continueHandler };
};

export default useOrderConfirm;
