import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { human1, human2, human3 } from "../../../utils/images";
import { Link } from "react-router-dom";

const banners = [human1, human2, human3];

const Banner = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="flex flex-col lg:flex-row gap-4">
      <div className="lg:w-3/5 bg-primary-lightGrey p-2 md:p-8 xl:p-14 rounded-3xl flex flex-col gap-2 justify-around ">
        <div className="bg-white rounded-3xl text-base md:text-base p-1 md:p-2 border border-black w-4/5 sm:w-1/2 font-poppins600 text-center ">
          <span className="text-center">📚 The Legendary destination for all your Books</span>
        </div>
        <div className="hidden md:block font-poppins700 text-xl sm:text-3xl md:text-5xl lg:text-6xl mb-1 lg:mb-2">
          Get all your Books
          <br /> at one place
        </div>
        <div className="block md:hidden font-poppins700 text-xl sm:text-3xl md:text-5xl lg:text-6xl mb-1 lg:mb-2">
          Get all your Books at one place
        </div>
        <p className="mb-4 w-full lg:w-3/4 font-poppins500 text-md sm:text-xl md:text-2xl">
          Welcome to Ramesh Book Depot With over 64 years of experience, Ramesh
          Book Depot has been a cornerstone of the literary landscape in
          Dehradun. We offer the widest variety of books, including medical,
          ayurvedic, and engineering books.
        </p>
        <div className="flex font-poppins500">
          <Link to="/about-us">
            {" "}
            <button className="bg-transparent text-black hover:bg-black hover:text-white px-2 py-1 md:px-4 md:py-2 rounded-full text-sm md:text-base mr-2 border-2 border-black">
              LEARN MORE
            </button>
          </Link>
          <Link to="/products">
            <button className="bg-transparent text-black hover:bg-black hover:text-white px-2 py-1 md:px-4 md:py-2 rounded-full text-sm md:text-base border-2 border-black">
              BROWSE BOOKS
            </button>
          </Link>
        </div>
      </div>
      <div className="hidden lg:block w-full lg:w-2/5 bg-primary-beige rounded-3xl  ">
        {/* <div className="z-20 lg:mr-14 lg:-ml-8 lg:absolute">
          <img
            draggable="false"
            className="h-96 w-96 lg:h-full lg:w-full object-fill "
            src={human1}
            alt="banner"
          />
        </div> */}
        <Slider {...settings} className="z-20 mt-10 lg:-ml-28 lg:absolute">
          {banners.map((el, i) => (
            <img
              draggable="false"
              className="h-96 w-96 lg:h-full lg:w-full object-fill"
              src={el}
              alt="banner"
              key={i}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Banner;
