import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";

import {
  forgotPasswordReducer,
  profileReducer,
  userReducer,
  allUsersReducer,
  userDetailsReducer,
} from "./reducers/userReducer";
import {
  newProductReducer,
  newReviewReducer,
  productDetailsReducer,
  productReducer,
  productsReducer,
  productReviewsReducer,
  reviewReducer,
  bestSellerProductsReducer,
  adminProductsReducer,
  mainDataReducer,
  similarProductsReducer,
} from "./reducers/productReducer";
import { cartReducer } from "./reducers/cartReducer";
import { saveForLaterReducer } from "./reducers/saveForLaterReducer";
import {
  allOrdersReducer,
  myOrdersReducer,
  newOrderReducer,
  orderDetailsReducer,
  orderReducer,
  exportOrdersReducer,
} from "./reducers/orderReducer";
import { wishlistReducer } from "./reducers/wishlistReducer";
import { addressReducer } from "./reducers/addressReducer";
import { purchaseReducer } from "./reducers/purchaseReducer";

const rootReducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  products: productsReducer,
  similarProducts: similarProductsReducer,
  adminProducts: adminProductsReducer,
  bestSellers: bestSellerProductsReducer,
  productDetails: productDetailsReducer,
  newReview: newReviewReducer,
  cart: cartReducer,
  saveForLater: saveForLaterReducer,
  newOrder: newOrderReducer,
  myOrders: myOrdersReducer,
  orderDetails: orderDetailsReducer,
  allOrders: allOrdersReducer,
  exportOrders: exportOrdersReducer,
  order: orderReducer,
  newProduct: newProductReducer,
  product: productReducer,
  users: allUsersReducer,
  userDetails: userDetailsReducer,
  reviews: productReviewsReducer,
  review: reviewReducer,
  wishlist: wishlistReducer,
  address: addressReducer,
  purchase: purchaseReducer,
  mainData: mainDataReducer,
});

const middleware = [thunk];

const store = createStore(rootReducer, applyMiddleware(...middleware));

export default store;
