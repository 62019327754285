import { useState } from "react";
import Sidebar from "./Sidebar";
import { MenuIcon } from "../../utils/iconImports";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

const UserDashboard = ({ activeTab, children }) => {
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const toggleSidebarHandler = () => {
    setToggleSidebar(!toggleSidebar);
  };

  const closeSidebar = () => {
    setToggleSidebar(false);
  };

  return (
    <main className="relative flex flex-col md:flex-row gap-2 md:gap-3 mx-auto md:w-11/12 mt-16 md:mt-24 ">
      {!isMobile && <Sidebar activeTab={activeTab} isMobile={isMobile} />}
      {isMobile && (
        <button
          onClick={toggleSidebarHandler}
          className="bg-gray-700 w-8 h-8 rounded-full shadow text-white flex items-center justify-center"
        >
          <MenuIcon />
        </button>
      )}
      {toggleSidebar && (
        <Sidebar
          activeTab={activeTab}
          isMobile={isMobile}
          toggleSidebar={toggleSidebar}
          closeSidebar={closeSidebar}
        />
      )}

      <div className="flex flex-col gap-6 sm:m-6  md:w-3/4 ">{children}</div>
    </main>
  );
};

UserDashboard.propTypes = {
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default UserDashboard;
