import { CheckIcon } from "../../utils/iconImports";
import PropTypes from "prop-types";
import useStepper from "../../hooks/purchasing/stepper";
import React from "react";

const Stepper = ({ activeStep, children }) => {
  const { steps } = useStepper();

  return (
    <div className="flex flex-col gap-4">
      {steps?.map((step, index) => {
        return (
          <React.Fragment key={step.label}>
            {activeStep === index ? (
              <div className="flex flex-col rounded-xl">
                <div className="flex items-center rounded-t-xl bg-primary-blue px-6 py-2 gap-4">
                  <span className="h-5 w-5 flex items-center justify-center text-xs font-poppins500 bg-white rounded-sm text-primary-blue">
                    {index + 1}
                  </span>
                  <div className="font-poppins500 text-white">{step.label}</div>
                </div>
                {children}
              </div>
            ) : (
              <>
                {activeStep > index ? (
                  <Step isDesc={true} {...step} index={index} />
                ) : (
                  <Step isDesc={false} {...step} index={index} />
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

const Step = ({ isDesc, label, desc, index }) => {
  return (
    <div className="flex shadow px-4 py-3 pb-4 rounded-xl">
      <span className="mt-2 ml-2 mr-4 h-5 w-5 flex items-center justify-center text-xs font-poppins500 bg-gray-100 rounded-lg text-primary-blue">
        {index + 1}
      </span>
      <div className="flex flex-col mt-1 gap-0.5">
        <div className="font-poppins500 text-gray-500 flex items-center gap-2">
          {label}
          {isDesc && (
            <span className="text-primary-blue mb-1">
              <CheckIcon sx={{ fontSize: "20px" }} />
            </span>
          )}
        </div>
        {isDesc && desc}
      </div>
    </div>
  );
};

Step.propTypes = {
  isDesc: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  desc: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};

export default Stepper;
