export const GET_SHIPPING_INFO = "GET_SHIPPING_INFO";
export const GET_SHIPPING_INFO_REQUEST = "GET_SHIPPING_INFO_REQUEST";
export const GET_SHIPPING_INFO_FAIL = "GET_SHIPPING_INFO_FAIL";

export const SAVE_SHIPPING_INFO_REQUEST = "SAVE_SHIPPING_INFO_REQUEST";
export const SAVE_SHIPPING_INFO = "SAVE_SHIPPING_INFO";
export const SAVE_SHIPPING_INFO_FAIL = "SAVE_SHIPPING_INFO_FAIL";

export const REMOVE_SHIPPING_INFO_REQUEST = "REMOVE_SHIPPING_INFO_REQUEST";
export const REMOVE_SHIPPING_INFO = "REMOVE_SHIPPING_INFO";
export const REMOVE_SHIPPING_INFO_FAIL = "REMOVE_SHIPPING_INFO_FAIL";

export const CLEAR_SHIPPING_INFO_REQUEST = "CLEAR_SHIPPING_INFO_REQUEST";
export const CLEAR_SHIPPING_INFO = "CLEAR_SHIPPING_INFO";
export const CLEAR_SHIPPING_INFO_FAIL = "CLEAR_SHIPPING_INFO_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
