import { useState } from "react";
import axios from "axios";
import {
  address1,
  addressLink1,
  phone1,
  mail1,
  address2,
  addressLink2,
  phone2,
  phone3,
  mail2,
  instaLink,
  fbLink,
  linkedinLink,
} from "../../utils/businessConstants";

import {
  whiteMail,
  whitePhone,
  whitePin,
  blueFb,
  blueInsta,
  blueLinkedin,
  account,
  sendMessage,
  mail,
  phone,
} from "../../utils/images";
import { useSnackbar } from "notistack";

const ContactUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const handleSendMessage = async () => {
    if (firstName && lastName && phoneNo && email && message) {
      const formData = {
        firstName,
        lastName,
        phoneNo,
        email,
        message,
      };

      try {
        await axios.post("/api/v1/sendmail", formData);
        enqueueSnackbar("Message Sent Successfully", {
          variant: "success",
        });

        setFirstName("");
        setLastName("");
        setPhoneNo("");
        setEmail("");
        setMessage("");
      } catch (error) {
        enqueueSnackbar("Failed to send message", { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please fill all the fields", {
        variant: "info",
      });
    }
  };

  return (
    <div className="max-w-6xl mx-auto bg-white my-6 gont-poppins400 text-[#011c2b] mt-14">
      <div className="text-center px-6">
        <div className="text-3xl font-poppins700">Contact Us</div>
        <p className="text-sm text-gray-400 mt-3">
          Have some queries or want to order books in bulk?
        </p>
      </div>
      <div className="grid lg:grid-cols-3 items-center gap-4 p-2 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-lg mt-8">
        <div className="bg-[#011c2b] rounded-xl p-6 max-lg:text-center font-poppins400">
          <div className="text-xl font-poppins600 text-white">
            Contact Information
          </div>
          <p className="text-sm text-gray-400 mt-3 ">
            Have some queries or want to order in bulk? Feel free to contact us
          </p>
          <div className="mt-8 md:mt-16 space-y-6">
            <div className="flex sm:items-start">
              <img src={whiteMail} alt="Mail" />

              <div className="flex flex-col ">
                <a
                  href={`mailto:${mail1}`}
                  className="text-white text-sm ml-3 hover:text-primary-blue hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>{mail1}</strong>
                </a>

                <a
                  href={`mailto:${mail2}`}
                  className="text-white text-sm ml-3 hover:text-primary-blue hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>{mail2}</strong>
                </a>
              </div>
            </div>
            <div className="flex items-start">
              <img src={whitePhone} alt="Phone" />
              <div className="flex flex-col font-poppins400">
                <a
                  href={`tel:${phone1}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white text-sm ml-3 hover:text-primary-blue hover:underline"
                >
                  <strong>{phone1}</strong>
                </a>
                <a
                  href={`tel:${phone2}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white text-sm ml-3 hover:text-primary-blue hover:underline"
                >
                  <strong>{phone2}</strong>
                </a>
                <a
                  href={`tel:${phone3}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white text-sm ml-3 hover:text-primary-blue hover:underline"
                >
                  <strong>{phone3}</strong>
                </a>
              </div>
            </div>
            <div className="flex items-start">
              <img src={whitePin} alt="Address" />
              <div className="flex flex-col items-center text-center font-poppins400">
                <a
                  href={addressLink1}
                  className="text-white text-xs ml-1 hover:text-primary-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>{address1}</strong>
                </a>
                <hr className="w-1/2 my-2 bg-white" />
                <a
                  href={addressLink2}
                  className="text-white text-xs ml-1 hover:text-primary-blue "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>{address2}</strong>
                </a>
              </div>
            </div>
          </div>
          <div className="flex max-lg:justify-center mt-16 space-x-4">
            <div className="bg-gray-800 hover:invert cursor-pointer h-10 w-10 rounded-full flex items-center justify-center shrink-0">
              <a href={fbLink} target="_blank" rel="noopener noreferrer">
                <img src={blueFb} alt="Facebook" />
              </a>
            </div>
            <div className="bg-gray-800 hover:invert cursor-pointer h-10 w-10 rounded-full flex items-center justify-center shrink-0 ">
              <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
                <img src={blueLinkedin} alt="Linkedin" />
              </a>
            </div>
            <div className="bg-gray-800 hover:invert cursor-pointer h-10 w-10 rounded-full flex items-center justify-center shrink-0">
              <a href={instaLink} target="_blank" rel="noopener noreferrer">
                <img src={blueInsta} alt="Instagram" />
              </a>
            </div>
          </div>
        </div>
        <div className="p-6 rounded-xl lg:col-span-2">
          <form>
            <div className="grid sm:grid-cols-2 gap-8">
              <div className="relative flex items-center">
                <input
                  type="text"
                  placeholder="First Name"
                  className="px-2 py-3 bg-white w-full text-sm border-b-2 focus:border-[#011c2b] outline-none font-poppins400"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <img src={account} alt="Account" className="h-5 w-5" />
              </div>
              <div className="relative flex items-center">
                <input
                  type="text"
                  placeholder="Last Name"
                  className="px-2 py-3 bg-white w-full text-sm border-b-2 focus:border-[#011c2b] outline-none font-poppins400"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <img src={account} alt="Account" className="h-5 w-5" />
              </div>
              <div className="relative flex items-center">
                <input
                  type="tel"
                  placeholder="Phone No."
                  className="px-2 py-3 bg-white text-black w-full text-sm border-b-2 focus:border-[#011c2b] outline-none font-poppins400"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
                <img src={phone} alt="Phone" className="h-5 w-5" />
              </div>
              <div className="relative flex items-center">
                <input
                  type="email"
                  placeholder="Email"
                  className="px-2 py-3 bg-white text-black w-full text-sm border-b-2 focus:border-[#011c2b] outline-none font-poppins400"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <img src={mail} alt="Mail" className="h-5 w-5" />
              </div>
              <div className="relative flex items-center sm:col-span-2">
                <textarea
                  placeholder="Write Message"
                  className="px-2 pt-3 bg-white text-black w-full text-sm border-b-2 focus:border-[#011c2b] outline-none font-poppins400"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
            <button
              type="button"
              className="mt-12 flex items-center justify-center text-sm lg:ml-auto max-lg:w-full rounded px-4 py-2.5 font-poppins500 bg-[#011c2b] text-white hover:invert"
              onClick={handleSendMessage}
            >
              <img src={sendMessage} alt="Send Message" className="mr-2" />
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
