import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { logoutUser } from "../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";

const useAdminSidebar = (isMobile, closeSidebar) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useSelector((state) => state.user);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleClick = () => {
    if (isMobile) {
      closeSidebar();
    }
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    setConfirmationOpen(false);
    enqueueSnackbar("Logout Successfully", {
      variant: "success",
    });
    navigate("/login");
  };

  return {
    confirmationOpen,
    user,
    setConfirmationOpen,
    handleLogout,
    handleClick,
  };
};

export default useAdminSidebar;
