import {
  FormControl,
  FormControlLabel,
  Pagination,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import {
  ExpandMoreIcon,
  ExpandLessIcon,
  StarIcon,
  CloseIcon,
  FilterListIcon,
} from "../../utils/iconImports";
import Loader from "../Layouts/Loader";
import Product from "./Product";
import { categories, exams } from "../../utils/constants";
import MetaData from "../Layouts/MetaData";
import useProducts from "../../hooks/products/products";

const Products = () => {
  const {
    price,
    category,
    exam,
    ratings,
    bestSeller,
    currentPage,
    categoryToggle,
    examsToggle,
    ratingsToggle,
    products,
    loading,
    resultPerPage,
    filteredProductsCount,
    toggleFilterSidebar,
    productsFound,
    totalPages,
    toggleFilterSidebarHandler,
    closeFilterSidebarHandler,
    priceHandler,
    clearFilters,
    handleCategoryChange,
    setCurrentPage,
    handleExamChange,
    handleRatingsChange,
    handleBestSellerChange,
    setCategoryToggle,
    setExamsToggle,
    setRatingsToggle,
  } = useProducts();

  return (
    <>
      <MetaData title="All Products | BooksDehra" />
      <main className="w-full mt-16 md:mt-24">
        <div className="flex flex-col sm:flex-row sm:gap-3 mt-2 sm:mx-3 m-auto mb-7">
          <button
            onClick={toggleFilterSidebarHandler}
            className="sm:hidden bg-gray-700 w-8 h-8 rounded-full shadow text-white flex items-center justify-center ml-2 mb-1"
          >
            <FilterListIcon />
          </button>
          <div
            className={`fixed inset-0 px-1 z-20 sm:z-0 transform transition-transform w-1/3 md:w-1/4 lg:w-1/5 ${
              toggleFilterSidebar
                ? "overflow-y-auto max-h-full translate-x-0 w-4/5 z-[5000]"
                : "-translate-x-full"
            } sm:translate-x-0 sm:static bg-white sm:bg-transparent `}
          >
            <div className="flex flex-col rounded-sm shadow">
              <div className="flex items-center justify-between gap-5 px-4 py-2 border-b">
                <p className="text-lg font-poppins500">Filters</p>

                {toggleFilterSidebar ? (
                  <button
                    className="uppercase text-primary-blue text-xs cursor-pointer font-poppins500"
                    onClick={() => clearFilters()}
                  >
                    clear all
                  </button>
                ) : (
                  <button
                    className="uppercase text-primary-blue text-xs cursor-pointer font-poppins500 hidden sm:block"
                    onClick={() => clearFilters()}
                  >
                    clear all
                  </button>
                )}
                {toggleFilterSidebar && (
                  <button
                    onClick={closeFilterSidebarHandler}
                    className="sm:hidden bg-gray-700 w-6 h-6 rounded-full shadow text-white flex items-center justify-center"
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>

              <div className="flex flex-col gap-2 py-3 overflow-hidden px-2.5">
                <div className="flex flex-col gap-2 border-b px-4">
                  <span className="font-poppins500 text-sm">PRICE</span>

                  <Slider
                    value={price}
                    onChange={priceHandler}
                    valueLabelDisplay="auto"
                    getAriaLabel={() => "Price range slider"}
                    min={0}
                    max={50000}
                  />

                  <div className="flex gap-3 items-center justify-between mb-2 min-w-full text-sm">
                    <span className="flex-1 border px-4 py-1 rounded-sm text-gray-800 bg-gray-50">
                      ₹{price[0].toLocaleString()}
                    </span>
                    <span className="font-poppins500 text-gray-400">to</span>
                    <span className="flex-1 border px-4 py-1 rounded-sm text-gray-800 bg-gray-50">
                      ₹{price[1].toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2 border-b px-4">
                  <span className="font-poppins500 text-sm">Best Seller</span>
                  <div className="flex items-center justify-start ">
                    <Radio
                      checked={bestSeller}
                      onClick={handleBestSellerChange}
                      size="small"
                    />
                    <span className="font-poppins400 sm:text-xs">
                      Best Seller
                    </span>
                  </div>
                </div>
                <div className="flex flex-col border-b px-4">
                  <button
                    className="flex justify-between cursor-pointer py-2 pb-4 items-center"
                    onClick={() => setCategoryToggle(!categoryToggle)}
                  >
                    <p className="font-poppins500 text-sm uppercase">
                      Category
                    </p>
                    {categoryToggle ? (
                      <ExpandLessIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                    )}
                  </button>

                  {categoryToggle && (
                    <div className="flex flex-col pb-1">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="category-radio-buttons-group"
                          onClick={handleCategoryChange}
                          name="category-radio-buttons"
                          value={category}
                        >
                          {categories?.map((category, i) => (
                            <FormControlLabel
                              value={category.name ?? ""}
                              key={category.name}
                              control={<Radio size="small" />}
                              label={
                                <span className="text-xs">{category.name}</span>
                              }
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                </div>
                <div className="flex flex-col border-b px-4">
                  <button
                    className="flex justify-between cursor-pointer py-2 pb-4 items-center"
                    onClick={() => setExamsToggle(!examsToggle)}
                  >
                    <p className="font-poppins500 text-sm uppercase">Exams</p>
                    {examsToggle ? (
                      <ExpandLessIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                    )}
                  </button>

                  {examsToggle && (
                    <div className="flex flex-col pb-1">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="category-radio-buttons-group"
                          onClick={handleExamChange}
                          name="exam-radio-buttons"
                          value={exam}
                        >
                          {exams?.map((exam, i) => (
                            <FormControlLabel
                              value={exam ?? ""}
                              key={exam}
                              control={<Radio size="small" />}
                              label={<span className="text-xs">{exam}</span>}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                </div>
                <div className="flex flex-col  px-4">
                  <button
                    className="flex justify-between cursor-pointer py-2 pb-4 items-center"
                    onClick={() => setRatingsToggle(!ratingsToggle)}
                  >
                    <p className="font-poppins500 text-sm uppercase">ratings</p>
                    {ratingsToggle ? (
                      <ExpandLessIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                    )}
                  </button>

                  {ratingsToggle && (
                    <div className="flex flex-col pb-1">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="ratings-radio-buttons-group"
                          onClick={handleRatingsChange}
                          value={ratings}
                          name="ratings-radio-buttons"
                        >
                          {[4, 3, 2, 1].map((el, i) => (
                            <FormControlLabel
                              value={el ?? ""}
                              key={el}
                              control={<Radio size="small" />}
                              label={
                                <span className="flex items-center text-xs">
                                  {el}
                                  <StarIcon
                                    sx={{ fontSize: "12px", mr: 0.5 }}
                                  />{" "}
                                  & above
                                </span>
                              }
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            {!loading && !productsFound && (
              <div className="flex flex-col items-center justify-center gap-3 shadow-sm rounded-sm p-6 sm:p-16">
                <img
                  draggable="false"
                  className="w-1/2 h-44 object-contain"
                  src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/error-no-search-results_2353c5.png"
                  alt="Search Not Found"
                />
                <div className="text-2xl font-poppins600 text-gray-900">
                  Sorry, no results found!
                </div>
                <p className="text-xl text-center font-poppins500 ">
                  Please check the spelling or try searching for something else
                </p>
              </div>
            )}

            {loading ? (
              <Loader />
            ) : (
              <div className="flex flex-col gap-2 pb-4 justify-center items-center w-full overflow-hidden ">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full place-content-start overflow-hidden pb-4 border-b">
                  {products?.map((product) => (
                    <Product {...product} key={product._id} />
                  ))}
                </div>
                {filteredProductsCount > resultPerPage && (
                  <>
                    {" "}
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(e, val) => setCurrentPage(val)}
                      color="primary"
                    />
                    <span className="text-sm font-poppins500 text-gray-500">
                      Showing {resultPerPage} of {filteredProductsCount} results
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Products;
