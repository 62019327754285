import { useState } from "react";
import { Link } from "react-router-dom";
import { DeleteIcon, EditIcon, InfoIcon } from "../../utils/iconImports";
import PropTypes from "prop-types";
import { Confirmation, ProductDetailsModal } from "../../utils/imports";

const Actions = ({
  id,
  deleteHandler,
  name,
  editRoute,
  product,
  productTable,
}) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const [showProductDetails, setShowProductDetails] = useState(false);

  const handleDelete = () => {
    deleteHandler(id);
    setConfirmationOpen(false);
  };

  return (
    <div className="flex justify-between items-center gap-3 text-xs">
      {productTable && (
        <button
          className="text-green-600 hover:bg-green-200 p-1.5 rounded-full bg-green-100"
          onClick={() => setShowProductDetails(true)}
        >
          <InfoIcon />
        </button>
      )}

      {editRoute !== "review" && (
        <Link
          to={`/admin/${editRoute}/${id}`}
          className="text-blue-600 hover:bg-blue-200 p-1 rounded-full bg-blue-100"
        >
          <EditIcon />
        </Link>
      )}
      <button
        onClick={() => {
          setItemToDelete(name);
          setConfirmationOpen(true);
        }}
        className="text-red-600 hover:bg-red-200 p-1 rounded-full bg-red-100"
      >
        <DeleteIcon />
      </button>
      <Confirmation
        open={confirmationOpen}
        confirmationText={`Are you sure you want to delete ${itemToDelete}?`}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleDelete}
        task="Delete"
      />
      <ProductDetailsModal
        isOpen={showProductDetails}
        onClose={() => setShowProductDetails(false)}
        product={product}
      />
    </div>
  );
};

Actions.propTypes = {
  id: PropTypes.string,
  deleteHandler: PropTypes.func,
  name: PropTypes.string,
  editRoute: PropTypes.string,
  product: PropTypes.object,
  userTable: PropTypes.bool,
};

export default Actions;
