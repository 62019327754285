import {
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  Modal,
} from "@mui/material";
import { PriceSidebar, MetaData, Stepper } from "../../utils/imports";
import states from "../../utils/states";
import useShipping from "../../hooks/purchasing/shipping";
const Shipping = () => {
  const {
    address,
    setAddress,
    city,
    setCity,
    country,
    setCountry,
    state,
    setState,
    pincode,
    setPincode,
    phoneNo,
    setPhoneNo,
    shippingSubmit,
    handleOrder,
    openModal,
    handleCloseModal,
    handleAddressSelect,
    addressSelected,
    savedAddressHandler,
    shippingInfo,
    purchaseItems,
  } = useShipping();

  return (
    <>
      <MetaData title="BooksDehra: Shipping Details" />
      <main className="mt-20">
        <div className="flex flex-col sm:flex-row gap-3.5 sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7 ">
          <div className="flex-1">
            <Stepper activeStep={1}>
              <div className="w-full ">
                <form
                  autoComplete="off"
                  className="flex flex-col justify-start gap-3 w-full sm:w-3/4 mx-1 sm:mx-8 my-4"
                >
                  <TextField
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    fullWidth
                    label="Address"
                    variant="outlined"
                    required
                  />

                  <div className="flex gap-6">
                    <TextField
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      type="tel"
                      label="Pincode"
                      fullWidth
                      variant="outlined"
                      required
                    />
                    <TextField
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      type="tel"
                      label="Phone No"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </div>

                  <div className="flex gap-6">
                    <TextField
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      label="City"
                      fullWidth
                      variant="outlined"
                      required
                    />
                    <TextField
                      label="Landmark (Optional)"
                      fullWidth
                      variant="outlined"
                    />
                  </div>

                  <div className="flex gap-6">
                    <FormControl fullWidth disabled={!country}>
                      <InputLabel id="state-select">State</InputLabel>
                      <Select
                        labelId="state-select"
                        id="state-select"
                        value={state}
                        label="State"
                        onChange={(e) => setState(e.target.value)}
                        required
                      >
                        {states.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="country-select">Country</InputLabel>
                      <Select
                        labelId="country-select"
                        id="country-select"
                        defaultValue={country}
                        disabled
                        label="Country"
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <MenuItem value={"IN"}>India</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="flex flex-row justify-between">
                    {!addressSelected && (
                      <>
                        <button
                          onClick={shippingSubmit}
                          className="bg-primary-orange w-1/3 my-2  md:text-lg font-poppins500 text-white shadow hover:shadow-lg outline-none border rounded-xl border-primary-orange hover:bg-white hover:text-primary-orange transition-all duration-300 ease-in-out"
                        >
                          Save and Deliver
                        </button>
                        <button
                          className="bg-primary-blue w-1/3 my-2 md:text-lg font-poppins500 text-white shadow hover:shadow-lg  outline-none border rounded-xl border-primary-blue hover:bg-white hover:text-primary-blue transition-all duration-300 ease-in-out"
                          onClick={savedAddressHandler}
                        >
                          Choose from saved addresses
                        </button>
                      </>
                    )}
                    {addressSelected && (
                      <button
                        className="bg-primary-orange w-1/3 my-2 py-1 md:py-2 md:text-lg font-poppins500 text-white shadow hover:shadow-lg  outline-none border rounded-md border-primary-orange hover:bg-white hover:text-primary-orange transition-all duration-300 ease-in-out"
                        onClick={handleOrder}
                      >
                        Deliver Here
                      </button>
                    )}

                    <Modal open={openModal} onClose={handleCloseModal}>
                      <div className="fixed z-20 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                              <div className="text-lg font-poppins600 ">
                                Choose from saved addresses
                              </div>
                              <div className="modal-description">
                                {Array.isArray(shippingInfo) &&
                                  shippingInfo?.map((address, index) => (
                                    <div
                                      key={address._id}
                                      className="flex flex-col items-start gap-2 mb-4 border-b p-4"
                                    >
                                      <p>
                                        <span className="font-poppins600">
                                          Address:
                                        </span>{" "}
                                        {address.address}
                                      </p>
                                      <p>
                                        <span className="font-poppins600">
                                          City:
                                        </span>{" "}
                                        {address.city}
                                      </p>
                                      <p>
                                        <span className="font-poppins600">
                                          State:
                                        </span>{" "}
                                        {address.state}
                                      </p>
                                      <p>
                                        <span className="font-poppins600">
                                          Pincode:
                                        </span>{" "}
                                        {address.pincode}
                                      </p>
                                      <p>
                                        <span className="font-poppins600">
                                          Phone No:
                                        </span>{" "}
                                        {address.phoneNo}
                                      </p>

                                      <button
                                        onClick={() =>
                                          handleAddressSelect(address)
                                        }
                                        className="bg-primary-orange font-poppins500 text-lg text-white md:py-2 md:px-4 rounded-xl shadow-md hover:bg-yellow-500 transition-colors duration-300 ease-in-out"
                                      >
                                        Select
                                      </button>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                              <button
                                onClick={handleCloseModal}
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-poppins500 text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </form>
              </div>
            </Stepper>
          </div>
          {purchaseItems && <PriceSidebar purchaseItems={purchaseItems} />}
        </div>
      </main>
    </>
  );
};

export default Shipping;
