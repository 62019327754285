import { TextField, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { BackdropLoader, MetaData, FormSidebar } from "../../utils/imports";
import { VisibilityIcon, VisibilityOffIcon } from "../../utils/iconImports";
import useUpdatePassword from "../../hooks/user/updatePassword";

const UpdatePassword = () => {
  const {
    oldPassword,
    setOldPassword,
    showOldPassword,
    handleShowOldPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    showPassword,
    handleShowPassword,
    setConfirmPassword,
    updatePasswordSubmitHandler,
    loading,
  } = useUpdatePassword();

  return (
    <div className="flex flex-col items-center justify-center mt-16 md:mt-24">
      <MetaData title="Password Update | BooksDehra" />
      {loading && <BackdropLoader />}
      <main className="w-4/5 lg:w-3/4">
        <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-primary-lightGrey rounded-2xl shadow-lg">
          <FormSidebar
            title="Looks like you want to update password!"
            tag="Enter your current and new password to update"
          />
          <div className="flex-1 overflow-hidden">
            <div className="text-center text-2xl font-poppins500 mt-6 text-gray-800">
              Update Password
            </div>
            <form
              onSubmit={updatePasswordSubmitHandler}
              className="p-5 sm:p-14"
            >
              <div className="flex flex-col gap-4 items-start">
                <div className="flex flex-col w-full justify-between sm:flex-col gap-3 items-center">
                  <TextField
                    fullWidth
                    label="Current Password"
                    type={showOldPassword ? "text" : "password"}
                    name="oldPassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    InputProps={
                      showOldPassword
                        ? {
                            endAdornment: (
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={handleShowOldPassword}
                              >
                                <VisibilityOffIcon />
                              </IconButton>
                            ),
                          }
                        : {
                            endAdornment: (
                              <IconButton
                                aria-label="Show password"
                                onClick={handleShowOldPassword}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ),
                          }
                    }
                  />
                  <TextField
                    fullWidth
                    label="New Password"
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Confirm New Password"
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    InputProps={
                      showPassword
                        ? {
                            endAdornment: (
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={handleShowPassword}
                              >
                                <VisibilityOffIcon />
                              </IconButton>
                            ),
                          }
                        : {
                            endAdornment: (
                              <IconButton
                                aria-label="Show password"
                                onClick={handleShowPassword}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ),
                          }
                    }
                  />
                </div>
                <button
                  type="submit"
                  className="text-white py-3 w-full bg-primary-darkBlue shadow hover:shadow-lg rounded-xl font-poppins500"
                >
                  Update
                </button>
                <Link
                  className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-xl font-poppins500 mb-8"
                  to="/account"
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UpdatePassword;
