import { useLocation } from "react-router-dom";
import { loadUser, clearErrors } from "../actions/userAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useSnackbar } from "notistack";

const useApp = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // const { enqueueSnackbar } = useSnackbar();
  const { loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  useEffect(() => {
    // if (!loading && isAuthenticated) {
    //   dispatch(loadUser());
    // }
    dispatch(loadUser());
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return { loading };
};

export default useApp;
