export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";

export const REMOVE_FROM_CART_REQUEST = "REMOVE_FROM_CART_REQUEST";
export const REMOVE_FROM_CART_FAIL = "REMOVE_FROM_CART_FAIL";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const EMPTY_CART_REQUEST = "EMPTY_CART_REQUEST";
export const EMPTY_CART_FAIL = "EMPTY_CART_FAIL";
export const EMPTY_CART = "EMPTY_CART";

export const GET_CART_ITEMS_REQUEST = "GET_CART_ITEMS_REQUEST";
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const GET_CART_ITEMS_FAIL = "GET_CART_ITEMS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
