import { ArrowBackIosIcon, ArrowForwardIosIcon } from "./iconImports";
import PropTypes from "prop-types";

export { default as Footer } from "../components/Layouts/Footer/Footer";
export { default as Header } from "../components/Layouts/Header/Header";
export { default as MetaData } from "../components/Layouts/MetaData";
export { default as NotFound } from "../components/NotFound";
export { default as UserDashboard } from "../components/User/UserDashboard";
export { default as Login } from "../components/User/Login";
export { default as Register } from "../components/User/Register";
export { default as UpdateProfile } from "../components/User/UpdateProfile";
export { default as Account } from "../components/User/Account";
export { default as ResetPassword } from "../components/User/ResetPassword";
export { default as ForgotPassword } from "../components/User/ForgotPassword";
export { default as UpdatePassword } from "../components/User/UpdatePassword";
export { default as ProductDetails } from "../components/ProductDetails/ProductDetails";
export { default as Home } from "../components/Home/Home";
export { default as Products } from "../components/Products/Products";
export { default as Cart } from "../components/Cart/Cart";
export { default as Shipping } from "../components/Purchasing/Shipping";
export { default as OrderConfirm } from "../components/Purchasing/OrderConfirm";
export { default as Payment } from "../components/Purchasing/Payment";
export { default as OrderSuccess } from "../components/Purchasing/OrderSuccess";
export { default as MyOrders } from "../components/Order/MyOrders";
export { default as OrderDetails } from "../components/Order/OrderDetails";
export { default as OrderTable } from "../components/Admin/OrderTable";
export { default as UpdateOrder } from "../components/Admin/UpdateOrder";
export { default as Dashboard } from "../components/Admin/Dashboard";
export { default as ProductTable } from "../components/Admin/ProductTable";
export { default as NewProduct } from "../components/Admin/NewProduct";
export { default as UpdateProduct } from "../components/Admin/UpdateProduct";
export { default as MainData } from "../components/Admin/MainData";
export { default as UserTable } from "../components/Admin/UserTable";
export { default as UpdateUser } from "../components/Admin/UpdateUser";
export { default as ReviewsTable } from "../components/Admin/ReviewsTable";
export { default as Wishlist } from "../components/Wishlist/Wishlist";
export { default as ManageAddress } from "../components/Address/ManageAddress";
export { default as AddShippingInfo } from "../components/Address/AddShippingInfo";
export { default as Privacy } from "../components/Layouts/Privacy";
export { default as TermsofService } from "../components/Layouts/TermsofService";
export { default as ContactUs } from "../components/Layouts/ContactUs";
export { default as AboutUs } from "../components/Layouts/AboutUs";
export { default as Cancellation } from "../components/Layouts/Cancellation";
export { default as Container } from "../Routes/Container";
export { default as ProtectedRoute } from "../Routes/ProtectedRoute";
export { default as BackdropLoader } from "../components/Layouts/BackdropLoader";
export { default as FormSidebar } from "../components/User/FormSidebar";
export { default as Loader } from "../components/Layouts/Loader";
export { default as PriceSidebar } from "../components/Layouts/PriceSidebar";
export { default as Stepper } from "../components/Purchasing/Stepper";
export { default as Banner } from "../components/Home/Banner/Banner";
export { default as Categories } from "../components/Home/Categories/Categories";
export { default as ProductSlider } from "../components/Home/ProductSlider/ProductSlider";
export { default as Exams } from "../components/Home/Exams/Exams";
export { default as Testimonials } from "../components/Home/Testimonial/Testimonials";
export { default as Publishers } from "../components/Home/Publishers/Publishers";
export { default as Bestsellers } from "../components/Home/Bestsellers/Bestsellers";
export { default as Confirmation } from "../components/Layouts/Confirmation";
export { default as ProductDetailsModal } from "../components/Admin/ProductDetailsModal";

export const PreviousBtn = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <ArrowBackIosIcon style={{ color: "#A3D8E4" }} />
    </button>
  );
};

export const NextBtn = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <ArrowForwardIosIcon style={{ color: "#A3D8E4" }} />
    </button>
  );
};

PreviousBtn.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

NextBtn.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
