import { SearchIcon, CloseIcon } from "../../../utils/iconImports";
import useSearch from "../../../hooks/layouts/search";

const Searchbar = () => {
  const { keyword, setKeyword, handleSubmit, handleClear } = useSearch();

  return (
    <form
      onSubmit={handleSubmit}
      className="px-1 sm:px-4 py-2.5 flex justify-between gap-2 items-center bg-primary-lightGrey rounded-md  overflow-hidden"
    >
      <button type="submit" className="-ml-1" aria-label="Search">
        <SearchIcon />
      </button>
      <input
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        className="ml-2 text-sm flex-1 outline-none border-none placeholder-gray-400 font-poppins400 bg-primary-lightGrey focus:ring-0 focus:border-0 focus:outline-none overflow-auto text-ellipsis"
        type="text"
        placeholder="Search books"
      />
      {keyword && (
        <button
          onClick={handleClear}
          className="text-gray-500 hover:text-gray-700 mr-1"
        >
          <CloseIcon />
        </button>
      )}
    </form>
  );
};

export default Searchbar;
