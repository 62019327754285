import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MetaData, Confirmation, Loader } from "../../utils/imports";
import WishlistProduct from "./WishlistProduct";
import { clearWishlist, clearErrors } from "../../actions/wishlistAction";
import { DeleteIcon } from "../../utils/iconImports";
import { useSnackbar } from "notistack";
import { loadUser } from "../../actions/userAction";

const Wishlist = () => {
  const dispatch = useDispatch();
  const { wishlistItems, error, loading } = useSelector(
    (state) => state.wishlist
  );
  const { enqueueSnackbar } = useSnackbar();

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const emptyWishlistHandler = () => {
    dispatch(clearWishlist());
    if (error) {
      enqueueSnackbar(`Facing some issue ${error}`, { variant: "error" });
    } else {
      enqueueSnackbar(`Wishlist cleared successfully`, { variant: "success" });
      dispatch(loadUser());
    }
    setConfirmationOpen(false);
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, enqueueSnackbar, dispatch]);

  return (
    <>
      {loading && <Loader />}
      <MetaData title="Wishlist | BooksDehra" />

      <div className="flex-1 shadow rounded-xl min-w-max md:min-w-0 ">
        <div className="flex flex-col">
          <span className="font-poppins500 text-lg px-4 sm:px-8 py-4 border-b flex justify-between items-center">
            <span> My Wishlist ({wishlistItems.length}) </span>
            <span className="text-sm font-poppins500">
              <button
                className={`${
                  wishlistItems.length < 1
                    ? "bg-primary-grey cursor-not-allowed"
                    : "bg-red-500 cursor-pointer text-white"
                } px-2 md:px-4 py-1 md:py-2 border rounded-xl  flex items-center gap-2`}
                onClick={() => setConfirmationOpen(true)}
                disabled={wishlistItems.length < 1}
              >
                <DeleteIcon />
                <span>Clear Wishlist</span>
              </button>
            </span>
          </span>
          <Confirmation
            open={confirmationOpen}
            confirmationText="Are you sure you want to remove all products from your wishlist?"
            onClose={() => setConfirmationOpen(false)}
            onConfirm={emptyWishlistHandler}
            task="Clear Wishlist"
          />
          {wishlistItems.length === 0 && (
            <div className="flex items-center flex-col gap-2 m-6">
              <img
                draggable="false"
                className="object-contain"
                src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/mywishlist-empty_39f7a5.png"
                alt="Empty Wishlist"
              />
              <span className="text-lg font-poppins500 mt-6">
                Empty Wishlist
              </span>
              <p>You have no items in your wishlist. Start adding!</p>
            </div>
          )}
          {wishlistItems
            ?.map((item) => <WishlistProduct {...item} key={item._id} />)
            .reverse()}
        </div>
      </div>
    </>
  );
};

export default Wishlist;
