import { DataGrid } from "@mui/x-data-grid";
import { MetaData, BackdropLoader } from "../../utils/imports";
import useReviewsTable from "../../hooks/admin/reviewsTable";
import { SearchIcon, CloseIcon, RefreshIcon } from "../../utils/iconImports";

const ReviewsTable = () => {
  const {
    rows,
    columns,
    loading,
    alias,
    setAlias,
    getReviewsHandler,
    refreshReviews,
    handleClear,
    reviewsLoading,
  } = useReviewsTable();

  return (
    <>
      <MetaData title="Admin Reviews | BooksDehra" />

      {loading && <BackdropLoader />}
      <div className="flex flex-col md:flex-row md:justify-start md:items-center gap-2 md:gap-6 xl:gap-12">
        <div className="text-base md:text-lg font-poppins500 uppercase">
          reviews
        </div>

        <form
          onSubmit={getReviewsHandler}
          className="px-1 sm:px-4 py-2.5 flex justify-between gap-2 items-center bg-primary-lightGrey rounded-lg overflow-hidden"
        >
          <button type="submit" className="-ml-1" aria-label="Search">
            <SearchIcon />
          </button>
          <input
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
            className="ml-2 text-sm flex-1 outline-none border-none placeholder-gray-400 font-poppins400 bg-primary-lightGrey focus:ring-0 focus:border-0 focus:outline-none overflow-auto text-ellipsis"
            type="text"
            placeholder="Search for something"
          />
          {alias && (
            <button
              onClick={handleClear}
              className="text-gray-500 hover:text-gray-700"
            >
              <CloseIcon />
            </button>
          )}
        </form>
        <button
          onClick={refreshReviews}
          className="bg-primary-blue flex items-center gap-1 text-white rounded-xl p-2 shadow hover:shadow-lg w-1/3 sm:w-1/5 md:w-auto"
        >
          Refresh
          <RefreshIcon />
        </button>
      </div>
      <div
        className="bg-white rounded-xl shadow-lg w-full"
        style={{ height: 470 }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          disableSelectIconOnClick
          loading={reviewsLoading}
          disableColumnMenu
        />
      </div>
    </>
  );
};

export default ReviewsTable;
