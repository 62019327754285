import { useSelector } from "react-redux";

const useStepper = () => {
  const { user } = useSelector((state) => state.user);
  const purchaseItems = useSelector((state) => state.purchase.purchasing);

  const address = useSelector((state) => state.purchase.address);

  const steps = [
    {
      label: "LOGIN",
      desc: (
        <p className="font-poppins500 text-sm">
          {user.name} <span className="text-sm font-normal">{user.email}</span>
        </p>
      ),
    },
    {
      label: "DELIVERY ADDRESS",
      desc: (
        <p className="font-poppins500 text-sm">
          <span className="text-sm font-normal">{address.address}</span>
        </p>
      ),
    },
    {
      label: "ORDER SUMMARY",
      desc: (
        <p className="font-poppins500 text-sm">{purchaseItems.length} Item</p>
      ),
    },
    {
      label: "PAYMENT OPTIONS",
      desc: <p className="font-poppins500 text-sm">Paytm</p>,
    },
  ];

  return { steps };
};

export default useStepper;
