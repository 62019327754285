import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { BackdropLoader, MetaData, FormSidebar } from "../../utils/imports";
import useForgotPassword from "../../hooks/user/forgotPassword";

const ForgotPassword = () => {
  const { email, setEmail, handleSubmit, loading } = useForgotPassword();

  return (
    <div className="flex flex-col items-center justify-center mt-16 md:mt-24">
      <MetaData title="Forgot Password" />

      {loading && <BackdropLoader />}
      <main className="w-4/5 lg:w-3/4 ">
        <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 shadow-lg bg-primary-lightGrey rounded-2xl ">
          <FormSidebar
            title="Forgot Your Password?"
            tag="Enter the email address associated with your account."
          />
          <div className="flex-1 overflow-hidden">
            <div className="text-center text-2xl font-poppins500 mt-6 text-gray-800">
              Forgot Password
            </div>
            <div className="text-center py-10 px-4 sm:px-14">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col w-full gap-4 mb-32 mt-2">
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    className="text-white py-3 w-full bg-primary-darkBlue shadow rounded-xl font-poppins500"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <Link
                to="/register"
                className="font-poppins500 text-sm text-primary-blue"
              >
                New here? Create an account
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
