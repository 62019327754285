import React, { useState } from "react";
import Card from "./Card";
import { Link } from "react-router-dom";
import { categories } from "../../../utils/constants";
import { ExpandMoreIcon, ExpandLessIcon } from "../../../utils/iconImports";

const Categories = () => {
  const [showAllCategories, setShowAllCategories] = useState(false);
  const initialCategoryCount = 13;
  const visibleCategories = showAllCategories
    ? categories
    : categories.slice(0, initialCategoryCount);

  const handleViewMore = () => {
    setShowAllCategories(true);
  };

  const handleViewLess = () => {
    setShowAllCategories(false);
  };

  return (
    <div
      className=" flex flex-col justify-between gap-4 xl:gap-10 "
      id="categories"
    >
      <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 items-center gap-3 xl:gap-8 font-poppins400 text-wrap ">
        <div className="text-xl sm:text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-poppins600 tracking-wider leading-snug col-span-2 text-center">
          {" "}
          Browse by <br /> Categories
        </div>
        {!showAllCategories &&
          visibleCategories.map((category, index) => (
            <Link
              key={category.name}
              to={`/products?category=${category.name}`}
            >
              <Card category={category.name} logo={category.image} />
            </Link>
          ))}
        {showAllCategories &&
          categories.map((category, index) => (
            <Link
              key={category.name}
              to={`/products?category=${category.name}`}
            >
              <Card category={category.name} logo={category.image} />
            </Link>
          ))}
      </div>
      <div className="flex justify-center">
        {!showAllCategories && categories?.length > initialCategoryCount && (
          <button
            className="w-1/5 text-base md:text-base lg:text-lg font-poppins700 rounded-full py-3 px-14 sm:px-0 border border-gray-950 hover:text-white hover:bg-black transition-all duration-150 ease-in-out hover:shadow-xl tracking-normal sm:tracking-wider flex justify-center items-center group"
            onClick={handleViewMore}
          >
            <span className="text-nowrap"> VIEW ALL </span>
            <ExpandMoreIcon className="text-2xl" />
          </button>
        )}
        {showAllCategories && (
          <button
            className="w-1/5 text-base md:text-base lg:text-lg font-poppins700 rounded-full py-3 px-14 sm:px-0 border border-gray-950 hover:text-white hover:bg-black transition-all duration-150 ease-in-out hover:shadow-xl tracking-normal sm:tracking-wider flex justify-center items-center group"
            onClick={handleViewLess}
          >
            <span className="text-nowrap"> VIEW LESS </span>
            <ExpandLessIcon className="text-2xl" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Categories;
