import {
  ADD_ITEM_PURCHASE,
  EMPTY_PURCHASE,
  REMOVE_ITEM_PURCHASE,
  GET_PURCHASE_ITEMS,
  PURCHASE_ITEMS_FAIL,
  ADD_ADDRESS_PURCHASE,
  REMOVE_ADDRESS_PURCHASE,
  UPDATE_PURCHASE_ITEMS,
  DIRECT_PURCHASE_TRUE,
  DIRECT_PURCHASE_FALSE,
} from "../constants/purchaseConstants";

export const purchaseReducer = (
  state = {
    purchasing: [],
    address: {},
    purchasingError: null,
  },
  { type, payload }
) => {
  const isItemExist = state.purchasing.find(
    (el) => el?.product === payload?.product
  );
  switch (type) {
    case DIRECT_PURCHASE_TRUE:
      return {
        ...state,
        directPurchase: true,
      };
    case DIRECT_PURCHASE_FALSE:
      return {
        ...state,
        directPurchase: false,
      };
    case ADD_ITEM_PURCHASE:
      if (isItemExist) {
        return {
          ...state,
          purchasing: state.purchasing.map((el) =>
            el.product === isItemExist.product ? payload : el
          ),
        };
      } else {
        return {
          ...state,
          purchasing: [...state.purchasing, payload],
        };
      }
    case UPDATE_PURCHASE_ITEMS:
    case GET_PURCHASE_ITEMS:
      return {
        ...state,
        purchasing: payload,
      };
    case REMOVE_ITEM_PURCHASE:
      return {
        ...state,
        purchasing: state.purchasing.filter((el) => el.product !== payload),
      };
    case EMPTY_PURCHASE:
      return {
        ...state,
        purchasing: [],
      };
    case ADD_ADDRESS_PURCHASE:
      return {
        ...state,
        address: payload,
      };
    case REMOVE_ADDRESS_PURCHASE:
      return {
        ...state,
        address: {},
      };
    case PURCHASE_ITEMS_FAIL:
      return {
        ...state,
        purchasingError: payload,
      };
    default:
      return state;
  }
};
