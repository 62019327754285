import "./Home.css";
import useHome from "../../hooks/home/home";
import {
  BackdropLoader,
  Container,
  MetaData,
  Banner,
  Categories,
  Publishers,
  Bestsellers,
  Exams,
  Testimonials,
} from "../../utils/imports";

const Home = () => {
  const { loading, productsFound } = useHome();

  return (
    <>
      {loading && <BackdropLoader />}
      <MetaData title="Online Shopping Site for Awesome Books" />
      <main className="flex flex-col gap-10 mt-20 md:mt-28 xl:mt-32 mb-2 sm:mb-12 ">
        <Container>
          <Banner />
        </Container>
        {productsFound && <Bestsellers />}
        <Container>
          <Categories />
        </Container>
        <Container>
          <Exams />
        </Container>
        <Container>
          <Publishers />
        </Container>
        <Container>
          <Testimonials />
        </Container>
      </main>
    </>
  );
};

export default Home;
