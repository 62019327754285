import React from "react";
import PropTypes from "prop-types";

const Card = ({ category, logo }) => {
  return (
    <div className=" flex flex-col items-center justify-center gap-4 py-6 md:py-12 xl:py-14 px-8 md:px-10 xl:px-14 mx-0 xl:mx-2 bg-primary-blueGray hover:bg-primary-darkBeige rounded-3xl cursor-pointer min-h-[150px] md:min-h-[240px]">
      <img src={logo} alt="category" className="w-15 h-15 sm:w-20 sm:h-20" />
      <div className="text-xs sm:text-sm md:text-lg xl:text-2xl text-gray-500 font-poppins500 ">
        {category}
      </div>
    </div>
  );
};

Card.propTypes = {
  category: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default Card;
