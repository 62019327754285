import PropTypes from "prop-types";
const FormSidebar = ({ title, tag }) => {
  return (
    <div className="loginSidebar bg-primary-blue px-9 py-10 hidden lg:flex flex-col gap-4 w-2/5 text-center min-h-[70vh]">
      <div className="font-poppins600 text-white text-3xl">{title}</div>
      <p className="text-gray-200 font-poppins500 text-lg pr-2 mb-10">{tag}</p>
    </div>
  );
};

FormSidebar.propTypes = {
  title: PropTypes.string,
  tag: PropTypes.string,
};

export default FormSidebar;
