import { useEffect, useState } from "react";
import { myOrders, clearErrors } from "../../actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const orderStatus = ["Processing", "Shipped", "Delivered", "Cancelled"];
const dt = new Date();
const ordertime = [dt.getMonth(), dt.getFullYear() - 1, dt.getFullYear() - 2];

const useMyOrders = () => {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [status, setStatus] = useState("");
  const [orderTime, setOrderTime] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [toggleFilterSidebar, setToggleFilterSidebar] = useState(false);
  const { orders, loading, error } = useSelector((state) => state.myOrders);
  const { isCancelled } = useSelector((state) => state.order);

  const toggleFilterSidebarHandler = () => {
    setToggleFilterSidebar(!toggleFilterSidebar);
  };

  const closeFilterSidebarHandler = () => {
    setToggleFilterSidebar(false);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
  }, [error, enqueueSnackbar, dispatch]);

  useEffect(() => {
    dispatch(myOrders());
  }, [dispatch, isCancelled]);

  useEffect(() => {
    if (loading === false) {
      setFilteredOrders(orders);
    }
  }, [loading, orders]);

  useEffect(() => {
    if (!orders) {
      return;
    }

    setSearch("");
    if (!status && +orderTime === 0) {
      setFilteredOrders(orders);
      return;
    }

    if (status && orderTime) {
      if (+orderTime === dt?.getMonth()) {
        const filteredArr = orders?.filter(
          (order) =>
            order?.orderStatus === status &&
            new Date(order?.createdAt)?.getMonth() === +orderTime
        );
        setFilteredOrders(filteredArr);
      } else {
        const filteredArr = orders.filter(
          (order) =>
            order?.orderStatus === status &&
            new Date(order?.createdAt)?.getFullYear() === +orderTime
        );
        setFilteredOrders(filteredArr);
      }
    } else if (!status) {
      if (+orderTime === dt?.getMonth()) {
        const filteredArr = orders?.filter(
          (order) => new Date(order.createdAt)?.getMonth() === +orderTime
        );
        setFilteredOrders(filteredArr);
      } else {
        const filteredArr = orders?.filter(
          (order) => new Date(order.createdAt)?.getFullYear() === +orderTime
        );
        setFilteredOrders(filteredArr);
      }
    } else {
      const filteredArr = orders.filter(
        (order) => order.orderStatus === status
      );
      setFilteredOrders(filteredArr);
    }
    // eslint-disable-next-line
  }, [status, orderTime]);

  const searchOrders = (e) => {
    e.preventDefault();
    const str = search.trim();
    if (!str) {
      enqueueSnackbar("Empty Input", {
        variant: "warning",
      });
      return;
    }

    const arr = orders.filter((order) =>
      order?.orderItems?.some((orderItem) =>
        orderItem?.product?.name?.toLowerCase().includes(str.toLowerCase())
      )
    );
    setFilteredOrders(arr);
  };

  const clearFilters = () => {
    setStatus("");
    setOrderTime(0);
    setFilteredOrders(orders);
  };

  const handleStatusChange = (e) => {
    if (e.target.value === status) {
      setStatus("");
    } else {
      setStatus(e.target.value);
    }
  };

  const handleTimeChange = (e) => {
    if (e.target.value === orderTime) {
      setOrderTime(0);
    } else {
      setOrderTime(e.target.value);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const clearSearch = () => {
    setSearch("");
    setFilteredOrders(orders);
  };

  return {
    status,
    orderTime,
    search,
    filteredOrders,
    loading,
    orderStatus,
    ordertime,
    orders,
    toggleFilterSidebar,
    clearSearch,
    handleStatusChange,
    handleTimeChange,
    handleSearchChange,
    searchOrders,
    clearFilters,
    toggleFilterSidebarHandler,
    closeFilterSidebarHandler,
  };
};

export default useMyOrders;
