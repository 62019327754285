import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
import {
  clearErrors,
  getOrderDetails,
  updateOrder,
  getAllOrders,
} from "../../actions/orderAction";

const useUpdateOrder = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();

  const { order, error, loading } = useSelector((state) => state.orderDetails);
  const { isUpdated, error: updateError } = useSelector((state) => state.order);

  const [status, setStatus] = useState(
    order?.orderStatus ? order.orderStatus : ""
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (updateError) {
      enqueueSnackbar(`  ${updateError}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (isUpdated) {
      dispatch(getAllOrders(1, 100, {}, {}));
      dispatch({ type: UPDATE_ORDER_RESET });
    }
    dispatch(getOrderDetails(params.id));
  }, [dispatch, error, params.id, isUpdated, updateError, enqueueSnackbar]);

  const updateOrderSubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("status", status);
    dispatch(updateOrder(params.id, formData));
    enqueueSnackbar("Order Updated Successfully", {
      variant: "success",
    });
    navigate("/admin/orders");
  };

  return {
    status,
    setStatus,
    order,
    loading,
    updateOrderSubmitHandler,
  };
};

export default useUpdateOrder;
