import axios from "axios";
import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART,
  ADD_TO_CART_FAIL,
  EMPTY_CART_REQUEST,
  EMPTY_CART,
  EMPTY_CART_FAIL,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART,
  REMOVE_FROM_CART_FAIL,
  CLEAR_ERRORS,
} from "../constants/cartConstants";

export const addItemsToCart = (id, quantity) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TO_CART_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.get(
      `/api/v1/addtocart/${id}/${quantity}`,
      config
    );

    dispatch({
      type: ADD_TO_CART,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ADD_TO_CART_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const removeItemsFromCart = (id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_FROM_CART_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.delete(`/api/v1/removefromcart/${id}`, config);

    dispatch({
      type: REMOVE_FROM_CART,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: REMOVE_FROM_CART_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const emptyCart = () => async (dispatch) => {
  try {
    dispatch({ type: EMPTY_CART_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.delete(`/api/v1/clearcart`, config);
    dispatch({ type: EMPTY_CART });
  } catch (error) {
    console.log(error);
    dispatch({
      type: EMPTY_CART_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
