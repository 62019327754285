import axios from "axios";
import {
  ALL_PRODUCTS_FAIL,
  ALL_PRODUCTS_REQUEST,
  ALL_PRODUCTS_SUCCESS,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  ADMIN_PRODUCTS_REQUEST,
  ADMIN_PRODUCTS_SUCCESS,
  ADMIN_PRODUCTS_FAIL,
  CLEAR_ERRORS,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_SUCCESS,
  NEW_REVIEW_FAIL,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  ALL_REVIEWS_REQUEST,
  ALL_REVIEWS_SUCCESS,
  ALL_REVIEWS_FAIL,
  BULK_UPLOAD_PRODUCTS_REQUEST,
  BULK_UPLOAD_PRODUCTS_SUCCESS,
  BULK_UPLOAD_PRODUCTS_FAIL,
  PRODUCT_REVIEWS_REQUEST,
  PRODUCT_REVIEWS_SUCCESS,
  PRODUCT_REVIEWS_FAIL,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  SLIDER_PRODUCTS_REQUEST,
  SLIDER_PRODUCTS_SUCCESS,
  SLIDER_PRODUCTS_FAIL,
  MAIN_DATA_REQUEST,
  MAIN_DATA_SUCCESS,
  MAIN_DATA_FAIL,
  SIMILAR_PRODUCTS_FAIL,
  SIMILAR_PRODUCTS_REQUEST,
  SIMILAR_PRODUCTS_SUCCESS,
} from "../constants/productConstants";

export const getProducts =
  (
    keyword = "",
    exam,
    bestSeller,
    category,
    price = [0, 50000],
    ratings = 0,
    currentPage = 1
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: ALL_PRODUCTS_REQUEST });

      let url = `/api/v1/products?keyword=${keyword}&category=${category}&exam=${exam}&price[gte]=${price[0]}&price[lte]=${price[1]}&ratings[gte]=${ratings}&page=${currentPage}&bestSeller=${bestSeller}`;
      const { data } = await axios.get(url);

      dispatch({
        type: ALL_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCTS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const getSimilarProducts = (category) => async (dispatch) => {
  try {
    if (!category)
      return dispatch({
        type: SIMILAR_PRODUCTS_FAIL,
        payload: "Category not found",
      });

    dispatch({ type: SIMILAR_PRODUCTS_REQUEST });

    const { data } = await axios.get(`/api/v1/products?category=${category}`);

    dispatch({
      type: SIMILAR_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log("Error in getting similar products: " + error);
    dispatch({
      type: SIMILAR_PRODUCTS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/product/${id}`);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const newReview = (reviewData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_REVIEW_REQUEST });

    const token = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.put("/api/v1/review", reviewData, config);

    dispatch({
      type: NEW_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: NEW_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getBestSellerProducts = () => async (dispatch) => {
  try {
    dispatch({ type: SLIDER_PRODUCTS_REQUEST });

    const { data } = await axios.get("/api/v1/products/bestSellers");

    if (data?.products?.length === 0) {
      dispatch({
        type: SLIDER_PRODUCTS_FAIL,
        payload: "No Products Found",
      });
      return;
    }

    dispatch({
      type: SLIDER_PRODUCTS_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    console.log("Error in getting slider products: " + error);
    if (error.response.data.message) {
      dispatch({
        type: SLIDER_PRODUCTS_FAIL,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: SLIDER_PRODUCTS_FAIL,
        payload: "Server Error",
      });
    }
  }
};

export const getMainData = () => async (dispatch) => {
  try {
    dispatch({ type: MAIN_DATA_REQUEST });
    const token = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.get("/api/v1/admin/mainData", config);

    dispatch({
      type: MAIN_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MAIN_DATA_FAIL,
      payload: error.response.data.message,
    });
    console.log("Error in getting admin main data:  " + error);
  }
};

export const getAdminProducts =
  (page, pageSize, filters, sort, keyword) => async (dispatch) => {
    try {
      dispatch({ type: ADMIN_PRODUCTS_REQUEST });

      const token = localStorage.getItem("userToken");

      const config = {
        "Content-Type": "application/json",
        headers: {
          Authorization: token,
        },
      };

      const requestbody = {
        filters,
        sort,
      };

      const { data } = await axios.post(
        `/api/v1/admin/products?page=${page}&limit=${pageSize}&keyword=${keyword}`,
        requestbody,
        config
      );

      dispatch({
        type: ADMIN_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_PRODUCTS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const bulkUploadProducts = (products) => async (dispatch) => {
  try {
    dispatch({ type: BULK_UPLOAD_PRODUCTS_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.post(
      "/api/v1/admin/uploadproducts",
      products,
      config
    );

    dispatch({
      type: BULK_UPLOAD_PRODUCTS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: BULK_UPLOAD_PRODUCTS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createProduct = (productData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCT_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.post(
      "/api/v1/admin/product/new",
      productData,
      config
    );

    dispatch({
      type: NEW_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };
    const { data } = await axios.put(
      `/api/v1/admin/product/${id}`,
      productData,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const deleteProduct = (alias) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.delete(
      `/api/v1/admin/product/${alias}`,
      config
    );

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const fetchTags = async () => {
  try {
    const response = await axios.get("/api/v1/tags");
    return response.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
  }
};

export const saveTag = async (tag) => {
  try {
    const token = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: token,
      },
    };
    await axios.post("/api/v1/savetag", { tag }, config);
  } catch (error) {
    console.error("Error saving tag:", error);
  }
};

export const getAllReviews = (page, pageSize) => async (dispatch) => {
  try {
    const token = localStorage.getItem("userToken");

    const config = {
      "Content-Type": "application/json",
      headers: {
        Authorization: token,
      },
    };

    dispatch({ type: ALL_REVIEWS_REQUEST });

    const { data } = await axios.get(
      `/api/v1/admin/allReviews?page=${page}&limit=${pageSize}`,
      config
    );

    dispatch({
      type: ALL_REVIEWS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_REVIEWS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getProductReviews = (alias) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_REVIEWS_REQUEST });

    const { data } = await axios.get(`/api/v1/admin/reviews?alias=${alias}`);

    dispatch({
      type: PRODUCT_REVIEWS_SUCCESS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_REVIEWS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const deleteReview = (reviewId, alias) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_REVIEW_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.delete(
      `/api/v1/admin/reviews?id=${reviewId}&alias=${alias}`,
      config
    );

    dispatch({
      type: DELETE_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
