import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { emptyPurchase } from "../../actions/purchasingActions";
import { useMediaQuery } from "react-responsive";

const useHeader = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { cartItems } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const location = useLocation();

  const [togglePrimaryDropDown, setTogglePrimaryDropDown] = useState(false);
  const primaryDropDownRef = useRef(null);
  const [toggleHamburger, setToggleHamburger] = useState(false);

  useEffect(() => {
    dispatch(emptyPurchase());
    const handleClick = (e) => {
      if (
        primaryDropDownRef.current &&
        !primaryDropDownRef.current.contains(e.target)
      ) {
        setTogglePrimaryDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  }, [dispatch]);

  const cartRedirect = isAuthenticated ? "/cart" : "/login";

  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const handleHamburgerClick = () => {
    setToggleHamburger(!toggleHamburger);
  };

  const [showSearchBar, setShowSearchBar] = useState(false);

  const searchHandler = () => {
    setShowSearchBar(!showSearchBar);
  };

  const burgerRef = useRef(null);
  const searchRef = useRef(null);
  const searchButtonRef = useRef(null);
  const hamburgerButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(e.target) &&
        !searchButtonRef.current.contains(e.target)
      )
        setShowSearchBar(false);
    };

    if (isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, showSearchBar]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        burgerRef.current &&
        !burgerRef.current.contains(e.target) &&
        !hamburgerButtonRef.current.contains(e.target)
      )
        setToggleHamburger(false);
    };

    if (isMobile || isTablet) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, isTablet]);

  const closeBurgerDropdown = () => {
    setToggleHamburger(false);
  };

  return {
    isAuthenticated,
    user,
    cartItems,
    location,
    togglePrimaryDropDown,
    primaryDropDownRef,
    cartRedirect,
    isTablet,
    isMobile,
    toggleHamburger,
    showSearchBar,
    burgerRef,
    searchRef,
    hamburgerButtonRef,
    searchButtonRef,
    setTogglePrimaryDropDown,
    handleHamburgerClick,
    searchHandler,
    closeBurgerDropdown,
  };
};

export default useHeader;
