import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { MetaData, BackdropLoader, Loader } from "../../utils/imports";
import useUserTable from "../../hooks/admin/userTable";

const UserTable = () => {
  const {
    rows,
    columns,
    loading,
    usersLoading,
    paginationModel,
    setPaginationModel,
    filterModel,
    setFilterModel,
    rowCountState,
    sortModel,
    setSortModel,
  } = useUserTable();

  return (
    <>
      <MetaData title="Admin Users | BooksDehra" />

      {loading && <BackdropLoader />}

      <div className="text-lg font-poppins500 uppercase">users</div>
      <div
        className="bg-white rounded-xl shadow-lg w-full text-xs "
        style={{ height: 450 }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          rowCount={rowCountState}
          pageSize={10}
          loading={usersLoading}
          disableSelectIconOnClick
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: Loader,
          }}
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
            panel: {
              sx: {
                top: "-160px !important",
              },
            },
            filterPanel: {
              sx: {
                maxWidth: "98vw ",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default UserTable;
