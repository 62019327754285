import { MetaData, Confirmation, Loader } from "../../utils/imports";
import Address from "./Address";
import { Link } from "react-router-dom";
import useManageAddress from "../../hooks/address/manageAddress";
import { AddIcon, DeleteIcon } from "../../utils/iconImports";

const ManageAddress = () => {
  const {
    confirmationOpen,
    shippingInfo,
    setConfirmationOpen,
    handleRemoveAllAddresses,
    loading,
  } = useManageAddress();

  return (
    <>
      {loading && <Loader />}
      <MetaData title="Addresses | BooksDehra" />
      <div className="flex-1 shadow rounded-xl min-h-[70vh] min-w-max md:min-w-0 ">
        <div className="flex flex-col">
          <span className="font-poppins500 px-4 md:px-8 py-4 border-b flex justify-between items-center">
            <span className="text-sn sm:text-base md:text-lg">
              Saved Addresses ({shippingInfo?.length})
            </span>
            <div className="flex justify-evenly md:justify-between gap-2 text-sm font-poppins500">
              <button
                className={`${
                  shippingInfo?.length < 1
                    ? "bg-primary-grey cursor-not-allowed"
                    : "bg-red-500 cursor-pointer text-white"
                } px-2 lg:px-4 py-1 lg:py-2 border rounded-xl flex items-center`}
                onClick={() => setConfirmationOpen(true)}
                disabled={shippingInfo?.length < 1}
              >
                <DeleteIcon />
                <span className="text-xs md:text-base ">Delete all</span>
              </button>
              <Confirmation
                open={confirmationOpen}
                confirmationText="Are you sure you want to delete all saved addresses?"
                onClose={() => setConfirmationOpen(false)}
                onConfirm={handleRemoveAllAddresses}
                task="Delete All"
              />
              <Link to="/add-address">
                <button className="flex items-center px-2 py-1 lg:px-4 lg:py-2 text-white  bg-primary-midBlue border rounded-xl cursor-pointer ">
                  <AddIcon />
                  <span className="text-xs md:text-base">Add New</span>
                </button>
              </Link>
            </div>
          </span>

          {shippingInfo?.length === 0 && (
            <div className="flex items-center flex-col gap-2 m-6">
              <img
                draggable="false"
                className="object-contain"
                src="https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/myaddresses-empty_3b40af.png"
                alt="No addresses saved"
              />
              <span className="text-sm md:text-lg font-poppins500 mt-6">
                {"No saved addresses"}
              </span>
            </div>
          )}
          {~loading &&
            shippingInfo?.map((item, index) => (
              <Address
                {...item}
                key={item?._id}
                isLastAddress={index === shippingInfo.length - 1}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default ManageAddress;
