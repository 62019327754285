import { CircleIcon, RadioButtonUncheckedIcon } from "../../utils/iconImports";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/functions";
import PropTypes from "prop-types";

const OrderItem = (props) => {
  const { orderId, orderItems, createdAt, deliveredAt, orderStatus } = props;

  let statusIcon;
  let statusText;

  if (orderStatus === "Shipped") {
    statusIcon = (
      <span className="text-primary-orange pb-0.5">
        <CircleIcon sx={{ fontSize: "14px" }} />
      </span>
    );
    statusText = "Shipped";
  } else if (orderStatus === "Delivered") {
    statusIcon = (
      <span className="text-primary-green pb-0.5">
        <CircleIcon sx={{ fontSize: "14px" }} />
      </span>
    );
    statusText = `Delivered on ${formatDate(deliveredAt)}`;
  } else if (orderStatus === "Cancelled") {
    statusIcon = (
      <span className="text-red-500 pb-0.5">
        <CircleIcon sx={{ fontSize: "14px" }} />
      </span>
    );
    statusText = "Cancelled";
  } else {
    statusIcon = (
      <span className="text-primary-green pb-0.5">
        <RadioButtonUncheckedIcon sx={{ fontSize: "14px" }} />
      </span>
    );
    statusText = `Ordered on ${formatDate(createdAt)}`;
  }

  let infoText;
  if (
    orderStatus === "Delivered" ||
    orderStatus === "Shipped" ||
    orderStatus === "Cancelled"
  ) {
    infoText = `Your item has been ${orderStatus}`;
  } else {
    infoText = "Seller has processed your order";
  }

  return (
    <Link
      to={`/order_details/${orderId}`}
      key={orderId}
      className={`flex flex-row items-center justify-between rounded-xl border hover:shadow-lg ${
        orderStatus === "Cancelled"
          ? "bg-red-300/50 border-red-400/95"
          : "bg-primary-lightestBlue border-primary-darkBlue"
      }   `}
    >
      <div
        className={`flex flex-col gap-1.5 w-3/5 border-r  ${
          orderStatus === "Cancelled"
            ? "border-red-400/95"
            : "border-primary-darkBlue"
        } `}
      >
        {orderItems?.map((item, index) => (
          <div
            key={item._id}
            className={`flex p-2 lg:p-4 gap-2 items-start ${
              index < orderItems.length - 1 ? "border-b" : ""
            } ${
              orderStatus === "Cancelled"
                ? "border-red-400/95"
                : "border-primary-darkBlue"
            }   font-poppins400`}
          >
            <div className="w-24 h-24">
              <img
                draggable="false"
                className="h-full w-full object-contain"
                src={item?.product?.images[0]?.url}
                alt={item?.product?.name}
              />
            </div>
            <div className="flex flex-col sm:flex-row justify-between w-full">
              <div className="flex flex-col gap-1 overflow-hidden">
                <p className="text-sm sm:text-base md:text-lg lg:text-xl">
                  {item?.product?.name?.length > 40
                    ? `${item?.product?.name?.substring(0, 40)}...`
                    : item?.product?.name}
                </p>
                <p className="text-xs sm:text-sm text-gray-500 mt-2">
                  Quantity: {item?.quantity}
                </p>
                <p className="text-xs sm:text-sm text-gray-500">
                  Total: ₹
                  {(
                    item?.quantity * item?.product?.discountPrice
                  ).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col sm:flex-row mx-2 mt-1 sm:mt-0 gap-2 sm:gap-20 sm:w-2/5">
        <div className="flex flex-col gap-1.5">
          <p className="text-xs sm:text-base md:text-lg font-poppins500 flex items-center gap-1">
            {statusIcon}
            {statusText}
          </p>
          <p className="text-xs sm:text-sm ml-1">{infoText}</p>
        </div>
      </div>
    </Link>
  );
};

OrderItem.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderItems: PropTypes.array.isRequired,
  createdAt: PropTypes.string.isRequired,
  deliveredAt: PropTypes.string,
  orderStatus: PropTypes.string.isRequired,
};

export default OrderItem;
