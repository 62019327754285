import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { SnackbarProvider } from "notistack";
import axios from "axios";

const port = process.env.PORT || 5000;

if (process.env.NODE_ENV !== "production") {
  axios.defaults.baseURL = `http://localhost:${port}`;
} else {
  const baseURL = window.location.origin + `:${port}`;
  axios.defaults.baseURL = baseURL;
}

// axios.defaults.baseURL = "http://194.163.175.241:5000";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={2}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Router>
        <App />
      </Router>
    </SnackbarProvider>
  </Provider>
);
