import { Link } from "react-router-dom";
import { navMenu } from "../../../utils/adminConstants";
import { AccountCircleIcon } from "../../../utils/iconImports";
import "./Sidebar.css";
import PropTypes from "prop-types";
import useAdminSidebar from "../../../hooks/admin/sidebar";
import { Confirmation } from "../../../utils/imports";
import React from "react";

const Sidebar = ({ activeTab, isMobile, closeSidebar, toggleSidebar }) => {
  const {
    confirmationOpen,
    user,
    setConfirmationOpen,
    handleLogout,
    handleClick,
  } = useAdminSidebar(isMobile, closeSidebar);

  return (
    <div
      className={`flex flex-col gap-1 md:gap-3 mt-2 bg-primary-beige shadow rounded-2xl   ${
        isMobile
          ? `absolute top-4 sm:top-1 left-0 bottom-0 z-20 inset-0 px-1 w-3/4 max-h-[90vh] transform transition-transform duration-300 ease-in-out  ${
              toggleSidebar ? "translate-x-0" : "-translate-x-full"
            } `
          : "w-1/4"
      }`}
    >
      <div className="flex flex-col gap-2 mt-2 lg:mt-4">
        <div className="flex items-center gap-1 md:gap-4 bg-primary-darkerBeige p-1 md:p-2 rounded-lg shadow-lg my-1 mx-1 lg:my-2 lg:mx-3">
          <div className="rounded-full">
            <AccountCircleIcon style={{ fontSize: "3rem" }} />
          </div>
          <div className="flex flex-col mt-1 text-wrap overflow-auto">
            <span className="font-poppins600 text-sm xl:text-base break-words">
              {user.name}
            </span>
            <span className="font-poppins400 text-sm xl:text-base break-words">
              {user.email}
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-2 mt-2 xl:mt-6 font-poppins500">
          {navMenu.map((item, index) => {
            const { icon, label, ref } = item;
            return (
              <React.Fragment key={label}>
                {label === "Logout" ? (
                  <>
                    <button
                      onClick={() => setConfirmationOpen(true)}
                      className=" hover:bg-primary-darkerBeige hover:font-poppins600 flex gap-1 md:gap-3 items-center py-1 px-2 md:py-3 md:px-4 font-poppins500 text-xs md:text-base"
                    >
                      <span>{icon}</span>
                      <span>{label}</span>
                    </button>
                    <Confirmation
                      open={confirmationOpen}
                      confirmationText="Are you sure you want to logout?"
                      onClose={() => setConfirmationOpen(false)}
                      onConfirm={handleLogout}
                      task="Logout"
                    />
                  </>
                ) : (
                  <Link
                    to={ref}
                    onClick={handleClick}
                    className={`${
                      activeTab === index
                        ? "bg-primary-darkerBeige font-poppins600"
                        : "hover:bg-primary-darkerBeige hover:font-poppins600"
                    } flex gap-1 md:gap-3 items-center py-1 px-2 md:py-3 md:px-4 text-sm xl:text-base`}
                  >
                    <span>{icon}</span>
                    <span>{label}</span>
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  activeTab: PropTypes.number.isRequired,
  toggleSidebar: PropTypes.bool,
  isMobile: PropTypes.bool,
  closeSidebar: PropTypes.func,
};

export default Sidebar;
