import { getDiscount } from "../../../utils/functions";
import Rating from "@mui/material/Rating";
import { arrow, heart, cart } from "../../../utils/images";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../actions/wishlistAction";
import {
  addItemsToCart,
  removeItemsFromCart,
} from "../../../actions/cartAction";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";

const Product = (props) => {
  const {
    _id,
    name,
    images,
    ratings,
    numOfReviews,
    price,
    discountPrice,
    backgroundColor,
    author,
    categories,
  } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { wishlistItems, loading } = useSelector((state) => state.wishlist);
  const { cartItems, cartLoading } = useSelector((state) => state.cart);

  const [isHovered, setIsHovered] = useState(false);

  const itemInWishlist = wishlistItems.some((i) => i?._id === _id);
  const itemInCart = cartItems.some((i) => i?.product?._id === _id);
  const userToken = localStorage.getItem("userToken");

  const addToWishlistHandler = () => {
    if (!userToken) {
      enqueueSnackbar("Please login to add to wishlist", {
        variant: "error",
      });
      return;
    }
    if (itemInWishlist) {
      dispatch(removeFromWishlist(_id));
      enqueueSnackbar("Removed From Wishlist", {
        variant: "info",
      });
    } else {
      dispatch(addToWishlist(_id));
      enqueueSnackbar("Added To Wishlist", {
        variant: "success",
      });
    }
  };

  const addToCartHandler = () => {
    if (!userToken) {
      enqueueSnackbar("Please login to add to cart", {
        variant: "error",
      });
      return;
    }
    if (itemInCart) {
      dispatch(removeItemsFromCart(_id));
      enqueueSnackbar("Removed From Cart", {
        variant: "info",
      });
      return;
    }
    dispatch(addItemsToCart(_id, 1));
    enqueueSnackbar("Added To Cart", {
      variant: "success",
    });
  };

  return (
    <div
      className="flex flex-row justify-around items-center rounded-3xl transition-all duration-150 ease-in-out hover:shadow-xl hover:cursor-pointer min-w-[280px] w-full  sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-6 py-6 xl:px-10 xl:py-6 text-wrap relative"
      style={{ backgroundColor: backgroundColor }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex flex-col items-start ">
        <Link to={`/product/${_id}`}>
          <div className="flex flex-col justify-between items-center text-center ">
            <div className="w-44 h-48 xl:w-50 xl:h-54">
              <img
                draggable="false"
                className="object-contain w-full h-full"
                src={images[0].url}
                alt={name}
              />
            </div>
            <p className="font-poppins400 text-base text-primary-darkGray mt-1  ">
              {categories
                ?.map((category, index) =>
                  index === categories.length - 1 ? category : `${category}, `
                )
                .join("")}
            </p>
            <div className="text-lg md:text-xl xl:text-2xl font-poppins500  break-words">
              {name?.length > 50 ? `${name.substring(0, 50)}...` : name}
            </div>
          </div>
          <div className="flex flex-col gap-2 justify-start items-start">
            <span className="text-lg font-poppins500 flex items-start">
              <Rating
                name="read-only"
                value={ratings}
                readOnly
                size="medium"
                precision={0.5}
                sx={{
                  color: "#FFC107",
                }}
              />
            </span>
            {numOfReviews > 10 && (
              <span className="text-primary-darkGray font-poppins400 text-xs">
                {" "}
                {numOfReviews} reviews
              </span>
            )}

            <p className="text-primary-darkGray font-poppins400 text-base">
              {author}
            </p>

            <div className="flex items-baseline gap-1.5 text-xl font-poppins500">
              <span>₹{discountPrice?.toLocaleString()}</span>
              <span className="text-primary-darkGray text-lg line-through">
                ₹{price?.toLocaleString()}
              </span>
              <span className="text-lg text-green-600 font-poppins600">
                {getDiscount(price, discountPrice)}%&nbsp;off
              </span>
            </div>
          </div>
        </Link>
        {isHovered && (
          <div className="flex flex-col justify-between absolute items-center right-3 bottom-4 z-20 gap-2 mt-3">
            <button
              onClick={addToWishlistHandler}
              className={`${loading ? "pointer-events-none" : ""} ${
                itemInWishlist ? " bg-black" : "bg-white"
              }  cursor-pointer w-12 h-12 rounded-full shadow-md hover:shadow-2xl transition-all duration-150 ease-in-out flex justify-center items-center `}
            >
              <img
                src={heart}
                alt="heart"
                className={`w-8 h-8 ${itemInWishlist ? "invert" : ""}`}
              />
            </button>
            <button
              onClick={addToCartHandler}
              className={`${cartLoading ? "pointer-events-none" : ""} ${
                itemInCart ? "bg-black " : "bg-white "
              }  cursor-pointer w-12 h-12 rounded-full shadow-md hover:shadow-2xl transition-all duration-150 ease-in-out flex justify-center items-center group`}
            >
              <img
                src={cart}
                alt="cart"
                className={`w-8 h-8 ${itemInCart ? "invert" : ""}`}
              />
            </button>
            <Link to={`/product/${_id}`}>
              <button className="cursor-pointer w-12 h-12 bg-white rounded-full shadow-md hover:shadow-2xl transition-all duration-150 ease-in-out mt-2 flex justify-center items-center ">
                <img src={arrow} alt="arrow" className="w-8 h-8 " />
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

Product.propTypes = {
  _id: PropTypes.string,
  name: PropTypes.string,
  images: PropTypes.array,
  ratings: PropTypes.number,
  numOfReviews: PropTypes.number,
  price: PropTypes.number,
  discountPrice: PropTypes.number,
  backgroundColor: PropTypes.string,
  author: PropTypes.string,
  categories: PropTypes.array,
};

export default Product;
