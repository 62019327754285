import React from "react";
import { TextField, MenuItem, Chip } from "@mui/material";
import PropTypes from "prop-types";
import { CloseIcon } from "../../utils/iconImports";
import { useSnackbar } from "notistack";
import { categoryNames } from "../../utils/adminConstants";

const Categories = ({ selectedCategories, setSelectedCategories }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAddCategory = (e, inputValue) => {
    e.preventDefault();
    if (inputValue && !selectedCategories.includes(inputValue)) {
      setSelectedCategories([...selectedCategories, inputValue]);
    } else {
      enqueueSnackbar("Category already added", { variant: "info" });
    }
  };

  const handleRemoveCategory = (categoryToRemove) => {
    setSelectedCategories(
      selectedCategories.filter((category) => category !== categoryToRemove)
    );
  };

  return (
    <div className="flex-col justify-evenly gap-2">
      <div className="font-poppins600 mb-2">Categories</div>
      <TextField
        label="Categories"
        select
        fullWidth
        variant="outlined"
        size="small"
        value={
          selectedCategories[selectedCategories.length - 1]
            ? selectedCategories[selectedCategories.length - 1]
            : ""
        }
        multiple
        onChange={(e) => handleAddCategory(e, e.target.value)}
        style={{ marginBottom: "10px" }}
      >
        {categoryNames.map((category, i) => (
          <MenuItem value={category} key={category}>
            {category}
          </MenuItem>
        ))}
      </TextField>
      <div className="mt-2">
        <div className="font-poppins500">Selected Categories</div>
        {selectedCategories?.length === 0 && (
          <p className="text-red-500">No category selected</p>
        )}
        {selectedCategories?.map((category, index) => (
          <Chip
            key={category}
            label={category}
            className="hover:bg-red-500 font- transition-all duration-300 ease-in-out"
            onDelete={() => handleRemoveCategory(category)}
            color="primary"
            variant="outlined"
            deleteIcon={<CloseIcon />}
            style={{
              marginRight: "5px",
              marginBottom: "5px",
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

Categories.propTypes = {
  selectedCategories: PropTypes.array.isRequired,
  setSelectedCategories: PropTypes.func.isRequired,
};

export default Categories;
