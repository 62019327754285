import { Link } from "react-router-dom";
import { MetaData, Loader } from "../../utils/imports";
import useAccount from "../../hooks/user/account";

const Account = () => {
  const { user, loading } = useAccount();

  return (
    <>
      <MetaData title="My Profile" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex-1 rounded-xl border ">
            <div className="flex flex-col gap-12 m-4 sm:mx-8 sm:my-6">
              <div className="flex flex-col gap-5 items-start">
                <span className="font-poppins500 text-lg">
                  Personal Information{" "}
                  <Link
                    to="/account/update"
                    className="text-sm text-primary-blue hover:text-primary-midBlue hover:underline hover:font-poppins600 font-poppins500 ml-8 cursor-pointer"
                  >
                    Edit
                  </Link>
                </span>

                <div
                  className="flex flex-col sm:flex-row items-center gap-3"
                  id="personalInputs"
                >
                  <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border inputs cursor-not-allowed bg-gray-100 focus-within:border-primary-blue ">
                    <label className="text-sm text-gray-500">Name</label>
                    <input
                      type="text"
                      value={user.name}
                      className="text-lg outline-none border-none cursor-not-allowed text-gray-500"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-5 items-start">
                <div className="flex flex-row gap-10 items-baseline font-poppins500 text-lg ">
                  <span> Email Address </span>
                  <div className="flex gap-4">
                    {" "}
                    <Link
                      to="/account/update"
                      className="text-sm text-primary-blue hover:text-primary-midBlue hover:underline hover:font-poppins600 font-poppins500 cursor-pointer"
                    >
                      Edit
                    </Link>
                    <Link
                      to="/password/update"
                      className="text-sm text-primary-blue hover:text-primary-midBlue hover:underline hover:font-poppins600 font-poppins500 "
                    >
                      Change Password
                    </Link>
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  <div className="flex flex-col gap-0.5 sm:w-64 px-3 py-1.5 rounded-sm border bg-gray-100 cursor-not-allowed focus-within:border-primary-blue">
                    <label className="text-sm text-gray-500">
                      Email Address
                    </label>
                    <input
                      type="email"
                      value={user.email}
                      className="text-lg outline-none border-none cursor-not-allowed text-gray-500"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-5 items-start">
                <div className="flex flex-row gap-10 items-baseline font-poppins500 text-lg ">
                  <span> Mobile Number </span>
                  <Link
                    to="/account/update"
                    className="text-sm text-primary-blue hover:text-primary-midBlue hover:underline hover:font-poppins600 font-poppins500 cursor-pointer"
                  >
                    Edit
                  </Link>
                </div>

                <div className="flex items-center gap-3">
                  <div className="flex flex-col gap-0.5 sm:w-64 px-3 py-1.5 rounded-sm border bg-gray-100 cursor-not-allowed focus-within:border-primary-blue">
                    <label className="text-sm text-gray-500">
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      value={user.phone}
                      className="text-lg outline-none border-none text-gray-500 cursor-not-allowed"
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/* <Link
                    className="text-sm text-primary-orange font-poppins500"
                    to="/"
                  >
                    Deactivate Account
                  </Link> */}
            </div>
            <img
              draggable="false"
              className="w-full object-contain"
              src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/myProfileFooter_4e9fe2.png"
              alt="footer"
            />
          </div>
        </>
      )}
    </>
  );
};

export default Account;
