import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { NEW_PRODUCT_RESET } from "../../constants/productConstants";
import { createProduct, clearErrors } from "../../actions/productAction";

const useNewProduct = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { loading, success, error } = useSelector((state) => state.newProduct);

  const [highlights, setHighlights] = useState([]);
  const [highlightInput, setHighlightInput] = useState("");
  const [specs, setSpecs] = useState([]);
  const [specsInput, setSpecsInput] = useState({
    title: "",
    description: "",
  });
  const [selectedExams, setSelectedExams] = useState([]);
  const [tags, setTags] = useState([]);
  const [name, setName] = useState("");
  const [itemDetails, setItemDetails] = useState("");
  const [displayPublisher, setDisplayPublisher] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [alias, setAlias] = useState("");
  const [discount, setDiscount] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [stock, setStock] = useState(0);
  const [author, setAuthor] = useState("");
  const [publisher, setPublisher] = useState("");
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [edition, setEdition] = useState("");
  const [bestSeller, setBestSeller] = useState(false);

  const handleSpecsChange = (e) => {
    setSpecsInput({ ...specsInput, [e.target.name]: e.target.value });
  };

  const addSpecs = () => {
    if (!specsInput.title.trim() || !specsInput.description.trim()) return;
    setSpecs([...specs, specsInput]);
    setSpecsInput({ title: "", description: "" });
  };

  const addHighlight = () => {
    if (!highlightInput.trim()) return;
    setHighlights([...highlights, highlightInput]);
    setHighlightInput("");
  };

  const deleteHighlight = (index) => {
    setHighlights(highlights.filter((h, i) => i !== index));
  };

  const deleteSpec = (index) => {
    setSpecs(specs.filter((s, i) => i !== index));
  };

  const handleProductImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((oldImages) => [...oldImages, reader.result]);
          setImages((oldImages) => [...oldImages, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleDeletePreviewImage = (url) => {
    setImagesPreview(imagesPreview.filter((img) => img !== url));
    setImages(images.filter((img) => img !== url));
  };

  const newProductSubmitHandler = (e) => {
    e.preventDefault();

    if (!alias.trim()) {
      enqueueSnackbar("Add Product Alias", {
        variant: "warning",
      });
      return;
    }

    if (!name.trim()) {
      enqueueSnackbar("Add Product name", {
        variant: "warning",
      });
      return;
    }
    if (!author.trim()) {
      enqueueSnackbar("Add Author name", {
        variant: "warning",
      });
      return;
    }
    if (!publisher.trim()) {
      enqueueSnackbar("Add Publisher name", {
        variant: "warning",
      });
    }

    const formData = new FormData();

    formData.set("name", name);
    formData.set("description", description);
    formData.set("price", price);
    formData.set("discount", discount);
    formData.set("stock", stock);
    formData.set("alias", alias);
    formData.set("author", author);
    formData.set("publisher", publisher);
    formData.set("edition", edition);
    formData.set("bestSeller", bestSeller);
    formData.set("itemDetails", itemDetails);
    formData.set("displayPublisher", displayPublisher);

    selectedCategories.forEach((category) => {
      formData.append("categories", category);
    });

    selectedExams.forEach((exam) => {
      formData.append("exams", exam);
    });

    tags.forEach((tag) => {
      formData.append("tags", tag);
    });
    images.forEach((image) => {
      formData.append("images", image);
    });

    highlights.forEach((h) => {
      formData.append("highlights", h);
    });

    specs.forEach((s) => {
      formData.append("specifications", s);
    });

    dispatch(createProduct(formData));
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (success) {
      enqueueSnackbar("Product Created", { variant: "success" });
      dispatch({ type: NEW_PRODUCT_RESET });
      navigate("/admin/products");
    }
  }, [dispatch, error, success, navigate, enqueueSnackbar]);

  return {
    loading,
    highlights,
    highlightInput,
    specs,
    specsInput,
    selectedExams,
    name,
    description,
    price,
    alias,
    tags,
    discount,
    selectedCategories,
    stock,
    author,
    publisher,
    imagesPreview,
    edition,
    bestSeller,
    itemDetails,
    displayPublisher,
    setSelectedCategories,
    setItemDetails,
    setDisplayPublisher,
    setHighlightInput,
    setSelectedExams,
    setName,
    setDescription,
    setPrice,
    setAlias,
    setDiscount,
    setStock,
    setAuthor,
    setPublisher,
    setEdition,
    setBestSeller,
    setTags,
    handleSpecsChange,
    addSpecs,
    addHighlight,
    deleteHighlight,
    deleteSpec,
    handleProductImageChange,
    newProductSubmitHandler,
    handleDeletePreviewImage,
  };
};

export default useNewProduct;
