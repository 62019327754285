import React from "react";

const Privacy = () => {
  return (
    <div className="flex flex-col gap-5 mt-5 p-5 bg-primary-lightGrey rounded-xl">
      <div className="text-2xl font-poppins600  text-primary-blue align-center">
        Privacy Policy
      </div>
      <div className="flex flex-col justify-between gap-2 text-gray-600 font-poppins400">
        <p className="font-poppins600 text-lg">
          At BooksDehra by Ramesh Book Depot, we are committed to protecting
          your privacy. This Privacy Policy outlines how we collect, use, and
          protect your personal information:
        </p>
        <ul className="list-disc pl-5">
          <li className="mb-3">
            <strong>Information We Collect:</strong>We collect personal
            information such as your name, email address, shipping address, and
            phone number when you create an account or place an order on our
            website.
          </li>
          <li className="mb-3">
            <strong>Use of Information:</strong>We use the information collected
            from you to process your orders, communicate with you about your
            orders, and improve our services. We do not sell or share your
            personal information with third parties for marketing purposes.
          </li>
          <li className="mb-3">
            <strong>Data Security:</strong> We take reasonable measures to
            protect the security and confidentiality of your personal
            information. However, please note that no method of transmission
            over the internet or electronic storage is 100% secure.
          </li>
          <li className="mb-3">
            <strong>Cookies:</strong> We use cookies to enhance your browsing
            experience on our website. You can choose to disable cookies in your
            web browser settings, but please note that some features of our
            website may not function properly without cookies.
          </li>
          <li className="mb-3">
            <strong>Intellectual Property:</strong> All content on our website,
            including text, images, and logos, is the property of BooksDehra by
            Ramesh Book Depot and is protected by copyright laws. You may not
            use, reproduce, or distribute any content from our website without
            our prior written consent.
          </li>
          <li className="mb-3">
            <strong>Third-Party Links:</strong>Our website may contain links to
            third-party websites. We are not responsible for the privacy
            practices or content of these third-party sites.
          </li>
          <li className="mb-3">
            <strong>Policy Updates:</strong> We reserve the right to update or
            modify this Privacy Policy at any time without prior notice. Your
            continued use of our website after any changes indicates your
            acceptance of the updated policy.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Privacy;
