import TestimonialCard from "./TestimonialCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };
  return (
    <Slider {...settings}>
      <TestimonialCard
        name="Prashansha Upadhyay"
        imageSrc="https://lh3.googleusercontent.com/a-/ALV-UjXTdU2_STnygfAOalu0wH-naZ2qahAhZm_X9RvvgcdDoGb7=w60-h60-p-rp-mo-ba4-br100"
        testimonial="It's fine...bought books from there ..they take the book sold by them back at 1/3rd price"
        rating={4.5}
        isSmallScreen={true}
      />
      <TestimonialCard
        name="Satendra Tiwari"
        imageSrc="https://lh3.googleusercontent.com/a-/ALV-UjVBXWZgDj7iBIRukPOPKfkJAv1_AbdA1sAwIDkkPwW7Qkih=w60-h60-p-rp-mo-br100"
        testimonial="An excellent book store having higher studies and specialised book. The owner is polite and soft spoken. It is just opposite Kotwali, Paltan Bajjar, Dehradun."
        rating={5}
        isSmallScreen={true}
      />
      <TestimonialCard
        name="Akshay Mishra"
        imageSrc="https://lh3.googleusercontent.com/a-/ALV-UjURq2Tv8fTxObpII_x0Vefr9Kalt4ScK5Cx_1JuikKILWY=w60-h60-p-rp-mo-ba4-br100"
        testimonial="I have visited this store one is in paltan market and one is in Nehru Colony in Dehradun i found here very nice and complete collection for all types of books stationary office stationary maps and many more things in a very good discounted rates. There are too much varieties available and you can find on your own without taking help. So must visit shop."
        rating={5}
        isSmallScreen={true}
      />
    </Slider>
  );
};

export default TestimonialSlider;
