import PropTypes from "prop-types";

const PriceSidebar = ({ purchaseItems }) => {
  let totalQuantity = 0;

  purchaseItems.forEach((item) => {
    totalQuantity += item.quantity;
  });

  return (
    <div className="flex sticky top-16 sm:h-screen flex-col sm:w-4/12 sm:px-1">
      <div className="flex flex-col bg-white rounded-xl shadow">
        <div className="px-6 py-3 border-b font-poppins500 text-gray-500">
          Price Details
        </div>

        <div className="flex flex-col gap-4 p-6 pb-3">
          <p className="flex justify-between">
            Price ({totalQuantity}
            {totalQuantity > 1 ? " items" : " item"})
            <span>
              ₹
              {purchaseItems
                ?.reduce(
                  (sum, item) => sum + item.product.price * item.quantity,
                  0
                )
                .toLocaleString()}
            </span>
          </p>
          <p className="flex justify-between">
            Discount{" "}
            <span className="text-primary-green">
              - ₹
              {purchaseItems
                ?.reduce(
                  (sum, item) =>
                    sum +
                    (item.product.price * item.quantity -
                      item.product.discountPrice * item.quantity),
                  0
                )
                .toLocaleString()}
            </span>
          </p>
          <p className="flex justify-between">
            Delivery Charges <span className="text-primary-green">FREE</span>
          </p>

          <div className="border border-dashed"></div>
          <p className="flex justify-between text-base md:text-lg font-poppins500">
            Total Amount{" "}
            <span>
              ₹
              {purchaseItems
                ?.reduce(
                  (sum, item) =>
                    sum + item.product.discountPrice * item.quantity,
                  0
                )
                .toLocaleString()}
            </span>
          </p>
          <div className="border border-dashed"></div>

          <p className="font-poppins500 text-primary-green text-sm">
            You will save ₹
            {purchaseItems
              ?.reduce(
                (sum, item) =>
                  sum +
                  (item.product.price * item.quantity -
                    item.product.discountPrice * item.quantity),
                0
              )
              ?.toLocaleString()}{" "}
            on this order
          </p>
        </div>
      </div>
    </div>
  );
};

PriceSidebar.propTypes = {
  purchaseItems: PropTypes.array.isRequired,
};

export default PriceSidebar;
