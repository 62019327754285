import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { loadScript } from "../../utils/paymentForm";
import { logo } from "../../utils/images";
import axios from "axios";
import { clearErrors, newOrder } from "../../actions/orderAction";

const usePayment = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const purchaseItems = useSelector((state) => state.purchase.purchasing);
  const purchaseAddress = useSelector((state) => state.purchase.address);

  const [payDisable, setPayDisable] = useState(false);

  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);

  const totalPrice = purchaseItems.reduce(
    (sum, item) => sum + item.product.discountPrice * item.quantity,
    0
  );

  const paymentData = {
    amount: Math.round(totalPrice),
    email: user?.email,
    phoneNo: purchaseAddress?.phoneNo,
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      enqueueSnackbar("Razorpay SDK failed to load. Please try again.", {
        variant: "error",
      });
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/payment/process",
      paymentData,
      config
    );

    const order_id = data.order.id;
    const token = localStorage.getItem("userToken");

    const authConfig = {
      headers: {
        Authorization: token,
      },
    };

    const apiKeyData = await axios.get(
      "/api/v1/payment/razorpayApiKey",
      authConfig
    );

    const razorpayApiKey = apiKeyData.data.key_id;

    const options = {
      key: razorpayApiKey,
      amount: paymentData.amount.toString(),
      currency: "INR",
      name: "BooksDehra",
      description: "Payment for Books",
      image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post("/api/v1/payment/success", data);

        if (result.status === 200) {
          enqueueSnackbar("Payment Successful", { variant: "success" });
          const orderData = {
            shippingInfo: purchaseAddress,
            orderItems: purchaseItems,
            paymentInfo: result.data,
            totalPrice,
          };
          dispatch(newOrder(orderData));
          navigate("/order/success");
        } else {
          enqueueSnackbar("Payment Failed. Please try again!!", {
            variant: "error",
          });
          navigate("/order/failed");
          setPayDisable(false);
        }
      },
      prefill: {
        name: user?.name,
        email: user?.email,
        contact: purchaseAddress?.phoneNo,
      },
      notes: {
        address: purchaseAddress?.address,
      },
      theme: {
        color: "#54f3d3",
      },
      modal: {
        ondismiss: function () {
          enqueueSnackbar("Payment Cancelled", { variant: "error" });
          setPayDisable(false);
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setPayDisable(true);
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
    }
    setPayDisable(false);
  }, [dispatch, error, enqueueSnackbar]);

  return { submitHandler, payDisable, totalPrice, purchaseItems };
};

export default usePayment;
