import {
  ADD_ITEM_PURCHASE,
  EMPTY_PURCHASE,
  REMOVE_ITEM_PURCHASE,
  GET_PURCHASE_ITEMS,
  PURCHASE_ITEMS_FAIL,
  UPDATE_PURCHASE_ITEMS,
  ADD_ADDRESS_PURCHASE,
  REMOVE_ADDRESS_PURCHASE,
} from "../constants/purchaseConstants";

export const addItemToPurchase = (product) => async (dispatch) => {
  if (Array.isArray(product)) {
    product.forEach((item) => {
      dispatch({ type: ADD_ITEM_PURCHASE, payload: item });
    });
    return;
  }

  dispatch({ type: ADD_ITEM_PURCHASE, payload: product });
};

export const removeItemFromPurchase = (product) => async (dispatch) => {
  dispatch({ type: REMOVE_ITEM_PURCHASE, payload: product });
};

export const updatePurchaseItems = (items) => async (dispatch) => {
  dispatch({ type: UPDATE_PURCHASE_ITEMS, payload: items });
};

export const emptyPurchase = () => async (dispatch) => {
  dispatch({ type: EMPTY_PURCHASE });
};

export const addAddressToPurchase = (address) => async (dispatch) => {
  dispatch({ type: ADD_ADDRESS_PURCHASE, payload: address });
};

export const removeAddressFromPurchase = () => async (dispatch) => {
  dispatch({ type: REMOVE_ADDRESS_PURCHASE });
};

export const getPurchaseItems = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PURCHASE_ITEMS });
    return getState().purchase.purchasing;
  } catch (error) {
    dispatch({ type: PURCHASE_ITEMS_FAIL, payload: error.message });
  }
};
