import {
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_FAIL,
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST_REQUEST,
  REMOVE_FROM_WISHLIST,
  REMOVE_FROM_WISHLIST_FAIL,
  GET_WISHLIST_ITEMS_REQUEST,
  GET_WISHLIST_ITEMS_FAIL,
  GET_WISHLIST_ITEMS,
  CLEAR_WISHLIST_REQUEST,
  CLEAR_WISHLIST,
  CLEAR_WISHLIST_FAIL,
  CLEAR_ERRORS,
} from "../constants/wishlistConstants";

export const wishlistReducer = (
  state = { wishlistItems: [], loading: false },
  { type, payload }
) => {
  const item = payload;
  const itemExist = state.wishlistItems?.find((i) => i?._id === item?._id);
  switch (type) {
    case ADD_TO_WISHLIST_REQUEST:
    case REMOVE_FROM_WISHLIST_REQUEST:
    case GET_WISHLIST_ITEMS_REQUEST:
    case CLEAR_WISHLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_WISHLIST_FAIL:
    case REMOVE_FROM_WISHLIST_FAIL:
    case GET_WISHLIST_ITEMS_FAIL:
    case CLEAR_WISHLIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ADD_TO_WISHLIST:
      if (itemExist) {
        return {
          ...state,
          loading: false,
          wishlistItems: state.wishlistItems?.map((i) =>
            i?._id === itemExist?._id ? item : i
          ),
        };
      } else {
        return {
          ...state,
          loading: false,
          wishlistItems: [...state.wishlistItems, item],
        };
      }
    case REMOVE_FROM_WISHLIST:
      return {
        ...state,
        loading: false,
        wishlistItems: state.wishlistItems.filter((i) => i?._id !== payload),
      };
    case GET_WISHLIST_ITEMS:
      return {
        ...state,
        loading: false,
        wishlistItems: payload,
      };
    case CLEAR_WISHLIST:
      return {
        ...state,
        loading: false,
        wishlistItems: [],
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
