export const getDiscount = (price, discountPrice) => {
  const priceValue = parseFloat(price);
  const discountPriceValue = parseFloat(discountPrice);

  if (!priceValue || !discountPriceValue || priceValue <= discountPriceValue) {
    return 0;
  }

  const discount = ((priceValue - discountPriceValue) / priceValue) * 100;

  return Math.round(discount);
};

export const getDeliveryDate = () => {
  const deliveryDate = new Date();
  deliveryDate.setDate(new Date().getDate() + 7);
  return deliveryDate.toUTCString().substring(0, 11);
};

export const formatDate = (dt) => {
  return new Date(dt).toUTCString().substring(0, 16);
};

export const getRandomProducts = (prodsArray, n) => {
  return prodsArray.sort(() => 0.5 - Math.random()).slice(0, n);
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
