import {
  EqualizerIcon,
  ShoppingBagIcon,
  InventoryIcon,
  GroupIcon,
  ReviewsIcon,
  AddBoxIcon,
  LogoutIcon,
  AccountBoxIcon,
} from "./iconImports";

export const navMenu = [
  {
    icon: <EqualizerIcon />,
    label: "Dashboard",
    ref: "/admin/dashboard",
  },
  {
    icon: <ShoppingBagIcon />,
    label: "Orders",
    ref: "/admin/orders",
  },
  {
    icon: <InventoryIcon />,
    label: "Products",
    ref: "/admin/products",
  },
  {
    icon: <AddBoxIcon />,
    label: "Add Product",
    ref: "/admin/new_product",
  },
  {
    icon: <GroupIcon />,
    label: "Users",
    ref: "/admin/users",
  },
  {
    icon: <ReviewsIcon />,
    label: "Reviews",
    ref: "/admin/reviews",
  },
  {
    icon: <AccountBoxIcon />,
    label: "My Profile",
    ref: "/account",
  },
  {
    icon: <LogoutIcon />,
    label: "Logout",
  },
];

export const categoryNames = [
  "Forestry",
  "Ayurveda",
  "Yoga",
  "IGNOU",
  "Fiction/Non-Fiction",
  "Psychology",
  "General Competition",
  "Law",
  "Medical",
  "Dental",
  "Nursing",
  "Physiotherapy",
  "Agriculture",
  "Humanities",
  "Science",
  "Pharmacy",
  "B Ed",
  "Polytechnic",
];
