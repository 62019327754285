import { FavoriteIcon } from "../../utils/iconImports";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import useProduct from "../../hooks/products/product";
import { Rating } from "@mui/material";

const Product = ({
  _id,
  name,
  author,
  images,
  ratings,
  numOfReviews,
  price,
  discountPrice,
}) => {
  const {
    addToWishlistHandler,
    itemInWishlist,
    getDiscount,
    loading,
    heartColor,
  } = useProduct(_id);

  return (
    <div className="flex flex-col items-center gap-2 px-2 py-4 md:px-4 md:py-6 relative hover:shadow-lg rounded-xl cursor-pointer">
      <Link
        to={`/product/${_id}`}
        className="flex flex-col items-center text-center group"
      >
        <div className="w-44 h-48">
          <img
            draggable="false"
            className="w-full h-full object-contain"
            src={images[0]?.url}
            alt="Product img"
          />
        </div>
        <div className="text-sm md:text-lg mt-4 group-hover:text-primary-blue text-center">
          {name.length > 85 ? `${name.substring(0, 85)}...` : name}
        </div>
        <span className="text-gray-500 text-sm font-poppins500">{author}</span>

        <div className="flex flex-col gap-2 items-center">
          <span className=" text-gray-500 font-poppins500 flex gap-2 items-center">
            <Rating
              name="read-only"
              value={ratings}
              readOnly
              size="small"
              precision={0.5}
              sx={{
                color: "#FFC107",
              }}
            />
            <span>({numOfReviews})</span>
          </span>
          <div className="flex items-center gap-1.5 text-base font-poppins500">
            <span>₹{discountPrice?.toLocaleString()}</span>
            <span className="text-gray-500 line-through text-xs">
              ₹{price?.toLocaleString()}
            </span>
            <span className="text-xs text-primary-green">
              {getDiscount(price, discountPrice)}%&nbsp;off
            </span>
          </div>
        </div>
      </Link>
      <button
        onClick={addToWishlistHandler}
        className={`${loading ? "pointer-events-none" : ""} ${
          itemInWishlist ? "lg:hover:text-gray-300" : "lg:hover:text-red-500 "
        }  absolute top-6 right-6 cursor-pointer`}
      >
        <FavoriteIcon
          sx={{
            fontSize: "18px",
            color: heartColor,
          }}
        />
      </button>
    </div>
  );
};

Product.propTypes = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  author: PropTypes.string.isRequired,
  ratings: PropTypes.number.isRequired,
  numOfReviews: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  discountPrice: PropTypes.number.isRequired,
};

export default Product;
