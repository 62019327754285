export const shop1 = "Nehru Colony Outlet (Dehradun's First Book Mall)";
export const address1 =
  "Ramesh Book Depot, Book Tower, Nehru Colony, Opp. Uniyal Bakers, Near Hotel Him Palace, Dehradun, Uttarakhand, 248001";
export const addressLink1 =
  "https://www.google.com/maps/place/Ramesh+Book+Depot+(Book+Tower)/@30.300789,78.0508843,15z/data=!4m2!3m1!1s0x0:0x589892158a5f3d98?sa=X&ved=2ahUKEwjSjMCE3OaEAxXuQPUHHa_lDsAQ_BJ6BAhfEAA";
export const phone1 = "+91 - 730 219 1947";
export const mail1 = "rbdatbooktower@gmail.com";

export const shop2 = "Moti Bazar Outlet (The Legacy Store)";
export const address2 =
  "Ramesh Book Depot, Moti Bazar (Book Market), Dehradun, Uttarakhand, 248001";
export const addressLink2 =
  "https://www.google.com/maps/place/Ramesh+Book+Depot/@30.3212244,77.961759,13z/data=!4m10!1m2!2m1!1sramesh+book+depot!3m6!1s0x39092994b7c13ee1:0xfc04deeb781007f4!8m2!3d30.3212244!4d78.0379767!15sChFyYW1lc2ggYm9vayBkZXBvdFoTIhFyYW1lc2ggYm9vayBkZXBvdJIBCmJvb2tfc3RvcmXgAQA!16s%2Fg%2F11dx9h8lgr?entry=ttu";
export const phone2 = "+91 - 639 646 3973";
export const phone3 = "+91 - 956 842 2831";
export const mail2 = "rbdthebookshop50@gmail.com";

export const instaLink = "https://www.instagram.com/ramesh.book.depot";
export const fbLink = "https://www.facebook.com/RBDDDN";
export const linkedinLink = "https://www.linkedin.com/company/rbdddn/about/";
export const linkTreeLink = "https://linktr.ee/RBDDDN";
