import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const ProtectedRoute = ({ children, isAdmin }) => {
  const { loading, isAuthenticated, user } = useSelector((state) => state.user);
  let content = null;

  if (loading === false) {
    if (isAuthenticated === false) {
      content = <Navigate to="/login" />;
    } else if (isAdmin) {
      if (user.role !== "admin") {
        content = <Navigate to="/login" />;
      } else {
        content = children;
      }
    } else {
      content = children;
    }
  }

  return <div className="w-[98%] mx-auto">{content}</div>;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAdmin: PropTypes.bool,
};

export default ProtectedRoute;
