import React from "react";
import { TextField, MenuItem, Chip } from "@mui/material";
import PropTypes from "prop-types";
import { exams } from "../../utils/constants";
import { CloseIcon } from "../../utils/iconImports";
import { useSnackbar } from "notistack";

const Exams = ({ selectedExams, setSelectedExams }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAddExam = (e, inputValue) => {
    e.preventDefault();
    if (inputValue && !selectedExams.includes(inputValue)) {
      setSelectedExams([...selectedExams, inputValue]);
    } else {
      enqueueSnackbar("Exam already added", { variant: "info" });
    }
  };

  const handleRemoveExam = (examToRemove) => {
    setSelectedExams(selectedExams.filter((exam) => exam !== examToRemove));
  };

  return (
    <div className="flex-col justify-evenly gap-2">
      <div className="font-poppins600 mb-2">Exams</div>
      <TextField
        label="Exams"
        select
        fullWidth
        variant="outlined"
        size="small"
        value={
          selectedExams[selectedExams.length - 1]
            ? selectedExams[selectedExams.length - 1]
            : ""
        }
        multiple
        onChange={(e) => handleAddExam(e, e.target.value)}
        style={{ marginBottom: "10px" }}
      >
        {exams.map((exam, i) => (
          <MenuItem value={exam} key={exam}>
            {exam}
          </MenuItem>
        ))}
      </TextField>
      <div className="mt-2">
        <div className="font-poppins500">Selected Exams</div>
        {selectedExams?.length === 0 && (
          <p className="text-red-500">No exams selected</p>
        )}
        {selectedExams?.map((exam, index) => (
          <Chip
            key={exam}
            label={exam}
            className="hover:bg-red-500 font- transition-all duration-300 ease-in-out"
            onDelete={() => handleRemoveExam(exam)}
            color="primary"
            variant="outlined"
            deleteIcon={<CloseIcon />}
            style={{
              marginRight: "5px",
              marginBottom: "5px",
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

Exams.propTypes = {
  selectedExams: PropTypes.array.isRequired,
  setSelectedExams: PropTypes.func.isRequired,
};

export default Exams;
