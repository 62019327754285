import React from "react";
import {
  AccountCircleIcon,
  PowerSettingsNewIcon,
  DashboardIcon,
} from "../../../utils/iconImports";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import usePrimaryDropdown from "../../../hooks/layouts/primaryDropdown";

const PrimaryDropDownMenu = ({ setTogglePrimaryDropDown, user }) => {
  const { handleLogout, wishlistItems, navs } = usePrimaryDropdown(
    setTogglePrimaryDropDown,
    user
  );

  return (
    <div className="absolute w-44 -left-32  ml-2 bg-primary-lightGrey top-6 shadow-2xl rounded-md flex-col text-sm z-20">
      {user?.role === "admin" && (
        <Link
          className="pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-50 rounded-t font-poppins400"
          to="/admin/dashboard"
          onClick={() => setTogglePrimaryDropDown(false)}
        >
          <DashboardIcon
            sx={{ fontSize: "18px" }}
            className="text-primary-gray"
          />
          Admin Dashboard
        </Link>
      )}

      <Link
        className="pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-50 rounded-t font-poppins400"
        to="/account"
        onClick={() => setTogglePrimaryDropDown(false)}
      >
        <AccountCircleIcon
          sx={{ fontSize: "18px" }}
          className="text-primary-gray"
        />
        My Profile
      </Link>

      {user?.role === "user" &&
        navs.map((item, i) => {
          const { title, icon, redirect } = item;

          return (
            <React.Fragment key={title}>
              {title === "Wishlist" ? (
                <Link
                  className="pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-50"
                  to={redirect}
                  onClick={() => setTogglePrimaryDropDown(false)}
                >
                  <span className="text-primary-gray">{icon}</span>
                  {title}
                  <span className="ml-auto mr-3 bg-gray-100 p-0.5 px-2 text-gray-600 rounded">
                    {wishlistItems.length}
                  </span>
                </Link>
              ) : (
                <Link
                  className="pl-3 py-3.5 border-b flex gap-3 items-center hover:bg-gray-50"
                  to={redirect}
                  onClick={() => setTogglePrimaryDropDown(false)}
                >
                  <span className="text-primary-gray">{icon}</span>
                  {title}
                </Link>
              )}
            </React.Fragment>
          );
        })}

      <button
        className="pl-3 py-3.5 flex gap-3 items-center hover:bg-gray-50 border-b cursor-pointer w-full font-poppins400 z-20"
        onClick={handleLogout}
      >
        <PowerSettingsNewIcon
          sx={{ fontSize: "18px" }}
          className="text-primary-gray"
        />
        Logout
      </button>
    </div>
  );
};

PrimaryDropDownMenu.propTypes = {
  setTogglePrimaryDropDown: PropTypes.func,
  user: PropTypes.object,
};

export default PrimaryDropDownMenu;
