import axios from "axios";
import {
  SAVE_SHIPPING_INFO_REQUEST,
  SAVE_SHIPPING_INFO,
  SAVE_SHIPPING_INFO_FAIL,
  REMOVE_SHIPPING_INFO_REQUEST,
  REMOVE_SHIPPING_INFO,
  REMOVE_SHIPPING_INFO_FAIL,
  CLEAR_SHIPPING_INFO_REQUEST,
  CLEAR_SHIPPING_INFO,
  CLEAR_SHIPPING_INFO_FAIL,
  CLEAR_ERRORS,
} from "../constants/addressConstants";

export const saveShippingInfo = (data) => async (dispatch) => {
  try {
    dispatch({ type: SAVE_SHIPPING_INFO_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.post(`/api/v1/saveAddress`, data, config);

    dispatch({
      type: SAVE_SHIPPING_INFO,
      payload: response.data.shippingInfo,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SAVE_SHIPPING_INFO_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const removeShippingInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_SHIPPING_INFO_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.delete(`/api/v1/removeAddress/${id}`, config);

    dispatch({
      type: REMOVE_SHIPPING_INFO,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: REMOVE_SHIPPING_INFO_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearShippingInfo = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_SHIPPING_INFO_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    await axios.delete(`/api/v1/clearAllAddresses`, config);

    dispatch({ type: CLEAR_SHIPPING_INFO });
  } catch (error) {
    console.log(error);
    dispatch({
      type: CLEAR_SHIPPING_INFO_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
