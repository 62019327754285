import { TextField, IconButton } from "@mui/material";
import { BackdropLoader, MetaData, FormSidebar } from "../../utils/imports";
import { VisibilityIcon, VisibilityOffIcon } from "../../utils/iconImports";
import useResetPassword from "../../hooks/user/resetPassword";

const ResetPassword = () => {
  const {
    newPassword,
    setNewPassword,
    confirmPassword,
    showPassword,
    handleShowPassword,
    setConfirmPassword,
    handleSubmit,
    loading,
  } = useResetPassword();

  return (
    <div className="flex flex-col items-center justify-center mt-16 md:mt-24">
      <MetaData title="Password Reset | BooksDehra" />

      {loading && <BackdropLoader />}
      <main className="w-4/5 lg:w-3/4">
        <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-primary-lightGrey rounded-2xl shadow-lg">
          <FormSidebar
            title="Reset Password"
            tag="Get access to your Account again!"
          />

          <div className="flex-1 overflow-hidden">
            <div className="text-center text-2xl font-poppins500 mt-6 text-gray-800">
              Reset Password
            </div>
            <div className="text-center py-10 px-4 sm:px-14">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col w-full gap-4">
                  <TextField
                    fullWidth
                    label="New Password"
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Confirm New Password"
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    InputProps={
                      showPassword
                        ? {
                            endAdornment: (
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={handleShowPassword}
                              >
                                <VisibilityOffIcon />
                              </IconButton>
                            ),
                          }
                        : {
                            endAdornment: (
                              <IconButton
                                aria-label="Show password"
                                onClick={handleShowPassword}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ),
                          }
                    }
                  />

                  <button
                    type="submit"
                    className="text-white py-3 w-full bg-primary-darkBlue shadow hover:shadow-lg rounded-xl font-poppins500"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ResetPassword;
