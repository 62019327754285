import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  clearErrors,
  deleteOrder,
  getAllOrders,
  exportOrdersData,
} from "../../actions/orderAction";
import { DELETE_ORDER_RESET } from "../../constants/orderConstants";
import Actions from "../../components/Admin/Actions";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import "./colstyle.css";
const ExcelJS = require("exceljs");

const useOrderTable = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const {
    orders,
    error,
    ordersCount,
    loading: ordersLoading,
  } = useSelector((state) => state.allOrders);
  const {
    loading,
    isDeleted,
    isUpdated,
    error: deleteError,
  } = useSelector((state) => state.order);

  const { exportOrders, exportLoading, exportError } = useSelector(
    (state) => state.exportOrders
  );

  const [sortModel, setSortModel] = useState([]);

  const [filterModel, setFilterModel] = useState({ items: [] });
  const [rowCountState, setRowCountState] = useState(
    typeof ordersCount === "number" ? ordersCount : 100
  );

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: Math.min(100, rowCountState),
  });

  useEffect(() => {
    if (location.pathname === "/admin/orders") {
      dispatch(
        getAllOrders(
          paginationModel.page,
          paginationModel.pageSize,
          filterModel,
          sortModel
        )
      );
    }
    // eslint-disable-next-line
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (ordersCount) {
      setRowCountState(ordersCount);
    }
  }, [ordersCount]);

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
    if (deleteError) {
      dispatch(clearErrors());
    }
    if (exportError) {
      dispatch(clearErrors());
    }
  }, [error, deleteError, exportError, dispatch]);

  useEffect(() => {
    if (isDeleted) {
      dispatch({ type: DELETE_ORDER_RESET });
    }
    dispatch(
      getAllOrders(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel,
        sortModel
      )
    );
    // eslint-disable-next-line
  }, [
    dispatch,
    paginationModel.page,
    paginationModel.pageSize,
    filterModel,
    sortModel,
    isDeleted,
    isUpdated,
  ]);

  const deleteOrderHandler = (id) => {
    dispatch(deleteOrder(id));
    if (isDeleted) {
      enqueueSnackbar("Order Deleted Successfully", {
        variant: "success",
      });
    } else if (error) {
      enqueueSnackbar(`${error}`, {
        variant: "error",
      });
    }
  };

  const handleDownload = async (data) => {
    console.log(data);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Orders");

    worksheet.columns = [
      { header: "Bill No", key: "BillNo", width: 40 },
      { header: "Series", key: "Series", width: 10 },
      { header: "MC_Name", key: "MCName", width: 20 },
      { header: "Order Date", key: "OrderDate", width: 20 },
      { header: "Unit", key: "Unit", width: 10 },
      { header: "Name", key: "Name", width: 20 },
      { header: "Customer Name", key: "CustomerName", width: 20 },
      { header: "Email", key: "Email", width: 25 },
      { header: "Phone", key: "Phone", width: 15 },
      {
        header: "Item Name",
        key: "ItemName",
        width: 30,
        style: { alignment: { wrapText: true } },
      },
      {
        header: "ItemAlias",
        key: "ItemAlias",
        width: 30,
        style: { alignment: { wrapText: true } },
      },
      { header: "Author", key: "Author", width: 20 },
      { header: "Publisher", key: "Publisher", width: 20 },
      { header: "Price", key: "Price", width: 10 },
      { header: "Quantity", key: "Quantity", width: 10 },
      { header: "DiscountPrice", key: "DiscountPrice", width: 20 },
      { header: "Amount", key: "Amount", width: 12 },
      { header: "OrderStatus", key: "OrderStatus", width: 15 },
      {
        header: "ShippingAddress",
        key: "ShippingAddress",
        width: 50,
        style: { alignment: { wrapText: true } },
      },
    ];

    data.forEach((item) => {
      worksheet.addRow(item);
    });

    worksheet.columns.forEach((column) => {
      column.eachCell((cell) => {
        cell.alignment = { vertical: "top", wrapText: true };
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, "Orders.xlsx");
  };

  useEffect(() => {
    if (exportOrders && exportOrders.length > 0 && !exportLoading) {
      enqueueSnackbar("Orders Exported Successfully", {
        variant: "success",
      });
      handleDownload(exportOrders);
      console.log(exportOrders);
    } else if (exportError) {
      enqueueSnackbar(`${exportError}`, {
        variant: "error",
      });
    }
  }, [exportOrders, exportLoading, exportError, enqueueSnackbar]);

  const exportDataHandler = () => {
    dispatch(exportOrdersData());
  };

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      minWidth: 220,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="flex justify-center items-center h-full">
            {params.value}
          </span>
        );
      },
    },
    {
      field: "orderStatus",
      headerName: "Status",
      minWidth: 120,
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        let statusColorClass = "";
        let statusText = "";

        if (params?.row?.orderStatus === "Delivered") {
          statusColorClass = "bg-green-100 text-green-800";
          statusText = params?.row?.orderStatus;
        } else if (params?.row?.orderStatus === "Shipped") {
          statusColorClass = "bg-yellow-100 text-yellow-800";
          statusText = params?.row?.orderStatus;
        } else if (params?.row?.orderStatus === "Cancelled") {
          statusColorClass = "bg-red-100 text-red-800";
          statusText = params?.row?.orderStatus;
        } else {
          statusColorClass = "bg-purple-100 text-purple-800";
          statusText = params?.row?.orderStatus;
        }
        return (
          <span
            className={`text-sm p-1 px-2 font-poppins500 rounded-full ${statusColorClass}`}
          >
            {statusText}
          </span>
        );
      },
    },
    {
      field: "totalPrice",
      headerName: "Amount",
      type: "number",
      minWidth: 100,
      flex: 0.2,
      renderCell: (params) => {
        return <span>₹{params?.row?.amount?.toLocaleString()}</span>;
      },
    },
    {
      field: "orderItems",
      headerName: "Ordered Items",
      minWidth: 700,
      flex: 0.5,
      sortable: false,
      filterable: false,
      scrollable: true,
      renderCell: (params) => {
        return (
          <span className="scrollable flex flex-col gap-1 overflow-x-auto overflow-y-auto ">
            {params?.row?.orderItems?.map((item, index) => (
              <span key={index} className="flex gap-2 items-center">
                <span className="text-sm font-poppins500">
                  {item?.product?.alias}
                </span>
                <span className="text-sm font-poppins500">
                  {item?.product?.name} x {item?.quantity}
                </span>
                <span className="text-sm font-poppins400">
                  ₹{item?.product?.price?.toLocaleString()}
                </span>
                <span className="text-sm font-poppins400">
                  ₹{item?.product?.discountPrice?.toLocaleString()}
                </span>
                <span className="text-sm font-poppins400">
                  {item?.product?.author}
                </span>
                <span className="text-sm font-poppins400">
                  {item?.product?.publisher}
                </span>
              </span>
            ))}
          </span>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Order On",
      type: "date",
      minWidth: 100,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <span className="flex justify-center items-center h-full py-4">
            {params?.row?.createdAt.toLocaleDateString()}
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.3,
      type: "number",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Actions
            editRoute={"order"}
            deleteHandler={deleteOrderHandler}
            id={params?.row?.id}
          />
        );
      },
    },
  ];

  const rows = [];

  orders?.forEach((order) => {
    rows.push({
      id: order?._id,
      amount: order?.totalPrice,
      createdAt: new Date(order?.createdAt),
      orderStatus: order?.orderStatus,
      orderItems: order?.orderItems,
    });
  });

  return {
    columns,
    rows,
    loading,
    ordersLoading,
    paginationModel,
    filterModel,
    rowCountState,
    sortModel,
    setFilterModel,
    setPaginationModel,
    setSortModel,
    exportDataHandler,
  };
};

export default useOrderTable;
