import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";

const Confirmation = ({ open, confirmationText, onClose, onConfirm, task }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        className="rounded-2xl shadow-2 z-[5000]"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <div className="text-gray-500 ">
            <span className="font-poppins500"> {confirmationText}</span>
            <br /> This process cannot be undone.
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={onClose}
            className="py-2 px-6 mb-4 rounded-xl shadow bg-gray-400 hover:bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="py-2 px-6 mx-4 mb-4 rounded-xl bg-red-600 hover:bg-red-700 text-white shadow"
          >
            {task}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Confirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmationText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  task: PropTypes.string.isRequired,
};

export default Confirmation;
