import React from "react";
import Marquee from "react-fast-marquee";
import { publishers } from "../../../utils/constants";

const Publishers = () => {
  return (
    <div className="flex flex-col gap-6 xl:gap-10 ">
      <div className="text-xl sm:text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-poppins600">
        {" "}
        Featured publishers
      </div>
      <Marquee velocity={25}>
        {publishers.map((publisher, index) => (
          <div
            className="flex flex-row items-center gap-2 m-6 cursor-pointer"
            key={publisher.name}
          >
            <img
              src={publisher.image}
              alt="logo"
              className="w-10 h-10 md:w-20 md:h-20"
            />
            <p className="text-lg sm:text-3xl font-poppins600 text-center">
              {publisher.name}
            </p>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Publishers;
