import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearErrors,
  getProductDetails,
  getSimilarProducts,
  newReview,
} from "../../actions/productAction";
import { NextBtn, PreviousBtn } from "../../utils/imports";
import { NEW_REVIEW_RESET } from "../../constants/productConstants";
import { DIRECT_PURCHASE_TRUE } from "../../constants/purchaseConstants";
import { addItemToPurchase } from "../../actions/purchasingActions";
import { addItemsToCart } from "../../actions/cartAction";
import { getDeliveryDate, getDiscount } from "../../utils/functions";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../actions/wishlistAction";

const useProductDetails = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [qty, setQty] = useState(1);

  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );
  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );
  const { cartItems } = useSelector((state) => state.cart);
  const { wishlistItems, error: wishlistError } = useSelector(
    (state) => state.wishlist
  );

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
  };

  const productId = params.id;
  const itemInWishlist = wishlistItems?.some((i) => i?._id === productId);
  const userToken = localStorage.getItem("userToken");

  const [heartColor, setHeartColor] = useState(
    itemInWishlist ? "#FF0000" : "#CCCCCC"
  );

  const addToWishlistHandler = () => {
    if (!userToken) {
      enqueueSnackbar("Please login to add to wishlist", {
        variant: "error",
      });
      return;
    }
    if (itemInWishlist) {
      setHeartColor("#CCCCCC");
      dispatch(removeFromWishlist(productId));
      if (wishlistError) {
        setHeartColor("#FF0000");
        enqueueSnackbar(wishlistError, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Removed From Wishlist", {
          variant: "info",
        });
      }
    } else {
      setHeartColor("#FF0000");
      dispatch(addToWishlist(productId));
      if (wishlistError) {
        setHeartColor("#CCCCCC");
        enqueueSnackbar(wishlistError, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Added To Wishlist", {
          variant: "success",
        });
      }
    }
  };

  const reviewSubmitHandler = () => {
    if (!userToken) {
      enqueueSnackbar("Please login to review", {
        variant: "error",
      });
      return;
    }
    if (rating === 0 || !comment.trim()) {
      enqueueSnackbar("Empty Review", {
        variant: "error",
      });
      return;
    }
    const formData = new FormData();
    formData.set("rating", rating);
    formData.set("comment", comment);
    formData.set("productId", productId);
    dispatch(newReview(formData));
    setOpen(false);
  };

  const addToCartHandler = () => {
    if (!userToken) {
      enqueueSnackbar("Please login to add to cart", {
        variant: "error",
      });
      return;
    }
    if (qty > product.stock) {
      enqueueSnackbar("Product is out of stock", {
        variant: "warning",
      });
      return;
    }
    dispatch(addItemsToCart(productId, qty));
    enqueueSnackbar("Product Added To Cart", {
      variant: "success",
    });
  };

  const handleDialogToggle = () => {
    setOpen(!open);
  };

  const itemInCart = cartItems?.some((i) => i.product === productId);

  const goToCart = () => {
    navigate("/cart");
  };

  const increaseQuantity = () => {
    const count = qty + 1;
    if (count > product?.stock) {
      enqueueSnackbar("Product is out of stock", {
        variant: "warning",
      });
      return;
    }
    setQty(count);
  };

  const decreaseQuantity = () => {
    const count = qty - 1;
    if (count < 1) return;
    setQty(count);
  };

  const buyNow = () => {
    const purchaseProduct = { product, quantity: qty };
    dispatch({ type: DIRECT_PURCHASE_TRUE });
    dispatch(addItemToPurchase(purchaseProduct));
    navigate("/shipping");
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (reviewError) {
      enqueueSnackbar(` ${reviewError}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (success) {
      enqueueSnackbar("Review Submitted Successfully", {
        variant: "success",
      });
      dispatch({ type: NEW_REVIEW_RESET });
    }
    dispatch(getProductDetails(productId));
    // eslint-disable-next-line
  }, [dispatch, productId, error, reviewError, success, enqueueSnackbar]);

  useEffect(() => {
    if (product?.categories && product?.categories[0] !== "N/A")
      dispatch(getSimilarProducts(product?.categories[0]));
  }, [dispatch, product]);

  return {
    product,
    loading,
    settings,
    addToWishlistHandler,
    open,
    handleDialogToggle,
    reviewSubmitHandler,
    rating,
    setRating,
    comment,
    setComment,
    qty,
    increaseQuantity,
    decreaseQuantity,
    buyNow,
    addToCartHandler,
    itemInCart,
    goToCart,
    getDeliveryDate,
    getDiscount,
    viewAll,
    setViewAll,
    heartColor,
  };
};

export default useProductDetails;
