import { useState, useEffect } from "react";
import { DeleteIcon } from "../../utils/iconImports";
import { removeShippingInfo, clearErrors } from "../../actions/addressAction";
import { useDispatch, useSelector } from "react-redux";
import { Confirmation } from "../../utils/imports";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

const Address = (props) => {
  const { address, city, state, pincode, phoneNo, _id, isLastAddress } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { error } = useSelector((state) => state.address);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const deleteHandler = () => {
    dispatch(removeShippingInfo(_id));
    if (error) {
      enqueueSnackbar(`Facing some issue ${error}`, { variant: "error" });
    } else {
      enqueueSnackbar(`Address removed successfully`, { variant: "success" });
    }
    setConfirmationOpen(false);
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, enqueueSnackbar, dispatch]);

  return (
    <div
      className={`flex gap-4 border-b p-4 sm:pb-8 group overflow-hidden ${
        !isLastAddress ? "border-b" : ""
      }`}
    >
      <div className="flex flex-col gap-5 p-1">
        <div className="flex justify-between items-start sm:pr-5">
          <div className="flex flex-col gap-0.5 text-base overflow-auto">
            <p className=" font-poppins500 w-64 md:w-full break-words">
              {address}
            </p>
            <span className=" text-gray-500 font-poppins500 flex-col items-center">
              <span>
                {city}, {state} - {pincode}
              </span>
              <div className="font-poppins500 ">Phone No:- {phoneNo}</div>
            </span>
          </div>
        </div>
        <div>
          <button
            className="flex items-center gap-2 text-sm font-poppins600 border rounded-xl text-red-500 sm:px-4 px-8 py-2 cursor-pointer hover:bg-red-500 hover:text-white "
            onClick={() => setConfirmationOpen(true)}
          >
            <DeleteIcon /> Delete
          </button>
        </div>
        <Confirmation
          open={confirmationOpen}
          confirmationText="Are you sure you want to delete this address?"
          onClose={() => setConfirmationOpen(false)}
          onConfirm={deleteHandler}
          task="Delete Address"
        />
      </div>
    </div>
  );
};

Address.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  pincode: PropTypes.number,
  phoneNo: PropTypes.number,
  _id: PropTypes.string,
  isLastAddress: PropTypes.bool,
};

export default Address;
