import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { BackdropLoader, MetaData, FormSidebar } from "../../utils/imports";
import useUpdateProfile from "../../hooks/user/updateProfile";

const UpdateProfile = () => {
  const {
    updateProfileHandler,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    loading,
  } = useUpdateProfile();

  return (
    <div className="flex flex-col items-center justify-center mt-16 md:mt-24">
      <MetaData title="Update Profile | BooksDehra" />
      {loading && <BackdropLoader />}
      <main className="w-4/5 lg:w-3/4">
        <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-primary-lightGrey shadow-lg rounded-2xl">
          <FormSidebar
            title="Update Profile"
            tag="Best book store in Dehradun"
          />
          <div className="flex-1 overflow-hidden">
            <div className="text-center text-2xl font-poppins500 mt-6 text-gray-800">
              Update Profile
            </div>
            <form
              onSubmit={updateProfileHandler}
              encType="multipart/form-data"
              className="p-5 sm:p-10"
            >
              <div className="flex flex-col gap-4 items-start">
                <div className="flex flex-col w-full justify-between sm:flex-col gap-3 items-center">
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="text-white py-3 w-full bg-primary-darkBlue shadow rounded-xl font-poppins500 hover:shadow-lg"
                >
                  Update
                </button>
                <Link
                  className="hover:bg-gray-100 text-primary-blue text-center py-3 w-full shadow border rounded-xl font-poppins500"
                  to="/account"
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UpdateProfile;
