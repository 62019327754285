import Product from "./Product";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Bestsellers.css";
import { arrow } from "../../../utils/images";
import { colors } from "../../../utils/constants";
import React from "react";

const Bestsellers = () => {
  const { products, loading } = useSelector((state) => state.bestSellers);

  let bestSellerProducts = null;
  if (!loading && products?.length > 0)
    bestSellerProducts = products.slice(0, 8);

  return (
    <section className="flex-col justify-between " id="bestSeller">
      <div className=" flex justify-between items-center mx-10  ">
        <div className=" flex flex-col justify-between gap-2">
          <p className="text-primary-blue items-center font-poppins600 tracking-widest text-base md:text-lg ">
            SHOP ONLINE
          </p>
          <div className="text-xl sm:text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-poppins600">
            Bestseller Books
          </div>
        </div>
        <Link
          to={`/products?bestSeller=${true}`}
          className=" text-xxs md:text-xs font-poppins700 rounded-full px-2 py-1 md:px-4 md:py-3 border border-gray-950 hover:text-white hover:bg-black transition-all duration-150 ease-in-out hover:shadow-xl mr-0 xl:mr-14 mt-8 sm:mt-14 tracking-normal xl:tracking-wider flex items-center group"
        >
          <span className="text-nowrap"> VIEW ALL </span>
          <img
            src={arrow}
            alt="arrow"
            className="sm:ml-1 group-hover:invert mr-2"
          />
        </Link>
      </div>
      <div className="scrollable overflow-x-scroll flex gap-4 xl:gap-6 mt-6 items-stretch py-4 ms-8 xl:ms-14 min-h-[420px] md:min-h-[520px] ">
        {bestSellerProducts?.map((product, index) => (
          <React.Fragment key={product._id}>
            <Product
              {...product}
              backgroundColor={colors[index % colors.length]}
            />
            {index === bestSellerProducts.length - 1 && (
              <div className="w-8 md:w-16"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

export default Bestsellers;
