import {
  ExpandLessIcon,
  ExpandMoreIcon,
  SearchIcon,
  MenuIcon,
} from "../../../utils/iconImports";
import { logo, cart } from "../../../utils/images";
import { Typography } from "@material-tailwind/react";
import Searchbar from "./Searchbar";
import PrimaryDropDownMenu from "./PrimaryDropDownMenu";
import { Link } from "react-router-dom";
// import NavLink from "./NavLink";
import useHeader from "../../../hooks/layouts/header";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const {
    isAuthenticated,
    user,
    cartItems,
    togglePrimaryDropDown,
    primaryDropDownRef,
    cartRedirect,
    isTablet,
    isMobile,
    burgerRef,
    searchRef,
    searchButtonRef,
    hamburgerButtonRef,
    toggleHamburger,
    showSearchBar,
    setTogglePrimaryDropDown,
    handleHamburgerClick,
    searchHandler,
    closeBurgerDropdown,
  } = useHeader();

  return (
    <div
      className={`flex flex-col items-center w-full ${
        showSearchBar || toggleHamburger ? "mb-8" : "mb-16"
      }  w-full fixed top-0 z-50 bg-white/100 py-1 md:py-0`}
    >
      <div className="fixed flex flex-row w-full px-1 md:px-6 justify-around items-center bg-white/100 gap-1 sm:gap-2 z-50 py-4 ">
        <a href="/" className="flex items-center sm:gap-2">
          <div className="w-6 h-6">
            <img src={logo} alt="Logo" className="object-contain" />
          </div>
          <Typography
            variant="h5"
            className="text-base sm:text-lg md:text-xl  lg:mr-4 xl:mr-12 "
          >
            <span className="font-poppins100">BOOKS</span>
            <span className="font-poppins500">DEHRA</span>
          </Typography>
        </a>
        {!isTablet && (
          <div className="flex flex-row justify-center items-center gap-10 text-lg font-poppins400">
            <HashLink smooth to="/" className={`hover:font-poppins600`}>
              Home
            </HashLink>
            <HashLink
              smooth
              to="/#bestSeller"
              className={`hover:font-poppins600`}
            >
              Bestsellers
            </HashLink>

            <HashLink
              smooth
              to="/#categories"
              className={`hover:font-poppins600`}
            >
              Categories
            </HashLink>
            <HashLink smooth to="/#exams" className={`hover:font-poppins600`}>
              Exams
            </HashLink>
          </div>
        )}
        {isMobile ? (
          <div className="w-3 h-3 sm:w-6 sm:h-6 mb-3 sm:mb-0">
            <button onClick={searchHandler} ref={searchButtonRef}>
              <SearchIcon />
            </button>
          </div>
        ) : (
          <div className="md:w-1/4 mx-auto">
            <Searchbar />
          </div>
        )}

        <div className="flex items-center justify-center gap-2 sm:gap-8">
          <Link to={cartRedirect} className=" font-poppins500 relative">
            <div className="w-4 h-4 sm:w-6 sm:h-6">
              <img src={cart} alt="Cart" />
            </div>

            {cartItems?.length > 0 && (
              <div className="w-3 h-3 sm:w-5 sm:h-5 bg-red-500 text-xxs lg:text-xs text-white rounded-full absolute -top-2 left-3 sm:-top-3 sm:left-5 flex justify-center items-center border">
                {cartItems?.length}
              </div>
            )}
          </Link>

          <div className="flex flex-row justify-center items-center gap-1 sm:gap-5 relative">
            {isAuthenticated === false ? (
              <Link
                to="/login"
                className="px-3 py-1 bg-primary-lightGrey border font-poppins500 rounded-xl cursor-pointer text-lg"
              >
                Login
              </Link>
            ) : (
              <button
                className="userDropDown flex items-center font-poppins500 cursor-pointer relative"
                onClick={() => setTogglePrimaryDropDown(!togglePrimaryDropDown)}
              >
                <span className="text-sm sm:text-base ">
                  {user?.name?.split(" ", 1)}
                </span>
                <span>
                  {togglePrimaryDropDown ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </span>
              </button>
            )}
            {togglePrimaryDropDown && (
              <span ref={primaryDropDownRef} className="absolute">
                <PrimaryDropDownMenu
                  setTogglePrimaryDropDown={setTogglePrimaryDropDown}
                  user={user}
                />
              </span>
            )}
            {isTablet && (
              <button
                ref={hamburgerButtonRef}
                className="cursor-pointer lg:hidden"
                onClick={handleHamburgerClick}
              >
                <MenuIcon />
              </button>
            )}
          </div>
        </div>
      </div>
      {showSearchBar && isMobile && (
        <div className="w-[96%] mx-auto mt-20 " ref={searchRef}>
          <Searchbar />
        </div>
      )}
      {toggleHamburger && (
        <div
          className="flex flex-col w-[94%] mt-14 sm:mt-20 gap-4 p-4 rounded-xl shadow-lg "
          ref={burgerRef}
        >
          <HashLink
            smooth
            to="/"
            className={`hover:font-poppins600`}
            onClick={closeBurgerDropdown}
          >
            Home
          </HashLink>
          <HashLink
            smooth
            to="/#bestSeller"
            className={`hover:font-poppins600`}
            onClick={closeBurgerDropdown}
          >
            Bestsellers
          </HashLink>

          <HashLink
            smooth
            to="/#categories"
            className={`hover:font-poppins600`}
            onClick={closeBurgerDropdown}
          >
            Categories
          </HashLink>
          <HashLink
            smooth
            to="/#exams"
            className={`hover:font-poppins600`}
            onClick={closeBurgerDropdown}
          >
            Exams
          </HashLink>
        </div>
      )}
    </div>
  );
};

export default Header;
