export const ADD_ITEM_PURCHASE = "ADD_TO_PURCHASE";
export const EMPTY_PURCHASE = "EMPTY_PURCHASE";
export const REMOVE_ITEM_PURCHASE = "REMOVE_FROM_PURCHASE";
export const GET_PURCHASE_ITEMS = "GET_PURCHASE_ITEMS";
export const UPDATE_PURCHASE_ITEMS = "UPDATE_PURCHASE_ITEMS";
export const PURCHASE_ITEMS_FAIL = "PURCHASE_ITEMS_FAIL";
export const ADD_ADDRESS_PURCHASE = "ADD_ADDRESS_PURCHASE";
export const REMOVE_ADDRESS_PURCHASE = "REMOVE_ADDRESS_PURCHASE";
export const CHANGE_ADDRESS_PURCHASE = "CHANGE_ADDRESS_PURCHASE";
export const DIRECT_PURCHASE_TRUE = "DIRECT_PURCHASE_TRUE";
export const DIRECT_PURCHASE_FALSE = "DIRECT_PURCHASE_FALSE";
