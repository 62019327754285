import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemsToCart,
  removeItemsFromCart,
  clearErrors,
} from "../../actions/cartAction";
import { getDeliveryDate, getDiscount } from "../../utils/functions";
import { saveForLater } from "../../actions/saveForLaterAction";

const useCartItem = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const { error } = useSelector((state) => state.cart);

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, enqueueSnackbar, dispatch]);

  const increaseQuantity = (id, quantity, stock) => {
    const newQty = parseInt(quantity) + 1;

    if (quantity >= stock) {
      enqueueSnackbar("Product is out of stock", {
        variant: "warning",
      });
      return;
    }
    dispatch(addItemsToCart(id, newQty));
    if (error) {
      enqueueSnackbar(`Facing some issue ${error}`, { variant: "error" });
    } else {
      enqueueSnackbar("Product Quantity Updated", {
        variant: "success",
      });
    }
  };

  const decreaseQuantity = (id, quantity) => {
    const newQty = quantity - 1;
    if (newQty < 1) {
      setConfirmationOpen(true);
    }
    dispatch(addItemsToCart(id, newQty));
    if (error) {
      enqueueSnackbar(`Facing some issue ${error}`, { variant: "error" });
    } else {
      enqueueSnackbar("Product Quantity Updated", {
        variant: "success",
      });
    }
  };

  const removeCartItem = (id) => {
    dispatch(removeItemsFromCart(id));
    if (error) {
      enqueueSnackbar(`Facing some issue ${error}`, { variant: "error" });
    } else {
      enqueueSnackbar("Product Removed From Cart", {
        variant: "success",
      });
    }
  };

  const saveForLaterHandler = (id) => {
    dispatch(saveForLater(id));
    removeCartItem(id);
    enqueueSnackbar("Product Saved For Later", {
      variant: "success",
    });
  };

  const handleRemove = (_id) => {
    removeCartItem(_id);
    setConfirmationOpen(false);
  };

  return {
    confirmationOpen,
    setConfirmationOpen,
    increaseQuantity,
    decreaseQuantity,
    handleRemove,
    getDeliveryDate,
    getDiscount,
    saveForLaterHandler,
  };
};

export default useCartItem;
