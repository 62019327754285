import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearShippingInfo, clearErrors } from "../../actions/addressAction";
import { useSnackbar } from "notistack";

const useManageAddress = () => {
  const dispatch = useDispatch();
  const { shippingInfo, loading, error } = useSelector(
    (state) => state.address
  );

  const { enqueueSnackbar } = useSnackbar();

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleRemoveAllAddresses = () => {
    dispatch(clearShippingInfo());
    if (error) {
      enqueueSnackbar(`Facing some issue ${error}`, { variant: "error" });
    } else {
      enqueueSnackbar(`All addresses removed successfully`, {
        variant: "success",
      });
    }
    setConfirmationOpen(false);
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, enqueueSnackbar, dispatch]);

  return {
    confirmationOpen,
    shippingInfo,
    setConfirmationOpen,
    handleRemoveAllAddresses,
    loading,
  };
};

export default useManageAddress;
