import TrackStepper from "./TrackStepper";
import { MetaData, Loader, Confirmation } from "../../utils/imports";
import useOrderDetails from "../../hooks/order/orderDetails";
import { Link } from "react-router-dom";
import { CancelOutlinedIcon } from "../../utils/iconImports";

const OrderDetails = () => {
  const {
    order,
    loading,
    confirmationOpen,
    setConfirmationOpen,
    cancelOrderHandler,
  } = useOrderDetails();

  return (
    <>
      <MetaData title="Order Details | BooksDehra" />
      <main className="w-full mt-8 sm:mt-4">
        {loading ? (
          <Loader />
        ) : (
          <>
            {order?.user && order?.shippingInfo && (
              <div className="flex flex-col gap-4 max-w-6xl mx-auto font-poppins400 mt-16 md:mt-24 rounded-xl ">
                <div className="flex flex-col sm:flex-row bg-white shadow rounded-xl min-w-full">
                  <div className="sm:w-1/2 sm:border-r">
                    <div className="flex flex-col gap-2.5 my-4 md:my-8 mx-6 md:mx-10">
                      <div className="font-poppins500 text-lg sm:text-xl">
                        Delivery Address
                      </div>
                      <div className="font-poppins500">{order?.user?.name}</div>
                      <p className="text-sm ">{`${order?.shippingInfo?.address}, ${order?.shippingInfo?.city}, ${order?.shippingInfo?.state} - ${order?.shippingInfo?.pincode}`}</p>
                      <div className="flex gap-2 items-center text-sm">
                        <p className="font-poppins500">Email:</p>
                        <p>{order?.user?.email}</p>
                      </div>
                      <div className="flex gap-2 items-center text-sm">
                        <p className="font-poppins500">Phone Number:</p>
                        <p>{order?.shippingInfo?.phoneNo}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex flex-col px-3 items-center gap-5 justify-center w-full md:w-3/4 rounded-r-xl  ${
                      order.orderStatus === "Cancelled" ? "bg-red-300/50" : ""
                    } `}
                  >
                    <div className="flex flex-col w-full sm:w-1/2">
                      <div className="font-poppins500 sm:text-center">
                        Order Status
                      </div>
                      <TrackStepper
                        orderOn={order?.createdAt}
                        shippedAt={order?.shippedAt}
                        deliveredAt={order?.deliveredAt}
                        cancelledAt={order?.cancelledAt}
                        activeStep={
                          {
                            Delivered: 2,
                            Shipped: 1,
                            Processing: 0,
                            Cancelled: 3,
                          }[order?.orderStatus]
                        }
                      />
                    </div>
                    <div className="flex justify-end items-end w-full mr-0 md:mr-3 mb-2 sm:mb-0 rounded-xl">
                      <button
                        className={`${
                          order?.orderStatus === "Delivered" ||
                          order?.orderStatus === "Shipped" ||
                          order?.orderStatus === "Cancelled"
                            ? "bg-primary-grey cursor-not-allowed"
                            : "bg-red-500 cursor-pointer text-white hover:bg-red-600"
                        } px-2 md:px-4 py-1 md:py-2 border rounded-lg flex items-center gap-2`}
                        onClick={() => setConfirmationOpen(true)}
                      >
                        <CancelOutlinedIcon className="w-6 h-6" />
                        <span>Cancel Order</span>
                      </button>
                    </div>
                  </div>
                  <Confirmation
                    open={confirmationOpen}
                    confirmationText="Are you sure you want to cancel your order?"
                    onClose={() => setConfirmationOpen(false)}
                    onConfirm={cancelOrderHandler}
                    task="Cancel Order"
                  />
                </div>

                {order?.orderItems?.map((item, index) => {
                  if (!item) {
                    return (
                      <div key={item._id}>
                        <p className="text-red-500 text-center">
                          Order Item Not Found
                        </p>
                      </div>
                    );
                  }

                  const { _id, product, quantity } = item || {};

                  return (
                    <div
                      className="flex flex-row min-w-full justify-center md:justify-start items-center md:items-start  shadow rounded-xl bg-white px-2 py-5"
                      key={_id}
                    >
                      <Link
                        to={`/product/${product?._id}`}
                        className="w-full sm:w-1/2"
                      >
                        <div className="flex flex-row gap-2 hover:cursor-pointer group">
                          <div className="w-36 h-40">
                            <img
                              draggable="false"
                              className="h-full w-full object-contain"
                              src={product?.images[0]?.url}
                              alt={product?.name}
                            />
                          </div>
                          <div className="flex flex-col gap-1 overflow-hidden ">
                            <p className="text-sm sm:text-lg group-hover:text-primary-blue">
                              {product?.name?.length > 60
                                ? `${product.name.substring(0, 60)}...`
                                : product?.name}
                            </p>
                            <p className="text-xs sm:text-sm text-gray-600">
                              {product?.author}
                            </p>
                            <p className="text-xs sm:text-sm text-gray-600">
                              {product?.alias}
                            </p>
                            <p className="text-xs sm:text-sm text-gray-600 mt-2">
                              Quantity: {quantity}
                            </p>
                            <p className="text-xs sm:text-sm text-gray-600">
                              Price: ₹{product?.discountPrice?.toLocaleString()}
                            </p>
                            <span className="font-poppins500">
                              Total: ₹
                              {(
                                quantity * product?.discountPrice
                              )?.toLocaleString()}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </main>
    </>
  );
};

export default OrderDetails;
