import {
  SAVE_SHIPPING_INFO,
  GET_SHIPPING_INFO,
  REMOVE_SHIPPING_INFO,
  CLEAR_SHIPPING_INFO,
  SAVE_SHIPPING_INFO_REQUEST,
  GET_SHIPPING_INFO_REQUEST,
  REMOVE_SHIPPING_INFO_REQUEST,
  REMOVE_SHIPPING_INFO_FAIL,
  CLEAR_SHIPPING_INFO_REQUEST,
  CLEAR_SHIPPING_INFO_FAIL,
  SAVE_SHIPPING_INFO_FAIL,
  CLEAR_ERRORS,
} from "../constants/addressConstants";

export const addressReducer = (
  state = { shippingInfo: [], loading: false },
  { type, payload }
) => {
  const item = payload;
  // const isItemExist = state.shippingInfo.find(
  //   (i) => i?.address === item?.address
  // );

  switch (type) {
    case REMOVE_SHIPPING_INFO_REQUEST:
    case CLEAR_SHIPPING_INFO_REQUEST:
    case GET_SHIPPING_INFO_REQUEST:
    case SAVE_SHIPPING_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: [...state.shippingInfo, payload],
        loading: false,
      };
    case GET_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: payload,
        loading: false,
      };
    case REMOVE_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: state.shippingInfo.filter((el) => el._id !== item),
        loading: false,
      };
    case CLEAR_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: [],
        loading: false,
      };
    case SAVE_SHIPPING_INFO_FAIL:
    case REMOVE_SHIPPING_INFO_FAIL:
    case CLEAR_SHIPPING_INFO_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
