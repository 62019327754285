import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getRandomProducts } from "../../../utils/functions";
import { arrow } from "../../../utils/images";
import {
  Container,
  NextBtn,
  PreviousBtn,
  Loader,
} from "../../../utils/imports";
import PropTypes from "prop-types";
import { clearErrors } from "../../../actions/productAction";
import Product from "./Product";
import { useEffect } from "react";

const ProductSlider = ({ title, tagline, categories }) => {
  const dispatch = useDispatch();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    initialSlide: 1,
    swipe: false,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { loading, products, error } = useSelector(
    (state) => state.similarProducts
  );

  let randomProducts = null;

  if (!loading && products?.length > 0) {
    randomProducts = getRandomProducts(products, 12);
  }

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [dispatch, error]);

  return (
    <Container>
      <div className="flex px-6 py-4 justify-between items-center">
        <div className="title flex flex-col gap-0.5">
          <div className="text-xl md:text-3xl font-poppins500">{title}</div>
          <p className="text-sm text-gray-400">{tagline}</p>
        </div>
        <Link
          to={!categories ? "#" : `/products?category=${categories[0]}`}
          className=" text-xxs md:text-xs font-poppins700 rounded-full p-1 md:p-2 border border-gray-950 hover:text-white hover:bg-black transition-all duration-150 ease-in-out hover:shadow-xl  tracking-normal xl:tracking-wider flex items-center group"
        >
          <span className="text-nowrap"> VIEW ALL </span>
          <img
            src={arrow}
            alt="arrow"
            className="sm:ml-1 group-hover:invert mr-2"
          />
        </Link>
      </div>
      <hr />
      {loading ? (
        <Loader />
      ) : (
        <Slider
          {...settings}
          className="flex items-center justify-between p-1 mx-2"
        >
          {randomProducts?.map((product) => (
            <Product {...product} key={product?._id} />
          ))}
        </Slider>
      )}
    </Container>
  );
};

ProductSlider.propTypes = {
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  categories: PropTypes.array,
};

export default ProductSlider;
