import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import { HashLink } from "react-router-hash-link";
import {
  logo,
  pin,
  phone,
  mail,
  insta,
  fb,
  linkedin,
  linktree,
  blackInsta,
  blackFb,
  blackLinkedin,
  blackLinktree,
} from "../../../utils/images";

import {
  addressLink1,
  phone1,
  mail1,
  instaLink,
  fbLink,
  linkedinLink,
  linkTreeLink,
} from "../../../utils/businessConstants";

import { footerLinks } from "../../../utils/constants";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [instaHover, setInstaHover] = useState(false);
  const [fbHover, setFbHover] = useState(false);
  const [linkedinHover, setLinkedinHover] = useState(false);
  const [linktreeHover, setLinktreeHover] = useState(false);

  return (
    <footer className="mt-8 bg-gray-50 p-2 md:p-6 rounded-xl w-full md:w-[98%] md:mx-auto">
      <div className="mx-auto w-full max-w-7xl ">
        <div className="grid grid-cols-1 justify-between gap-6 md:gap-4 sm:grid-cols-2">
          <div className="flex-col sm:justify-start  ">
            <div className="flex md:justify-start">
              <a href="/" className="flex items-center gap-1 sm:gap-2 mb-4 ">
                <div className="w-4 h-4 sm:w-6 sm:h-6">
                  <img src={logo} alt="Logo" className="object-contain" />
                </div>
                <Typography
                  variant="h5"
                  className="text-sm sm:text-lg md:text-xl xl:mr-12 lg:mr-4"
                >
                  <span className="font-poppins100">BOOKS</span>
                  <span className="font-poppins500">DEHRA</span>
                </Typography>
              </a>
            </div>

            <div className="flex md:justify-start">
              <img src={pin} alt="Pin" className="mr-2 mb-8" />
              <a
                className="mb-3 font-poppins400 hover:text-primary-blue cursor-pointer"
                href={addressLink1}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ramesh Book Depot, Book Tower, Nehru Colony, Opp. Uniyal Bakers,
                <br />
                Near Hotel Him Palace, Dehradun, Uttarakhand, 248009
              </a>
            </div>
            <div className="flex md:justify-start ">
              <img src={phone} alt="Phone" className="mr-2 mb-3" />
              <Typography variant="small" className="mb-3 font-poppins400">
                <a
                  href={phone1}
                  className="text-primary-blue hover:underline hover:text-primary-midBlue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +91 - 730 219 1947
                </a>
              </Typography>
            </div>
            <div className="flex md:justify-start">
              <img src={mail} alt="Mail" className="mr-2 mb-3" />
              <Typography variant="small" className="mb-3 font-poppins400">
                <a
                  href={`mailto:${mail1}`}
                  className="text-primary-blue hover:underline hover:text-primary-midBlue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {mail1}
                </a>
              </Typography>
            </div>
            <div className="flex gap-4 ">
              <Typography
                as="a"
                href={instaLink}
                target="_blank"
                onMouseEnter={() => setInstaHover(true)}
                onMouseLeave={() => setInstaHover(false)}
              >
                <img
                  src={instaHover ? blackInsta : insta}
                  alt="Instagram"
                  className="w-8 h-8"
                />
              </Typography>
              <Typography
                as="a"
                href={fbLink}
                target="_blank"
                onMouseEnter={() => setFbHover(true)}
                onMouseLeave={() => setFbHover(false)}
              >
                <img
                  src={fbHover ? blackFb : fb}
                  alt="Facebook"
                  className="w-8 h-8"
                />
              </Typography>
              <Typography
                as="a"
                href={linkedinLink}
                target="_blank"
                onMouseEnter={() => setLinkedinHover(true)}
                onMouseLeave={() => setLinkedinHover(false)}
              >
                <img
                  src={linkedinHover ? blackLinkedin : linkedin}
                  alt="Linkedin"
                  className="w-8 h-8"
                />
              </Typography>
              <Typography
                as="a"
                href={linkTreeLink}
                target="_blank"
                onMouseEnter={() => setLinktreeHover(true)}
                onMouseLeave={() => setLinktreeHover(false)}
              >
                <img
                  src={linktreeHover ? blackLinktree : linktree}
                  alt="Linktree"
                  className="w-8 h-8"
                />
              </Typography>
            </div>
          </div>

          <div className="grid grid-cols-3 justify-between gap-6">
            {footerLinks.map(({ title, items }) => (
              <div key={title} className="flex flex-col gap-2">
                <Typography
                  variant="small"
                  className="mb-3 font-poppins600  text-primary-blue tracking-wide uppercase"
                >
                  {title}
                </Typography>
                {items.map((item) => (
                  <div key={item.name} className="font-poppins400">
                    <HashLink
                      to={item.redirect}
                      className="py-3 transition-colors hover:text-primary-blue cursor-pointer"
                      smooth
                    >
                      {" "}
                      {item.name}
                    </HashLink>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4 md:mt-8 w-full border-t border-blue-gray-50 py-1 md:py-3">
          <Typography
            variant="h6"
            className="mb-2 text-center font-poppins400 text-gray-500 md:mb-0"
          >
            &copy; &nbsp; Copyright {currentYear}, All Rights Reserved by{" "}
            <a href="/" className="hover:text-primary-blue">
              BooksDehra
            </a>
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
