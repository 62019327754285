import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAIL,
  CLEAR_ERRORS,
  CLEAR_MESSAGE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  ALL_USERS_FAIL,
  ALL_USERS_SUCCESS,
  ALL_USERS_REQUEST,
} from "../constants/userConstants";
import axios from "axios";

import {
  CLEAR_WISHLIST,
  GET_WISHLIST_ITEMS,
} from "../constants/wishlistConstants";
import { EMPTY_CART, GET_CART_ITEMS } from "../constants/cartConstants";
import {
  CLEAR_SHIPPING_INFO,
  GET_SHIPPING_INFO,
  GET_SHIPPING_INFO_REQUEST,
} from "../constants/addressConstants";
import { EMPTY_PURCHASE } from "../constants/purchaseConstants";

export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_USER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/login",
      { email, password },
      config
    );

    console.log(data);

    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: data.user,
    });

    localStorage.setItem("userToken", data.token);

    dispatch({
      type: GET_WISHLIST_ITEMS,
      payload: data.user.wishlist,
    });

    dispatch({
      type: GET_CART_ITEMS,
      payload: data.user.cart,
    });

    dispatch({
      type: GET_SHIPPING_INFO,
      payload: data.user.shippingInfo,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const registerUser = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/v1/register", userData, config);

    dispatch({
      type: REGISTER_USER_SUCCESS,
      payload: data.user,
    });
    localStorage.setItem("userToken", data.token);

    dispatch({
      type: GET_WISHLIST_ITEMS,
      payload: data.user.wishlist,
    });

    dispatch({
      type: GET_CART_ITEMS,
      payload: data.user.cart,
    });

    dispatch({
      type: GET_SHIPPING_INFO,
      payload: data.user.shippingInfo,
    });
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    const token = localStorage.getItem("userToken");

    dispatch({ type: GET_SHIPPING_INFO_REQUEST });

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.get("/api/v1/me", config);

    dispatch({
      type: LOAD_USER_SUCCESS,
      payload: data.user,
    });

    dispatch({
      type: GET_WISHLIST_ITEMS,
      payload: data.user.wishlist,
    });

    dispatch({
      type: GET_CART_ITEMS,
      payload: data.user.cart,
    });

    dispatch({
      type: GET_SHIPPING_INFO,
      payload: data.user.shippingInfo,
    });
  } catch (error) {
    dispatch({
      type: LOAD_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await axios.get("/api/v1/logout");
    dispatch({ type: LOGOUT_USER_SUCCESS });
    localStorage.removeItem("userToken");

    dispatch({ type: CLEAR_WISHLIST });
    dispatch({ type: EMPTY_CART });
    dispatch({ type: CLEAR_SHIPPING_INFO });
    dispatch({ type: EMPTY_PURCHASE });
  } catch (error) {
    dispatch({
      type: LOGOUT_USER_FAIL,
      payload: error.response.data,
    });
  }
};

export const updateProfile = (userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    const { data } = await axios.put("/api/v1/me/update", userData, config);

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    const { data } = await axios.put(
      "/api/v1/password/update",
      passwords,
      config
    );

    dispatch({
      type: UPDATE_PASSWORD_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    const base_url = window.location.origin;

    const { data } = await axios.post("/api/v1/password/forgot", {
      email,
      base_url,
    });

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/v1/password/reset/${token}`,
      passwords,
      config
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllUsers =
  (page, pageSize, filters, sort) => async (dispatch) => {
    try {
      dispatch({ type: ALL_USERS_REQUEST });
      const token = localStorage.getItem("userToken");

      const config = {
        "Content-Type": "application/json",
        headers: {
          Authorization: token,
        },
      };

      const requestBody = {
        sort,
        filters,
      };

      const { data } = await axios.post(
        `/api/v1/admin/users?page=${page}&limit=${pageSize}`,
        requestBody,
        config
      );
      dispatch({
        type: ALL_USERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_USERS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };
    const { data } = await axios.get(`/api/v1/admin/user/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateUser = (id, userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    const { data } = await axios.put(
      `/api/v1/admin/user/${id}`,
      userData,
      config
    );

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.delete(`/api/v1/admin/user/${id}`, config);

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const clearMessage = () => async (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE });
};
