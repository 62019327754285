import { FormLabel, TextField } from "@mui/material";
import { DeleteIcon, CloseIcon } from "../../utils/iconImports";
import { MetaData, BackdropLoader } from "../../utils/imports";
import Exams from "./Exams";
import Tags from "./Tags";
import Categories from "./Categories";
import useNewProduct from "../../hooks/admin/newProduct";

const NewProduct = () => {
  const {
    loading,
    highlights,
    highlightInput,
    specs,
    specsInput,
    selectedExams,
    name,
    description,
    price,
    selectedCategories,
    alias,
    discount,
    stock,
    author,
    publisher,
    imagesPreview,
    edition,
    bestSeller,
    tags,
    itemDetails,
    displayPublisher,
    setSelectedCategories,
    setItemDetails,
    setDisplayPublisher,
    setHighlightInput,
    setSelectedExams,
    setName,
    setDescription,
    setPrice,
    setAlias,
    setDiscount,
    setStock,
    setAuthor,
    setPublisher,
    setEdition,
    setBestSeller,
    setTags,
    handleSpecsChange,
    addSpecs,
    addHighlight,
    deleteHighlight,
    deleteSpec,
    handleProductImageChange,
    newProductSubmitHandler,
    handleDeletePreviewImage,
  } = useNewProduct();

  return (
    <>
      <MetaData title="Admin: New Product | BooksDehra" />

      {loading && <BackdropLoader />}
      <form
        onSubmit={newProductSubmitHandler}
        encType="multipart/form-data"
        className="flex flex-col sm:flex-row bg-white rounded-lg shadow p-4"
        id="mainform"
      >
        <div className="flex flex-col gap-3 m-2 sm:w-1/2">
          <TextField
            label="Print Name"
            variant="outlined"
            size="small"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Item Details"
            variant="outlined"
            size="small"
            required
            value={itemDetails}
            onChange={(e) => setItemDetails(e.target.value)}
          />
          <TextField
            label="Description"
            multiline
            rows={3}
            variant="outlined"
            size="small"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            label="Alias"
            variant="outlined"
            size="small"
            required
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
          />
          <div className="flex justify-between gap-2 ">
            <TextField
              label="Price"
              type="number"
              variant="outlined"
              size="small"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              required
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              style={{ width: "40%" }}
            />
            <TextField
              label="Discount %"
              type="number"
              variant="outlined"
              size="small"
              required
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 100,
                },
              }}
              value={discount}
              onChange={(e) => {
                let value = parseInt(e.target.value);
                if (value > 100) {
                  value = 100;
                }
                setDiscount(value);
              }}
              style={{ width: "30%" }}
            />
            <TextField
              label="Stock"
              type="number"
              variant="outlined"
              size="small"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              required
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              style={{ width: "30%" }}
            />
          </div>
          <Categories
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center border rounded">
              <input
                value={highlightInput}
                onChange={(e) => setHighlightInput(e.target.value)}
                type="text"
                placeholder="Highlight"
                className="px-2 flex-1 outline-none border-none"
              />
              <button
                onClick={() => addHighlight()}
                className="py-2 px-6 bg-primary-blue text-white rounded-r hover:shadow-lg cursor-pointer"
              >
                Add
              </button>
            </div>

            <div className="flex flex-col gap-1.5">
              {highlights.map((h, i) => (
                <div
                  className="flex justify-between rounded items-center py-1 px-2 bg-green-50"
                  key={h}
                >
                  <p className="text-green-800 text-sm font-poppins500">{h}</p>
                  <button
                    onClick={() => deleteHighlight(i)}
                    className="text-red-600 hover:bg-red-100 p-1 rounded-full cursor-pointer"
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ))}
            </div>

            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={bestSeller}
                className="sr-only peer"
                onChange={() => {
                  setBestSeller(!bestSeller);
                }}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ms-3 text-sm font-poppins500">Best Seller?</span>
            </label>

            <Tags tags={tags} setTags={setTags} />
            <Exams
              selectedExams={selectedExams}
              setSelectedExams={setSelectedExams}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2 m-2 sm:w-1/2">
          <div className="text-xl font-poppins500 ml-2">
            Book Specifications
          </div>
          <div className="flex justify-between ml-2 items-center">
            <FormLabel>Author Name</FormLabel>
            <TextField
              label="Author Name"
              type="text"
              variant="outlined"
              size="small"
              required
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </div>
          <div className="flex justify-between ml-2 items-center">
            <FormLabel>Publisher</FormLabel>
            <TextField
              label="Publisher Name"
              type="text"
              variant="outlined"
              size="small"
              required
              value={publisher}
              onChange={(e) => setPublisher(e.target.value)}
            />
          </div>
          <div className="flex justify-between ml-2 items-center">
            <FormLabel>Display Publisher</FormLabel>
            <TextField
              label="Display Publisher"
              type="text"
              variant="outlined"
              size="small"
              required
              value={displayPublisher}
              onChange={(e) => setDisplayPublisher(e.target.value)}
            />
          </div>
          <div className="flex justify-between ml-2 items-center">
            <FormLabel>Edition</FormLabel>
            <TextField
              type="text"
              variant="outlined"
              size="small"
              value={edition}
              onChange={(e) => setEdition(e.target.value)}
            />
          </div>
          <div className="flex items-center gap-2 ml-2">
            <TextField
              value={specsInput.title}
              onChange={handleSpecsChange}
              name="title"
              label="Name"
              placeholder="Specification"
              variant="outlined"
              size="small"
            />
            <TextField
              value={specsInput.description}
              onChange={handleSpecsChange}
              name="description"
              label="Description"
              placeholder="Describe"
              variant="outlined"
              size="small"
            />
            <button
              onClick={() => addSpecs()}
              className="py-2 px-6 bg-primary-blue text-white rounded hover:shadow-lg cursor-pointer"
            >
              Add
            </button>
          </div>

          <div className="flex flex-col gap-2 ml-2 ">
            {specs.map((spec, i) => (
              <div
                className="flex justify-between items-center text-sm rounded bg-blue-50 py-1 px-2"
                key={spec}
              >
                <p className="text-gray-500 font-poppins500">{spec.title}</p>
                <p>{spec.description}</p>
                <button
                  onClick={() => deleteSpec(i)}
                  className="text-red-600 hover:bg-red-200 bg-red-100 p-1 rounded-full cursor-pointer"
                >
                  <DeleteIcon />
                </button>
              </div>
            ))}
          </div>

          <div className="font-poppins500">Product Images</div>
          <div className="flex gap-2 overflow-x-auto h-32 border rounded">
            {imagesPreview.map((image, i) => (
              <div className="relative w-32 h-32" key={i}>
                <img
                  draggable="false"
                  src={image}
                  alt="Product"
                  className="w-full h-full object-contain"
                />
                <button
                  type="button"
                  onClick={() => handleDeletePreviewImage(image)}
                  className="absolute top-1 right-1 bg-red-500 text-white rounded-full flex items-center  text-xs"
                >
                  <CloseIcon sx={{ fontSize: "16px" }} />
                </button>
              </div>
            ))}
          </div>
          <label className="rounded font-poppins500 bg-gray-400 text-center cursor-pointer text-white p-2 shadow hover:shadow-lg my-2 ">
            <input
              type="file"
              name="images"
              accept="image/*"
              multiple
              onChange={handleProductImageChange}
              className="hidden"
            />
            Choose Files
          </label>

          <div className="flex justify-end mr-2 ">
            <input
              form="mainform"
              type="submit"
              className="bg-primary-orange  sm:w-3/4  xl:w-1/3 p-3 text-white font-poppins500 rounded-xl shadow hover:shadow-lg cursor-pointer"
              value="Add Product"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default NewProduct;
