import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  clearErrors,
  deleteReview,
  getProductReviews,
  getAllReviews,
} from "../../actions/productAction";
import { DELETE_REVIEW_RESET } from "../../constants/productConstants";
import Rating from "@mui/material/Rating";
import Actions from "../../components/Admin/Actions";
import "./colstyle.css";

const useReviewsTable = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [alias, setAlias] = useState("");

  const {
    reviews,
    error,
    loading: reviewsLoading,
  } = useSelector((state) => state.reviews);

  const handleClear = () => {
    setAlias("");
  };

  const {
    loading,
    isDeleted,
    error: deleteError,
  } = useSelector((state) => state.review);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (deleteError) {
      enqueueSnackbar(`${deleteError}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (isDeleted) {
      enqueueSnackbar("Review Deleted Successfully", { variant: "success" });
      dispatch({ type: DELETE_REVIEW_RESET });
    }
    dispatch(getAllReviews(0, 0));
    // eslint-disable-next-line
  }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

  const deleteReviewHandler = (id, alias) => {
    dispatch(deleteReview(id, alias));
  };

  const getReviewsHandler = (e) => {
    e.preventDefault();
    const trimmedAlias = alias.trim();
    if (trimmedAlias !== "") {
      dispatch(getProductReviews(trimmedAlias));
    } else {
      enqueueSnackbar("Please enter a valid product alias", {
        variant: "error",
      });
    }
  };

  const refreshReviews = () => {
    dispatch(getAllReviews());
    setAlias("");
  };

  const columns = [
    {
      field: "id",
      headerName: "Review ID",
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: "user",
      headerName: "User",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "alias",
      headerName: "Product Alias",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "rating",
      headerName: "Rating",
      type: "number",
      minWidth: 200,
      flex: 0.3,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <Rating
            readOnly
            value={params.row.rating}
            size="small"
            precision={0.5}
          />
        );
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      minWidth: 200,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <span className="scrollable overflow-x-auto">
            {params?.row?.comment}
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.3,
      type: "number",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Actions
            editRoute={"review"}
            deleteHandler={() =>
              deleteReviewHandler(params?.row?.id, params?.row?.alias)
            }
            id={params?.row?.alias}
          />
        );
      },
    },
  ];

  const rows = [];

  reviews?.forEach((rev) => {
    rows.push({
      id: rev._id,
      rating: rev.rating,
      comment: rev.comment,
      user: rev.name,
      alias: rev.productAlias,
    });
  });

  return {
    rows,
    columns,
    loading,
    reviewsLoading,
    alias,
    setAlias,
    getReviewsHandler,
    refreshReviews,
    handleClear,
  };
};

export default useReviewsTable;
