import {
  MetaData,
  PriceSidebar,
  Confirmation,
  Loader,
} from "../../utils/imports";
import CartItem from "./CartItem";
import EmptyCart from "./EmptyCart";
import useCart from "../../hooks/cart/cart";

const Cart = () => {
  const {
    confirmationOpen,
    cartItems,
    setConfirmationOpen,
    placeOrderHandler,
    emptyCartHandler,
    cartLoading,
  } = useCart();

  return (
    <>
      {cartLoading && <Loader />}
      <MetaData title="Shopping Cart | BooksDehra" />
      <main className="mt-20">
        <div className="flex flex-col sm:flex-row gap-3.5  sm:w-11/12  m-auto sm:mb-7">
          <div className="flex-1 mt-4">
            <div className="flex flex-col shadow rounded-2xl">
              <span className="font-poppins500 text-lg px-2 sm:px-8 py-4 border-b flex justify-between">
                <span> My Cart ({cartItems?.length}) </span>
                <span className="text-sm text-gray-500 ">
                  <button
                    className={`${
                      cartItems?.length < 1
                        ? "bg-primary-grey cursor-not-allowed"
                        : "bg-red-500 cursor-pointer text-white"
                    } px-2 md:px-4 py-1 md:py-2 border rounded-xl  flex items-center gap-2`}
                    onClick={() => setConfirmationOpen(true)}
                    disabled={cartItems?.length < 1}
                  >
                    Remove all items
                  </button>
                </span>
              </span>
              <Confirmation
                open={confirmationOpen}
                confirmationText="Are you sure you want to remove all products from your cart?"
                onClose={() => setConfirmationOpen(false)}
                onConfirm={emptyCartHandler}
                task="Empty Cart"
              />
              {cartItems?.length === 0 && <EmptyCart />}
              {cartItems?.map((item, index) => (
                <CartItem
                  {...item.product}
                  inCart={true}
                  key={item?.product?._id}
                  quantity={item?.quantity}
                />
              ))}
              <div className="flex justify-center md:justify-end">
                <button
                  onClick={placeOrderHandler}
                  disabled={cartItems?.length < 1}
                  className={`${
                    cartItems?.length < 1
                      ? "bg-primary-grey cursor-not-allowed"
                      : "bg-primary-orange text-white cursor-pointer"
                  } w-3/5 md:w-1/3 mx-2 sm:mx-6 my-4 py-3 font-poppins500 shadow hover:shadow-lg rounded-xl `}
                >
                  Place Order
                </button>
              </div>
            </div>
          </div>
          <PriceSidebar purchaseItems={cartItems} />
        </div>
      </main>
    </>
  );
};

export default Cart;
