import Slider from "react-slick";
import ProductSlider from "../Home/ProductSlider/ProductSlider";
import Loader from "../Layouts/Loader";
import {
  ShoppingCartIcon,
  FlashOnIcon,
  StarIcon,
  CachedIcon,
  CurrencyRupeeIcon,
  FavoriteIcon,
} from "../../utils/iconImports";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  TextField,
} from "@mui/material";
import { MetaData } from "../../utils/imports";
import useProductDetails from "../../hooks/productDetails/productDetails";

const ProductDetails = () => {
  const {
    product,
    loading,
    settings,
    addToWishlistHandler,
    open,
    handleDialogToggle,
    reviewSubmitHandler,
    rating,
    setRating,
    comment,
    setComment,
    qty,
    increaseQuantity,
    decreaseQuantity,
    buyNow,
    addToCartHandler,
    itemInCart,
    goToCart,
    getDeliveryDate,
    getDiscount,
    viewAll,
    setViewAll,
    heartColor,
  } = useProductDetails();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title={product?.name} />
          <main className="mt-16 md:mt-24">
            <div className="flex flex-col lg:flex-row gap-4 bg-white sm:p-2 relative">
              <div className="lg:w-2/5 top-16 lg:h-screen">
                <div className="flex flex-col gap-3 m-3 ">
                  <div className=" px-7 lg:px-10 py-6 border  rounded-2xl relative">
                    <Slider {...settings}>
                      {product?.images?.map((item, i) => (
                        <img
                          key={item?.public_id}
                          draggable="false"
                          className="w-52 h-52 lg:w-96 lg:h-96 object-contain"
                          src={item?.url}
                          alt={product?.name}
                        />
                      ))}
                    </Slider>
                    <div className="absolute top-2 right-2 lg:top-4 lg:right-4 shadow-lg bg-white w-9 h-9 border flex items-center justify-center rounded-full">
                      <button
                        onClick={addToWishlistHandler}
                        className="cursor-pointer hover:shadow-xl"
                      >
                        <FavoriteIcon
                          sx={{ fontSize: "18px", color: heartColor }}
                        />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-row gap-1 lg:gap-2 items-center text-sm lg:text-base">
                    <button
                      onClick={itemInCart ? goToCart : addToCartHandler}
                      className="p-2 lg:p-4 w-1/2 flex items-center justify-center gap-1 lg:gap-2 text-white bg-primary-orange rounded-xl shadow hover:shadow-lg font-poppins500 "
                    >
                      <ShoppingCartIcon />
                      {itemInCart ? "GO TO CART" : "ADD TO CART"}
                    </button>
                    <button
                      onClick={buyNow}
                      disabled={product?.stock < 1}
                      className={`
                        ${
                          product?.stock < 1
                            ? "opacity-70 cursor-not-allowed "
                            : ""
                        }
                        p-2 lg:p-4 w-1/2 flex items-center justify-center gap-1 lg:gap-2 text-white bg-primary-midBlue rounded-xl shadow hover:shadow-lg font-poppins500
                      `}
                    >
                      <FlashOnIcon />
                      {product?.stock < 1 ? "OUT OF STOCK" : "BUY NOW"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex-1 py-2 px-3">
                <div className="flex flex-col gap-2 mb-4">
                  <div className="text-4xl font-poppins500">
                    {product?.name}
                  </div>
                  <span className="text-sm text-gray-500 font-poppins500 flex gap-2 items-center">
                    <span className="text-xs px-1.5 py-0.5  rounded-sm  flex items-center gap-0.5">
                      <button onClick={handleDialogToggle}>
                        <Rating
                          name="read-only"
                          value={product?.ratings}
                          readOnly
                          size="medium"
                          precision={0.5}
                          sx={{
                            color: "#FFC107",
                          }}
                        />
                      </button>
                    </span>
                    <span>
                      {product?.numOfReviews === 1
                        ? "1 Review"
                        : `${product?.numOfReviews} Reviews`}{" "}
                    </span>
                  </span>
                  <span className="text-primary-green text-sm font-poppins500">
                    Special Price
                  </span>
                  <div className="flex items-baseline gap-2 text-2xl font-poppins500">
                    <span className="text-gray-800">
                      ₹{product?.discountPrice?.toLocaleString()}
                    </span>
                    <span className="text-base text-gray-500 line-through">
                      ₹{product?.price?.toLocaleString()}
                    </span>
                    <span className="text-base text-primary-green">
                      {getDiscount(product?.price, product?.discountPrice)}
                      %&nbsp;off
                    </span>
                  </div>
                  {product?.stock <= 10 && product?.stock > 0 && (
                    <span className="text-red-500 text-sm font-poppins500">
                      Hurry, Only {product?.stock} left!
                    </span>
                  )}

                  <div className="flex justify-between pr-4 sm:pr-0 sm:justify-start sm:gap-6">
                    <p className="text-lg font-poppins500 mt-1">Quantity</p>
                    <div className="flex gap-1 items-center">
                      <button
                        onClick={decreaseQuantity}
                        className="w-7 h-7 text-xl font-poppins300 bg-red-200 rounded-full border flex items-center justify-center cursor-pointer hover:font-poppins500 hover:bg-blue-300"
                      >
                        <p>-</p>
                      </button>
                      <input
                        className="w-11 border outline-none text-center rounded-sm py-0.5 text-gray-700 font-poppins600 text-sm qtyInput"
                        value={qty}
                        disabled
                      />
                      <button
                        onClick={increaseQuantity}
                        className="w-7 h-7 text-xl font-poppins300 bg-blue-200 rounded-full border flex items-center justify-center cursor-pointer hover:font-poppins500 hover:bg-blue-300"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex gap-14 mt-4 items-center text-sm font-poppins500">
                    <p className="text-gray-500">Delivery</p>
                    <span>Delivery by {getDeliveryDate()}</span>
                  </div>
                  <div className="flex gap-9 mt-4 items-stretch text-sm">
                    <p className="text-gray-500 font-poppins500">Categories</p>

                    <div className="list-disc flex flex-col gap-2 w-64">
                      {product?.categories?.map((category, i) => (
                        <div key={category}>
                          <p>{category}</p>
                        </div>
                      ))}
                      {product?.categories?.length === 0 && <p> N/A</p>}
                    </div>
                  </div>
                  <div className="flex gap-16 mt-4 items-stretch text-sm">
                    <p className="text-gray-500 font-poppins500">
                      Exams&nbsp;&nbsp;
                    </p>

                    <div className="list-disc flex flex-col gap-2 w-64">
                      {product?.exams?.map((exam, i) => (
                        <div key={exam}>
                          <p>{exam}</p>
                        </div>
                      ))}
                      {product?.exams?.length === 0 && <p> N/A</p>}
                    </div>
                  </div>
                  <div className="flex gap-12 mt-4 items-stretch text-sm">
                    <p className="text-gray-500 font-poppins500">Highlights</p>
                    <div className="list-disc flex flex-col gap-2 w-64">
                      {product?.highlights?.map((highlight, i) => (
                        <div key={highlight}>
                          <p>{highlight}</p>
                        </div>
                      ))}
                      {product?.highlights?.length === 0 && (
                        <p>No highlights available for this product</p>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-16 mt-4 mr-6 items-stretch text-sm">
                    <p className="text-gray-500 font-poppins500">Services</p>
                    <div className="flex flex-col gap-2">
                      <div>
                        <p className="flex items-center gap-3">
                          <span className="text-primary-blue">
                            <CachedIcon sx={{ fontSize: "18px" }} />
                          </span>{" "}
                          7 Days Replacement Policy
                        </p>
                      </div>
                      <div>
                        <p className="flex items-center gap-3">
                          <span className="text-primary-blue">
                            <CurrencyRupeeIcon sx={{ fontSize: "18px" }} />
                          </span>{" "}
                          Cash on Delivery available
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="w-full mt-6  border rounded-2xl flex flex-col">
                    <div className="px-6 py-4 border-b text-xl lg:text-3xl  font-poppins500">
                      Product Description
                    </div>
                    <div className="p-6">
                      <p className="text-sm">{product?.description}</p>
                    </div>
                  </div>
                  <div className="w-full mt-4 pb-4 rounded-2xl border  flex flex-col">
                    <div className="px-6 py-4 border-b text-xl lg:text-3xl  font-poppins500">
                      Book Specifications
                    </div>
                    <div className="px-6 py-3 text-lg">General</div>
                    <div className="px-6 py-2 flex items-center text-sm">
                      <p className="text-gray-500 w-3/12">Publisher</p>
                      <p className="flex-1">{product?.displayPublisher}</p>
                    </div>
                    <div className="px-6 py-2 flex items-center text-sm">
                      <p className="text-gray-500 w-3/12">Edition</p>
                      {product?.edition ? (
                        <p className="flex-1">{product?.edition}</p>
                      ) : (
                        <p className="flex-1">N/A</p>
                      )}
                    </div>
                    <div className="px-6 py-2 flex items-center text-sm">
                      <p className="text-gray-500 w-3/12">Author</p>
                      <p className="flex-1">{product?.author}</p>
                    </div>
                    <div className="px-6 py-2 flex items-center text-sm">
                      <p className="text-gray-500 w-3/12">Alias</p>
                      <p className="flex-1">{product?.alias}</p>
                    </div>

                    {product?.specifications?.map((spec, i) => (
                      <div
                        className="px-6 py-2 flex items-center text-sm"
                        key={spec}
                      >
                        <p className="text-gray-500 w-3/12">{spec?.title}</p>
                        <p className="flex-1">{spec?.description}</p>
                      </div>
                    ))}
                  </div>
                  <div className="w-full mt-4 border rounded-2xl flex flex-col">
                    <div className="flex justify-between items-center border-b px-6 py-4 font-poppins500">
                      <div className="text-xl lg:text-3xl ">
                        Ratings & Reviews
                      </div>
                      <button
                        onClick={handleDialogToggle}
                        className=" bg-primary-gold px-2 py-1 lg:px-4 lg:py-2 rounded-xl hover:shadow-lg"
                      >
                        Rate Product
                      </button>
                    </div>

                    <Dialog
                      aria-labelledby="review-dialog"
                      open={open}
                      onClose={handleDialogToggle}
                      fullWidth
                      maxWidth="sm"
                    >
                      <DialogTitle className="border-b">
                        Submit Review
                      </DialogTitle>
                      <DialogContent className="flex flex-col m-1 gap-4">
                        <Rating
                          onChange={(e) => setRating(e.target.value)}
                          value={rating}
                          size="large"
                          precision={0.5}
                        />
                        <TextField
                          label="Review"
                          multiline
                          rows={3}
                          fullWidth
                          size="small"
                          variant="outlined"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <button
                          onClick={handleDialogToggle}
                          className="py-2 px-6 rounded-xl shadow bg-white border border-red-500 hover:bg-red-100 text-red-600 uppercase"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={reviewSubmitHandler}
                          className="py-2 px-6 rounded-xl bg-green-600 hover:bg-green-700 text-white shadow uppercase"
                        >
                          Submit
                        </button>
                      </DialogActions>
                    </Dialog>

                    <div className="flex items-center border-b">
                      <div className="px-6 py-3 text-3xl font-poppins600">
                        {product?.ratings?.toFixed(1)}
                        <StarIcon
                          sx={{
                            color: "#FFC107",
                          }}
                        />
                      </div>
                      <p className="text-lg text-gray-500">
                        {product?.numOfReviews === 1 ? (
                          <>({product?.numOfReviews}) Review</>
                        ) : (
                          <>({product?.numOfReviews}) Reviews</>
                        )}
                      </p>
                    </div>

                    {viewAll
                      ? product?.reviews
                          ?.map((rev) => (
                            <div
                              className="flex flex-col gap-2 py-4 px-6 border-b"
                              key={rev._id}
                            >
                              <Rating
                                name="read-only"
                                value={rev.rating}
                                readOnly
                                size="small"
                                precision={0.5}
                              />
                              <p>{rev.comment}</p>
                              <span className="text-sm text-gray-500">
                                by {rev.name}
                              </span>
                            </div>
                          ))
                          .reverse()
                      : product?.reviews
                          ?.slice(-3)
                          .map((rev, i) => (
                            <div
                              className={`flex flex-col gap-2 py-4 px-6  ${
                                product?.numOfReviews > 1 ? "border-t" : ""
                              }`}
                              key={rev._id}
                            >
                              <Rating
                                name="read-only"
                                value={rev.rating}
                                readOnly
                                size="small"
                                precision={0.5}
                              />
                              <p>{rev.comment}</p>
                              <span className="text-sm text-gray-500">
                                by {rev.name}
                              </span>
                            </div>
                          ))
                          .reverse()}
                    {product?.reviews?.length > 3 && (
                      <button
                        onClick={() => setViewAll(!viewAll)}
                        className="w-1/3 m-2 rounded-sm shadow hover:shadow-lg py-2 bg-primary-blue text-white"
                      >
                        {viewAll ? "View Less" : "View All"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {!loading &&
              product?.categories?.length > 0 &&
              !product?.categories?.includes("N/A") && (
                <ProductSlider
                  title={"Similar Products"}
                  tagline={"Based on the category"}
                  categories={product?.categories}
                />
              )}
          </main>
        </>
      )}
    </>
  );
};

export default ProductDetails;
