import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getOrderDetails,
  cancelOrder,
} from "../../actions/orderAction";

const useOrderDetails = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();

  const { order, error, loading } = useSelector((state) => state.orderDetails);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const cancelOrderHandler = () => {
    setConfirmationOpen(false);
    dispatch(cancelOrder(order._id));
    navigate("/orders");
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    dispatch(getOrderDetails(params.id));
  }, [dispatch, error, params.id, enqueueSnackbar]);

  return {
    order,
    loading,
    confirmationOpen,
    setConfirmationOpen,
    cancelOrderHandler,
  };
};

export default useOrderDetails;
