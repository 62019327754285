import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { formatDate } from "../../utils/functions";
import TrackStepper from "../Order/TrackStepper";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import { ArrowBackIosIcon } from "../../utils/iconImports";
import { MetaData } from "../../utils/imports";
import useUpdateOrder from "../../hooks/admin/updateOrder";

const UpdateOrder = () => {
  const { status, setStatus, order, loading, updateOrderSubmitHandler } =
    useUpdateOrder();

  return (
    <>
      <MetaData title="Admin: Update Order | BooksDehra" />

      {loading ? (
        <Loading />
      ) : (
        <>
          {order?.user && order?.shippingInfo && (
            <div className="flex flex-col gap-4 font-poppins400">
              <Link
                to="/admin/orders"
                className="ml-1 flex items-center gap-0 font-poppins500 text-primary-blue uppercase"
              >
                <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
                Go Back
              </Link>

              <div className="flex flex-col sm:flex-row bg-white shadow-lg rounded-xl min-w-full">
                <div className="sm:w-1/2 border-r">
                  <div className="flex flex-col gap-3 my-8 mx-10">
                    <div className="font-poppins500 text-lg sm:text-xl">
                      Delivery Address
                    </div>
                    <div className="font-poppins500">{order.user?.name}</div>
                    <p className="text-sm">{`${order.shippingInfo?.address}, ${order.shippingInfo?.city}, ${order.shippingInfo.state} - ${order.shippingInfo?.pincode}`}</p>
                    <div className="flex gap-2 text-sm sm:text-base">
                      <p className="font-poppins500">Email:</p>
                      <p>{order.user?.email}</p>
                    </div>
                    <div className="flex gap-2 text-sm sm:text-base">
                      <p className="font-poppins500">Phone Number:</p>
                      <p>{order.shippingInfo?.phoneNo}</p>
                    </div>
                  </div>
                </div>

                <form
                  onSubmit={updateOrderSubmitHandler}
                  className={`flex flex-col gap-3 p-8 `}
                >
                  <div className="font-poppins500 text-lg">Update Status</div>
                  <div className="flex gap-2">
                    <p className="text-sm font-poppins500">Current Status:</p>
                    <p className="text-sm">
                      {order?.orderStatus === "Shipped" &&
                        `Shipped on ${formatDate(order?.shippedAt)}`}
                      {order?.orderStatus === "Processing" &&
                        `Ordered on ${formatDate(order?.createdAt)}`}
                      {order?.orderStatus === "Delivered" &&
                        `Delivered on ${formatDate(order?.deliveredAt)}`}
                      {order?.orderStatus === "Cancelled" && `Cancelled`}
                    </p>
                  </div>
                  {order?.orderStatus !== "Cancelled" && (
                    <FormControl fullWidth sx={{ marginTop: 1 }}>
                      <InputLabel id="order-status-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="order-status-select-label"
                        id="order-status-select"
                        value={status}
                        label="Status"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value={"Processing"}>Processing</MenuItem>
                        <MenuItem value={"Shipped"}>Shipped</MenuItem>
                        <MenuItem value={"Delivered"}>Delivered</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <button
                    type="submit"
                    disabled={order.orderStatus === "Cancelled"}
                    className={`bg-primary-orange p-2.5 text-white font-poppins500 rounded-xl shadow hover:shadow-lg ${
                      order.orderStatus === "Cancelled"
                        ? "cursor-not-allowed"
                        : "hover:bg-primary-orange/70"
                    } `}
                  >
                    Update
                  </button>
                </form>
              </div>

              {order?.orderItems?.map((item) => {
                const { _id, product, quantity } = item;

                return (
                  <div
                    className="flex flex-col sm:flex-row min-w-full shadow-lg rounded-xl bg-white px-2 py-5"
                    key={_id}
                  >
                    <div className="flex flex-col sm:flex-row sm:w-1/2 gap-1">
                      <div className="w-full sm:w-32 h-24">
                        <img
                          draggable="false"
                          className="h-full w-full object-contain"
                          src={product?.images[0].url}
                          alt={product?.name}
                        />
                      </div>
                      <div className="flex flex-col gap-1 overflow-hidden">
                        <p className="text-sm">
                          {product?.name.length > 45
                            ? `${product?.name.substring(0, 45)}...`
                            : product?.name}
                        </p>
                        <p className="text-xs text-gray-600">
                          {product?.alias}
                        </p>
                        <p className="text-xs text-gray-600">
                          {product?.author}
                        </p>
                        <p className="text-xs text-gray-600 mt-2">
                          Quantity: {quantity}
                        </p>
                        <p className="text-xs text-gray-600">
                          Price: ₹{product?.discountPrice?.toLocaleString()}
                        </p>
                        <span className="font-poppins500">
                          Total: ₹
                          {(
                            quantity * product?.discountPrice
                          )?.toLocaleString()}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col w-full sm:w-1/2">
                      <div className="font-poppins500 sm:text-center">
                        Order Status
                      </div>
                      <TrackStepper
                        orderOn={order?.createdAt}
                        shippedAt={order?.shippedAt}
                        deliveredAt={order?.deliveredAt}
                        cancelledAt={order?.cancelledAt}
                        activeStep={
                          {
                            Delivered: 2,
                            Shipped: 1,
                            Processing: 0,
                            Cancelled: 3,
                          }[order?.orderStatus]
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UpdateOrder;
