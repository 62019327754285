import {
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from "@mui/material";
import { MetaData } from "../../utils/imports";
import states from "../../utils/states";
import useAddShippingInfo from "../../hooks/address/addShippingInfo";

const AddShippingInfo = () => {
  const {
    address,
    setAddress,
    city,
    setCity,
    country,
    setCountry,
    state,
    setState,
    pincode,
    setPincode,
    phoneNo,
    setPhoneNo,
    saveAddress,
  } = useAddShippingInfo();

  return (
    <>
      <MetaData title="BooksDehra: Shipping Details" />
      <div className="flex flex-col justify-center items-center mt-16 md:mt-24 mx-1 md:mx-0 ">
        <main className="w-full md:w-3/4">
          <div className="flex flex-col sm:flex-row gap-1.5 md:gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7 overflow-hidden border rounded-2xl bg-primary-lightGrey">
            <div className="flex-1">
              <div className="flex justify-between items-center px-4 sm:px-8 py-4 border-b">
                <span className="font-poppins500 text-2xl">
                  Add New Address
                </span>
              </div>

              <form
                onSubmit={saveAddress}
                autoComplete="off"
                className="flex flex-col justify-center gap-3 w-4/5 sm:w-3/4 mx-3 sm:mx-8 my-4 "
              >
                <TextField
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  fullWidth
                  label="Address"
                  variant="outlined"
                  required
                />

                <div className="flex gap-2 md:gap-6">
                  <TextField
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    type="tel"
                    label="Pincode"
                    fullWidth
                    variant="outlined"
                    required
                  />
                  <TextField
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    type="tel"
                    label="Phone No"
                    fullWidth
                    variant="outlined"
                    required
                  />
                </div>

                <div className="flex gap-2 md:gap-6">
                  <TextField
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    label="City"
                    fullWidth
                    variant="outlined"
                    required
                  />
                  <TextField
                    label="Landmark (Optional)"
                    fullWidth
                    variant="outlined"
                  />
                </div>

                <div className="flex gap-2 md:gap-6">
                  <FormControl fullWidth disabled={!country}>
                    <InputLabel id="state-select">State</InputLabel>
                    <Select
                      labelId="state-select"
                      id="state-select"
                      value={state}
                      label="State"
                      onChange={(e) => setState(e.target.value)}
                      required
                    >
                      {states.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="country-select">Country</InputLabel>
                    <Select
                      labelId="country-select"
                      id="country-select"
                      defaultValue={country}
                      disabled
                      label="Country"
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <MenuItem value={"IN"}>India</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <button
                  type="submit"
                  className="bg-primary-darkBlue w-3/4 sm:w-1/3 my-2 py-3.5 text-sm font-poppins600 text-white shadow hover:shadow-lg rounded-xl  outline-none"
                >
                  Save Address
                </button>
              </form>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default AddShippingInfo;
