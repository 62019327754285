import React, { useState, useEffect } from "react";
import { TextField, Chip, Autocomplete } from "@mui/material";
import { fetchTags, saveTag } from "../../actions/productAction";
import { CloseIcon } from "../../utils/iconImports";
import PropTypes from "prop-types";

function Tags({ tags, setTags }) {
  const [inputValue, setInputValue] = useState("");
  const [suggestedTags, setSuggestedTags] = useState([]);

  useEffect(() => {
    const fetchSuggestedTags = async () => {
      const response = await fetchTags();
      const tags = response.tags.map((tag) => tag.name);
      setSuggestedTags(tags);
    };
    fetchSuggestedTags();
  }, []);

  const handleAddTag = (e, newValue) => {
    e.preventDefault();
    if (newValue && !tags.includes(newValue)) {
      setTags([...tags, newValue]);
      saveTag(newValue);
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags?.filter((tag) => tag !== tagToRemove));
  };

  return (
    <div className="flex-col justify-evenly gap-2">
      <div className="font-poppins500 mb-2">Tags</div>
      <Autocomplete
        freeSolo
        options={suggestedTags}
        value={inputValue}
        onChange={(event, newValue) => setInputValue(newValue)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleAddTag(e, inputValue);
          }
        }}
        renderInput={(params) => (
          <div style={{ position: "relative" }}>
            <TextField
              {...params}
              label="Tags"
              type="text"
              variant="outlined"
              size="small"
            />
            {inputValue && (
              <CloseIcon
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setInputValue("")}
              />
            )}
          </div>
        )}
      />
      <div className="mt-2">
        <div className="font-poppins500 mb-2">
          Selected Tags for this product:
        </div>
        {tags?.length === 0 && (
          <p className="text-red-500">No tags selected for this product</p>
        )}
        {tags?.map((tag, index) => (
          <Chip
            key={tag}
            label={tag}
            className="hover:bg-red-500 font- transition-all duration-300 ease-in-out"
            onDelete={() => handleRemoveTag(tag)}
            color="primary"
            variant="outlined"
            deleteIcon={<CloseIcon />}
            style={{
              marginRight: "5px",
              marginBottom: "5px",
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
}

Tags.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func,
};

export default Tags;
