import Loading from "./Loading";
import { AccountCircleIcon } from "../../utils/iconImports";
import { MenuItem, TextField } from "@mui/material";
import { MetaData, BackdropLoader } from "../../utils/imports";
import useUpdateUser from "../../hooks/admin/updateUser";
import { Link } from "react-router-dom";

const UpdateUser = () => {
  const {
    name,
    email,
    phone,
    role,
    loading,
    updateLoading,
    setRole,
    updateUserSubmitHandler,
  } = useUpdateUser();

  return (
    <>
      <MetaData title="Admin: Update User | BooksDehra" />
      {updateLoading && <BackdropLoader />}
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col bg-white shadow-lg rounded-2xl mx-auto w-3/5 lg:w-2/5">
          <div className="text-center text-2xl font-poppins500 mt-6 text-gray-800">
            Update Role
          </div>
          <form onSubmit={updateUserSubmitHandler} className="p-5 sm:p-10">
            <div className="flex flex-col gap-3 items-start">
              <div className="flex flex-col  justify-between sm:flex-col gap-3 items-center ">
                <TextField
                  fullWidth
                  label="Full Name"
                  name="name"
                  value={name}
                  disabled
                />
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  name="email"
                  value={email}
                  disabled
                />
                <TextField
                  fullWidth
                  label="Phone"
                  type="text"
                  name="phone"
                  value={phone}
                  disabled
                />
              </div>
              <div className="flex flex-col w-full justify-between sm:flex-row gap-3 items-center">
                <AccountCircleIcon sx={{ fontSize: "56px" }} />
                <TextField
                  label="Role"
                  select
                  fullWidth
                  variant="outlined"
                  required
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem value={"user"}>User</MenuItem>
                  <MenuItem value={"admin"}>Admin</MenuItem>
                </TextField>
              </div>

              <button
                type="submit"
                className="text-white py-3 w-full bg-primary-darkBlue shadow hover:shadow-lg rounded-xl font-poppins500"
              >
                Update
              </button>
              <Link
                className="hover:bg-gray-100 text-primary-blue text-center py-3 w-full shadow border rounded-xl font-poppins500"
                to="/admin/users"
              >
                Cancel
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default UpdateUser;
