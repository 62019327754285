import medical from "../assets/images/Categories/medical.svg";
import agriculture from "../assets/images/Categories/agriculture.svg";
import ayurveda from "../assets/images/Categories/ayurveda.svg";
import dental from "../assets/images/Categories/dental.svg";
import forestry from "../assets/images/Categories/forestry.svg";
import humanities from "../assets/images/Categories/humanities.svg";
import law from "../assets/images/Categories/law.svg";
import nursing from "../assets/images/Categories/nursing.svg";
import pharmacy from "../assets/images/Categories/pharmacy.svg";
import physiotherapy from "../assets/images/Categories/physiotherapy.svg";
import yoga from "../assets/images/Categories/yoga.svg";
import ignou from "../assets/images/Categories/ignou.svg";
import fiction from "../assets/images/Categories/fiction.svg";
import psychology from "../assets/images/Categories/psychology.svg";
import science from "../assets/images/Categories/science.svg";
import bed from "../assets/images/Categories/bed.svg";
import polytechnic from "../assets/images/Categories/polytechnic.svg";
import general from "../assets/images/Categories/gc.svg";

//Publisher images
import arihant from "../assets/images/publishers/arihant.svg";
import cbs from "../assets/images/publishers/cbs.svg";
import cengage from "../assets/images/publishers/cengage.svg";
import disha from "../assets/images/publishers/disha.svg";
import elsevier from "../assets/images/publishers/elsevier.svg";
import jaypee from "../assets/images/publishers/jaypee.svg";
import kalyani from "../assets/images/publishers/kalyani.svg";
import mtg from "../assets/images/publishers/mtg.svg";
import sChand from "../assets/images/publishers/sChand.svg";
import wolter from "../assets/images/publishers/wolter.svg";
import icar from "../assets/images/publishers/icar.svg";

export const categories = [
  {
    name: "Forestry",
    image: forestry,
  },
  {
    name: "Ayurveda",
    image: ayurveda,
  },
  {
    name: "Yoga",
    image: yoga,
  },
  {
    name: "IGNOU",
    image: ignou,
  },
  {
    name: "Fiction/Non-Fiction",
    image: fiction,
  },
  {
    name: "Psychology",
    image: psychology,
  },
  {
    name: "General Competition",
    image: general,
  },
  {
    name: "Law",
    image: law,
  },
  {
    name: "Medical",
    image: medical,
  },
  {
    name: "Dental",
    image: dental,
  },
  {
    name: "Nursing",
    image: nursing,
  },
  {
    name: "Physiotherapy",
    image: physiotherapy,
  },
  {
    name: "Agriculture",
    image: agriculture,
  },
  {
    name: "Humanities",
    image: humanities,
  },
  {
    name: "Science",
    image: science,
  },
  {
    name: "Pharmacy",
    image: pharmacy,
  },
  {
    name: "B Ed",
    image: bed,
  },
  {
    name: "Polytechnic",
    image: polytechnic,
  },
];

export const specifications = ["Product Edition", "Language", "Binding"];

export const exams = [
  "JEE/ NEET",
  "IAS/ PCS",
  "NDA/ CDS",
  "Judicial",
  "Agriculture/ Forestry",
  "GATE/ IES",
  "CUET",
  "TGT/ PGT/ CTET",
  "CAT/ MAT",
  "SSC/ BANKING",
  "Group C",
  "Medical PG",
  "Army/ Navodya/ Olympiad",
];

export const colors = [
  "#F3EEE8",
  "#E8ECEF",
  "#F4E9E5",
  "#E9E8E4",
  "#F2E4E4",
  "#F1F1F1",
  "#EAE8F0",
  "#EEF8FA",
];

export const publishers = [
  {
    name: "CBS Publishers and Distributors",
    image: cbs,
  },
  {
    name: "Elsevier",
    image: elsevier,
  },
  {
    name: "Jaypee Brother Medical Publishers",
    image: jaypee,
  },
  {
    name: "Wolters Kluwer",
    image: wolter,
  },
  {
    name: "Disha Publication Inc.",
    image: disha,
  },
  {
    name: "Arihant Publications",
    image: arihant,
  },
  {
    name: "S. Chand Group",
    image: sChand,
  },
  {
    name: "Cengage",
    image: cengage,
  },
  {
    name: "MTG Learning Media",
    image: mtg,
  },
  {
    name: "Kalyani Publishers",
    image: kalyani,
  },
  {
    name: "ICAR - Indian Council of Agricultural Research",
    image: icar,
  },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const footerLinks = [
  {
    title: "ABOUT",
    items: [
      {
        name: "About Us",
        redirect: "/about-us",
      },
      {
        name: "Contact Us",
        redirect: "/contact-us",
      },
    ],
  },
  {
    title: "LEGAL",
    items: [
      {
        name: "Terms & Conditions",
        redirect: "/terms-of-service",
      },
      {
        name: "Privacy Policy",
        redirect: "/privacy",
      },
      {
        name: "Cancellation & Returns",
        redirect: "/cancellation",
      },
    ],
  },
  {
    title: "RESOURCES",
    items: [
      {
        name: "Bestsellers",
        redirect: "/#bestSeller",
      },
      {
        name: "Books Categories",
        redirect: "/#categories",
      },
      {
        name: "Shop by Exams",
        redirect: "/#exams",
      },
    ],
  },
];
