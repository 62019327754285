import { useState } from "react";
import { useSnackbar } from "notistack";
import { getDeliveryDate, getDiscount } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updatePurchaseItems,
  removeItemFromPurchase,
} from "../../actions/purchasingActions";

const usePurchaseItem = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const purchaseItems = useSelector((state) => state.purchase.purchasing);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleRemove = (id) => {
    dispatch(removeItemFromPurchase(id));
    setConfirmationOpen(false);
    enqueueSnackbar("Item removed ", {
      variant: "warning",
    });
    if (purchaseItems.length === 0) navigate("/");
  };

  const increaseQuantity = (id, quantity, stock) => {
    const newQty = parseInt(quantity) + 1;
    if (newQty > stock) {
      enqueueSnackbar("Product is out of stock", {
        variant: "warning",
      });
      return;
    } else {
      quantity = Math.min(newQty, stock);
      const newItems = purchaseItems.map((item) =>
        item.product._id === id
          ? { ...item, quantity: Math.min(item.quantity + 1, stock) }
          : item
      );
      dispatch(updatePurchaseItems(newItems));
    }
  };

  const decreaseQuantity = (id, quantity) => {
    const newQty = parseInt(quantity) - 1;
    if (newQty < 1) {
      setConfirmationOpen(true);
    } else {
      quantity = Math.max(newQty, 1);
      const newItems = purchaseItems.map((item) =>
        item.product._id === id
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
      dispatch(updatePurchaseItems(newItems));
    }
  };

  return {
    confirmationOpen,
    setConfirmationOpen,
    increaseQuantity,
    decreaseQuantity,
    getDeliveryDate,
    getDiscount,
    handleRemove,
  };
};

export default usePurchaseItem;
