import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  clearErrors,
  deleteProduct,
  getAdminProducts,
  bulkUploadProducts,
} from "../../actions/productAction";
import Rating from "@mui/material/Rating";
import { DELETE_PRODUCT_RESET } from "../../constants/productConstants";
import Actions from "../../components/Admin/Actions";
import { useLocation } from "react-router-dom";
import "./colstyle.css";
import { getDiscount } from "../../utils/functions";

const useProductTable = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const {
    products,
    error,
    productsCount,
    totalPages,
    loading: productsLoading,
  } = useSelector((state) => state.adminProducts);
  const {
    loading,
    isDeleted,
    isUpdated,
    error: deleteError,
  } = useSelector((state) => state.product);

  const { success, error: bulkUploadError } = useSelector(
    (state) => state.newProduct
  );

  const [filterModel, setFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState([]);
  const [rowCountState, setRowCountState] = useState(
    typeof productsCount === "number" ? productsCount : 100
  );
  const [keyword, setKeyword] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: Math.min(100, rowCountState),
  });

  const [file, setFile] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const currentKeyword = query.get("keyword");
    if (currentKeyword !== keyword) {
      setKeyword(currentKeyword || "");
    }
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    if (productsCount) {
      setRowCountState(productsCount);
    }
  }, [productsCount]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
  }, [error, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (isDeleted) {
      dispatch({ type: DELETE_PRODUCT_RESET });
    }

    if (location.pathname === "/admin/products") {
      dispatch(
        getAdminProducts(
          paginationModel.page,
          paginationModel.pageSize,
          filterModel,
          sortModel,
          ""
        )
      );
    }
  }, [
    dispatch,
    paginationModel,
    filterModel,
    sortModel,
    isDeleted,
    location.pathname,
  ]);

  const deleteProductHandler = (alias) => {
    dispatch(deleteProduct(alias));
    if (isDeleted) {
      enqueueSnackbar("Product Deleted Successfully", {
        variant: "success",
      });
    } else if (deleteError) {
      enqueueSnackbar("Product Deletion Failed", {
        variant: "error",
      });
    }
  };

  const searchProduct = (e) => {
    e.preventDefault();
    const str = keyword.trim();
    if (str === "") {
      return;
    }
    const query = new URLSearchParams(location.search);
    query.set("keyword", str);
    dispatch(
      getAdminProducts(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel,
        sortModel,
        str
      )
    );
  };

  const refreshProducts = () => {
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });

    setFilterModel({ items: [] });
    setSortModel([]);
    setKeyword("");
  };

  useEffect(() => {
    refreshProducts();
    // eslint-disable-next-line
  }, [isUpdated]);

  const handleClear = () => {
    setKeyword("");
    dispatch(
      getAdminProducts(
        paginationModel.page,
        paginationModel.pageSize,
        filterModel,
        sortModel,
        ""
      )
    );
  };

  const bulkUploadHandler = async () => {
    if (file?.type === "application/json") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        try {
          const jsonData = JSON.parse(text);
          if (!jsonData?.length) {
            enqueueSnackbar("Empty JSON file", { variant: "error" });
            return;
          }

          if (!Array.isArray(jsonData)) {
            enqueueSnackbar("JSON Data is in invalid format ", {
              variant: "error",
            });
            return;
          }

          const firstItem = jsonData[0];
          if (firstItem && typeof firstItem === "object") {
            const keys = Object.keys(firstItem);
            if (
              keys.includes("name") &&
              keys.includes("price") &&
              keys.includes("stock") &&
              keys.includes("categories") &&
              keys.includes("exams") &&
              keys.includes("author") &&
              keys.includes("displayPublisher") &&
              keys.includes("alias") &&
              keys.includes("description") &&
              keys.includes("publisher") &&
              keys.includes("stock") &&
              keys.includes("discountPrice") &&
              keys.includes("edition") &&
              keys.includes("bestSeller")
            ) {
              dispatch(bulkUploadProducts(jsonData));
              if (success) {
                enqueueSnackbar("Products Uploaded Successfully", {
                  variant: "success",
                });
              } else if (bulkUploadError) {
                enqueueSnackbar(bulkUploadError, { variant: "error" });
              }
            } else {
              enqueueSnackbar("JSON Data is in invalid format ", {
                variant: "error",
              });
            }
          }
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Error parsing JSON file", { variant: "error" });
        } finally {
          setFile(null);
        }
      };
      reader.readAsText(file);
    } else {
      enqueueSnackbar("Please upload a valid JSON file", { variant: "error" });
      setFile(null);
    }
  };

  const columns = [
    {
      field: "alias",
      headerName: "Product Alias",
      minWidth: 150,
      flex: 0.9,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex items-center scrollable overflow-x-auto ">
            {params?.row?.name}
          </div>
        );
      },
    },
    {
      field: "categories",
      headerName: "Categories",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => {
        return (
          <span className="scrollable overflow-x-auto ">
            {params?.row?.categories}
          </span>
        );
      },
    },
    {
      field: "exams",
      headerName: "Exams",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => {
        return (
          <span className="scrollable overflow-x-auto">
            {params?.row?.exams}
          </span>
        );
      },
    },
    {
      field: "author",
      headerName: "Author",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => {
        return (
          <span className="scrollable overflow-x-auto">
            {params?.row?.author}
          </span>
        );
      },
    },
    {
      field: "displayPublisher",
      headerName: "Publisher",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => {
        return (
          <span className="scrollable overflow-x-auto">
            {params?.row?.displayPublisher}
          </span>
        );
      },
    },
    {
      field: "stock",
      headerName: "Stock",
      type: "number",
      headerAlign: "left",
      align: "left",
      minWidth: 70,
      flex: 0.1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.stock < 10 ? (
              <span className=" text-xs font-poppins500 text-red-700 rounded-full bg-red-200 p-1 w-5 h-5 flex items-center justify-center ">
                {params?.row?.stock}
              </span>
            ) : (
              <span className="text-xs font-poppins500 text-green-700 rounded-full bg-green-200 p-1 w-5 h-5 flex items-center justify-center">
                {params?.row?.stock}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      flex: 0.2,
      renderCell: (params) => {
        return (
          <span className="">₹{params?.row?.price?.toLocaleString()}</span>
        );
      },
    },
    {
      field: "discount",
      headerName: "Discount %",
      type: "number",
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      flex: 0.1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <span className="">
            {getDiscount(params?.row?.price, params?.row?.discountPrice)}%
          </span>
        );
      },
    },
    {
      field: "discountPrice",
      headerName: "Discount Price",
      type: "number",
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 0.2,
      renderCell: (params) => {
        return (
          <span className="">
            ₹{params?.row?.discountPrice.toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "ratings",
      headerName: "Ratings",
      type: "number",
      minWidth: 100,
      flex: 0.1,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <Rating
            readOnly
            value={params?.row?.ratings}
            size="small"
            precision={0.5}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 180,
      flex: 0.3,
      type: "number",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Actions
            editRoute={"product"}
            deleteHandler={() => deleteProductHandler(params.row.alias)}
            name={params?.row?.name}
            id={params?.row?.id}
            product={params.row}
            productTable={true}
          />
        );
      },
    },
  ];

  const rows = [];

  products?.forEach((item) => {
    rows.push({
      id: item._id,
      name: item.name,
      images: item.images,
      categories: item.categories.join(", "),
      exams: item.exams.join(", "),
      numOfReviews: item.numOfReviews,
      itemDetails: item.itemDetails,
      displayPublisher: item.displayPublisher,
      stock: item.stock,
      price: item.price,
      discountPrice: item.discountPrice,
      ratings: item.ratings,
      description: item.description,
      author: item.author,
      publisher: item.publisher,
      alias: item.alias,
      bestSeller: item.bestSeller,
      tags: item.tags,
      specifications: item.specifications,
      edition: item.edition,
    });
  });

  return {
    columns,
    rows,
    loading,
    productsLoading,
    paginationModel,
    filterModel,
    rowCountState,
    totalPages,
    sortModel,
    keyword,
    file,
    setPaginationModel,
    setFilterModel,
    setSortModel,
    searchProduct,
    setKeyword,
    refreshProducts,
    handleClear,
    bulkUploadHandler,
    setFile,
  };
};

export default useProductTable;
