import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { MetaData, BackdropLoader, Loader } from "../../utils/imports";
import useProductTable from "../../hooks/admin/productTable";
import { SearchIcon, CloseIcon, RefreshIcon } from "../../utils/iconImports";

const ProductTable = () => {
  const {
    rows,
    columns,
    loading,
    productsLoading,
    paginationModel,
    keyword,
    filterModel,
    rowCountState,
    sortModel,
    file,
    setPaginationModel,
    setFilterModel,
    setSortModel,
    searchProduct,
    setKeyword,
    refreshProducts,
    handleClear,
    bulkUploadHandler,
    setFile,
  } = useProductTable();
  return (
    <>
      <MetaData title="Admin Products | BooksDehra" />

      {loading && <BackdropLoader />}

      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-2 ">
        <div className="text-base md:text-lg font-poppins500 uppercase">
          products
        </div>
        <div className="flex flex-col md:flex-row md:justify-end md:w-3/4 gap-2">
          <form
            onSubmit={searchProduct}
            className="px-1 sm:px-4 py-2.5 flex justify-between gap-2 items-center bg-primary-lightGrey rounded-lg overflow-hidden lg:w-1/2"
          >
            <button type="submit" className="-ml-1" aria-label="Search">
              <SearchIcon />
            </button>
            <input
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              className="ml-2 text-sm flex-1 outline-none border-none placeholder-gray-400 font-poppins400 bg-primary-lightGrey focus:ring-0 focus:border-0 focus:outline-none overflow-auto text-ellipsis"
              type="text"
              placeholder="Search for something"
            />
            {keyword && (
              <button
                onClick={handleClear}
                className="text-gray-500 hover:text-gray-700"
              >
                <CloseIcon />
              </button>
            )}
          </form>
          <div className="flex justify-between items-center gap-2 font-poppins500 ">
            <button
              onClick={refreshProducts}
              className="bg-primary-blue flex items-center gap-1 text-white rounded-xl p-2 shadow hover:shadow-lg"
            >
              Refresh
              <RefreshIcon />
            </button>
            <form onSubmit={bulkUploadHandler}>
              {file ? (
                <button
                  type="submit"
                  className="bg-primary-blue text-white rounded-xl p-2 shadow hover:shadow-lg cursor-pointer"
                >
                  Insert in DB
                </button>
              ) : (
                <>
                  <input
                    type="file"
                    className="hidden"
                    accept=".json"
                    onChange={(e) => setFile(e.target.files[0])}
                    id="json-file"
                  />
                  <label
                    htmlFor="json-file"
                    className="bg-primary-blue text-white rounded-xl p-2 shadow hover:shadow-lg cursor-pointer"
                  >
                    Upload File
                  </label>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <div
        className="rounded-xl shadow-lg w-full text-xs"
        style={{ height: 470 }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          rowCount={rowCountState ?? 0}
          pageSize={100}
          disableSelectIconOnClick
          paginationMode="server"
          filterMode="server"
          filterModel={filterModel}
          filterDebounceMs={500}
          onFilterModelChange={setFilterModel}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          loading={productsLoading}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: Loader,
          }}
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
            panel: {
              sx: {
                top: "-160px !important",
              },
            },
            filterPanel: {
              sx: {
                maxWidth: "98vw ",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default ProductTable;
