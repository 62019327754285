import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  clearErrors,
  getUserDetails,
  updateUser,
} from "../../actions/userAction";
import {
  UPDATE_USER_RESET,
  REMOVE_USER_DETAILS,
} from "../../constants/userConstants";

const useUpdateUser = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();

  const { user, error, loading } = useSelector((state) => state.userDetails);
  const {
    isUpdated,
    error: updateError,
    loading: updateLoading,
  } = useSelector((state) => state.profile);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");

  const userId = params.id;

  const updateUserSubmitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("name", name);
    formData.set("email", email);
    formData.set("phone", phone);
    formData.set("role", role);

    dispatch(updateUser(userId, formData));
  };

  useEffect(() => {
    if (user?._id !== userId) {
      dispatch(getUserDetails(userId));
    } else {
      setName(user?.name);
      setEmail(user?.email);
      setPhone(user?.phone);
      setRole(user?.role);
    }
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (updateError) {
      enqueueSnackbar(` ${updateError}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (isUpdated) {
      enqueueSnackbar("User Updated Successfully", {
        variant: "success",
      });
      dispatch({ type: UPDATE_USER_RESET });
      dispatch({ type: REMOVE_USER_DETAILS });
      navigate("/admin/users");
    }
  }, [
    dispatch,
    error,
    userId,
    user,
    navigate,
    isUpdated,
    updateError,
    enqueueSnackbar,
  ]);

  return {
    updateUserSubmitHandler,
    name,
    email,
    phone,
    role,
    loading,
    updateLoading,
    setRole,
  };
};

export default useUpdateUser;
