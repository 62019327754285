import axios from "axios";
import {
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST,
  ADD_TO_WISHLIST_FAIL,
  REMOVE_FROM_WISHLIST_REQUEST,
  REMOVE_FROM_WISHLIST,
  REMOVE_FROM_WISHLIST_FAIL,
  CLEAR_WISHLIST_REQUEST,
  CLEAR_WISHLIST,
  CLEAR_WISHLIST_FAIL,
  CLEAR_ERRORS,
} from "../constants/wishlistConstants";

export const addToWishlist = (id) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TO_WISHLIST_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.get(`/api/v1/addtowishlist/${id}`, config);

    const data = response.data;

    dispatch({
      type: ADD_TO_WISHLIST,
      payload: {
        _id: data.product._id,
        name: data.product.name,
        price: data.product.price,
        discountPrice: data.product.discountPrice,
        description: data.product.description,
        alias: data.product.alias,
        publisher: data.product.publisher,
        author: data.product.author,
        edition: data.product.edition,
        images: data.product.images,
        ratings: data.product.ratings,
        numOfReviews: data.product.numOfReviews,
        exams: data.product.exams,
        tags: data.product.tags,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADD_TO_WISHLIST_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const removeFromWishlist = (id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_FROM_WISHLIST_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    axios.delete(`/api/v1/removefromwishlist/${id}`, config);

    dispatch({
      type: REMOVE_FROM_WISHLIST,
      payload: id,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: REMOVE_FROM_WISHLIST_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const clearWishlist = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_WISHLIST_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    await axios.delete(`/api/v1/clearwishlist`, config);

    dispatch({
      type: CLEAR_WISHLIST,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: CLEAR_WISHLIST_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
