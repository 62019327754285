import TestimonialCard from "./TestimonialCard";
import TestimonialSlider from "./TestimonialSlider";
import { useMediaQuery } from "react-responsive";
import testimonial1 from "../../../assets/images/testimonials/testimonial1.webp";
import testimonial2 from "../../../assets/images/testimonials/testimonial2.webp";
import testimonial3 from "../../../assets/images/testimonials/testimonial3.webp";

const Testimonials = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 900 });

  return (
    <section className="flex flex-col gap-0 xl:gap-1">
      <div className=" flex flex-col gap-2 xl:gap-4">
        <div className="text-primary-blue font-poppins600 text-base md:text-lg ">
          {" "}
          3940+ HAPPY BOOKSTORE USERS
        </div>
        <div className="mb-8 text-xl sm:text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-poppins600">
          Testimonials
        </div>
      </div>
      {isSmallScreen ? (
        <TestimonialSlider />
      ) : (
        <div className="flex flex-row items-center lg:items-baseline justify-center sm:justify-between gap-10 sm:gap-4 bg-primary-grey rounded-2xl p-4 sm:p-14 ">
          <TestimonialCard
            name="Prashansha Upadhyay"
            imageSrc={testimonial1}
            testimonial="It's fine...bought books from there ..they take the book sold by them back at 1/3rd price"
            rating={4.5}
            isSmallScreen={isSmallScreen}
          />
          <TestimonialCard
            name="Satendra Tiwari"
            imageSrc={testimonial2}
            testimonial="An excellent book store having higher studies and specialised book. The owner is polite and soft spoken. It is just opposite Kotwali, Paltan Bazar, Dehradun."
            rating={5}
            isSmallScreen={isSmallScreen}
          />
          <TestimonialCard
            name="Akshay Mishra"
            imageSrc={testimonial3}
            testimonial="I have visited this store one is in paltan market and one is in Nehru Colony in Dehradun i found here very nice and complete collection for all types of books stationary office stationary maps and many more things in a very good discounted rates. There are too much varieties available and you can find on your own without taking help. So must visit shop."
            rating={5}
            isSmallScreen={isSmallScreen}
          />
        </div>
      )}
    </section>
  );
};
export default Testimonials;
