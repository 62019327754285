import PurchaseItem from "./PurchaseItem";
import { PriceSidebar, Stepper, MetaData } from "../../utils/imports";
import useOrderConfirm from "../../hooks/purchasing/orderConfirm";

const OrderConfirm = () => {
  const { purchaseItems, user, continueHandler } = useOrderConfirm();

  return (
    <>
      <MetaData title="BooksDehra: Order Confirmation" />

      <main className="mt-16 md:mt-24">
        <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
          <div className="flex-1">
            <Stepper activeStep={2}>
              <div className="">
                {purchaseItems.map((item, i) => (
                  <PurchaseItem
                    {...item.product}
                    key={item.product._id}
                    quantity={item.quantity}
                  />
                ))}
              </div>
              <div className="flex justify-between items-center mt-4  px-6 py-3 rounded-b-sm">
                <p className="text-sm">
                  Order confirmation email will be sent to{" "}
                  <span className="font-poppins600">{user?.email}</span>
                </p>
                <button
                  onClick={continueHandler}
                  className="bg-primary-orange px-2 md:px-4 py-1 md:py-2 text-white font-poppins500 rounded-xl shadow hover:shadow-lg "
                >
                  Continue
                </button>
              </div>
            </Stepper>
          </div>
          <PriceSidebar purchaseItems={purchaseItems} />
        </div>
      </main>
    </>
  );
};

export default OrderConfirm;
