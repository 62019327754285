import { PriceSidebar, MetaData, Stepper } from "../../utils/imports";
import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
} from "@mui/material";
import { razorpay } from "../../utils/images";
import usePayment from "../../hooks/purchasing/payment";

const Payment = () => {
  const { submitHandler, totalPrice, payDisable, purchaseItems } = usePayment();

  return (
    <>
      <MetaData title="BooksDehra: Secure Payment | Razorpay" />

      <main className="w-full mt-20">
        <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">
          <div className="flex-1">
            <Stepper activeStep={3}>
              <div className="w-full bg-white">
                <form
                  onSubmit={(e) => submitHandler(e)}
                  autoComplete="off"
                  className="flex flex-col justify-start gap-2 w-full mx-8 my-4 overflow-hidden"
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="payment-radio-group"
                      defaultValue="paytm"
                      name="payment-radio-button"
                    >
                      <FormControlLabel
                        value="paytm"
                        control={<Radio />}
                        label={
                          <div className="flex items-center gap-4">
                            <img
                              draggable="false"
                              className="h-6 w-6 object-contain"
                              src={razorpay}
                              alt="Razorpay Logo"
                            />
                            <span>Razorpay</span>
                          </div>
                        }
                      />
                    </RadioGroup>
                  </FormControl>

                  <input
                    type="submit"
                    value={`Pay ₹${totalPrice.toLocaleString()}`}
                    disabled={payDisable}
                    className={`${
                      payDisable
                        ? "bg-primary-grey cursor-not-allowed"
                        : "bg-primary-orange cursor-pointer"
                    } w-1/2 sm:w-1/4 my-2 py-3 font-poppins500 text-white shadow hover:shadow-lg rounded-xl uppercase outline-none`}
                  />
                </form>
              </div>
            </Stepper>
          </div>
          <PriceSidebar purchaseItems={purchaseItems} />
        </div>
      </main>
    </>
  );
};

export default Payment;
