import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emptyCart, clearErrors } from "../../actions/cartAction";
import { addItemToPurchase } from "../../actions/purchasingActions";
import { useSnackbar } from "notistack";
import { DIRECT_PURCHASE_FALSE } from "../../constants/purchaseConstants";

const useCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems, error, cartLoading } = useSelector((state) => state.cart);
  const { saveForLaterItems } = useSelector((state) => state.saveForLater);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const placeOrderHandler = () => {
    if (cartItems?.length < 1) {
      enqueueSnackbar("Your cart is empty", { variant: "info" });
      return;
    }
    let isOutOfStock = false;
    cartItems.forEach((item) => {
      if (item.product.stock < item.quantity) {
        enqueueSnackbar(
          `${item.product.name} is out of stock. Please remove it from the cart to proceed`,
          { variant: "info" }
        );
        isOutOfStock = true;
        return;
      }
    });
    if (isOutOfStock) return;

    dispatch(addItemToPurchase(cartItems));
    dispatch({ type: DIRECT_PURCHASE_FALSE });
    if (error) {
      enqueueSnackbar(`Facing some issue ${error}`, { variant: "error" });
    }
    navigate("/shipping");
  };

  const emptyCartHandler = () => {
    dispatch(emptyCart());
    setConfirmationOpen(false);
    enqueueSnackbar("All items removed from cart ", { variant: "success" });
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, enqueueSnackbar, dispatch]);

  return {
    confirmationOpen,
    cartItems,
    saveForLaterItems,
    setConfirmationOpen,
    placeOrderHandler,
    emptyCartHandler,
    cartLoading,
  };
};

export default useCart;
