import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART,
  ADD_TO_CART_FAIL,
  EMPTY_CART_REQUEST,
  EMPTY_CART,
  EMPTY_CART_FAIL,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART,
  REMOVE_FROM_CART_FAIL,
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS,
  GET_CART_ITEMS_FAIL,
  CLEAR_ERRORS,
} from "../constants/cartConstants";

export const cartReducer = (
  state = { cartItems: [], cartLoading: false },
  { type, payload }
) => {
  const item = payload;
  const isItemExist = state.cartItems?.find(
    (el) => el?.product?._id === item?.product?._id
  );

  switch (type) {
    case ADD_TO_CART_REQUEST:
    case EMPTY_CART_REQUEST:
    case REMOVE_FROM_CART_REQUEST:
    case GET_CART_ITEMS_REQUEST:
      return {
        ...state,
        cartLoading: true,
      };
    case ADD_TO_CART_FAIL:
    case EMPTY_CART_FAIL:
    case REMOVE_FROM_CART_FAIL:
    case GET_CART_ITEMS_FAIL:
      return {
        ...state,
        cartLoading: false,
        error: payload,
      };
    case ADD_TO_CART:
      if (isItemExist) {
        return {
          ...state,
          cartLoading: false,
          cartItems: state.cartItems?.map((el) =>
            el?.product?._id === isItemExist?.product?._id ? item : el
          ),
        };
      } else {
        return {
          ...state,
          cartLoading: false,
          cartItems: [...state.cartItems, item],
        };
      }
    case GET_CART_ITEMS:
      return {
        ...state,
        cartLoading: false,
        cartItems: payload,
      };

    case REMOVE_FROM_CART:
      return {
        ...state,
        cartLoading: false,
        cartItems: state.cartItems?.filter(
          (el) => el?.product?._id !== payload
        ),
      };
    case EMPTY_CART:
      return {
        ...state,
        cartLoading: false,
        cartItems: [],
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
