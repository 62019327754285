import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { saveShippingInfo } from "../../actions/addressAction";
import { useNavigate } from "react-router-dom";
import { addAddressToPurchase } from "../../actions/purchasingActions";

const useShipping = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const purchaseItems = useSelector((state) => state.purchase.purchasing);

  const { enqueueSnackbar } = useSnackbar();

  const { shippingInfo } = useSelector((state) => state.address);

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("IN");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const shippingSubmit = (e) => {
    e.preventDefault();
    if (!address || !city || !state || !pincode || !phoneNo) {
      enqueueSnackbar("Please fill all the fields", {
        variant: "error",
      });
      return;
    }

    dispatch(
      saveShippingInfo({ address, city, country, state, pincode, phoneNo })
    );

    dispatch(
      addAddressToPurchase({ address, city, country, state, pincode, phoneNo })
    );

    navigate("/order/confirm");
  };

  const handleOrder = (e) => {
    e.preventDefault();
    navigate("/order/confirm");
  };

  const [openModal, setOpenModal] = useState(false);
  // const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressSelected, setAddressSelected] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddressSelect = (address) => {
    // setSelectedAddress(address);
    setAddress(address.address || "");
    setCity(address.city || "");
    setState(address.state || "");
    setPincode(address.pincode || "");
    setPhoneNo(address.phoneNo || "");

    dispatch(addAddressToPurchase(address));

    setAddressSelected(true);
    handleCloseModal();
  };

  const savedAddressHandler = () => {
    if (!Array.isArray(shippingInfo) || shippingInfo.length === 0) {
      enqueueSnackbar("No saved addresses found", {
        variant: "error",
      });
      return;
    }
    handleOpenModal();
  };

  return {
    address,
    setAddress,
    city,
    setCity,
    country,
    setCountry,
    state,
    setState,
    pincode,
    setPincode,
    phoneNo,
    setPhoneNo,
    shippingSubmit,
    handleOrder,
    openModal,
    handleCloseModal,
    handleAddressSelect,
    addressSelected,
    savedAddressHandler,
    shippingInfo,
    purchaseItems,
  };
};

export default useShipping;
