import { TextField, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { FormSidebar, MetaData, BackdropLoader } from "../../utils/imports";
import { VisibilityIcon, VisibilityOffIcon } from "../../utils/iconImports";
import useLogin from "../../hooks/user/login";

const Login = () => {
  const {
    email,
    setEmail,
    password,
    showPassword,
    handleShowPassword,
    setPassword,
    handleLogin,
    loading,
    isAuthenticated,
  } = useLogin();

  return (
    <div className="flex flex-col items-center justify-center mt-16 md:mt-24">
      <MetaData title="Login | BooksDehra" />
      {loading && isAuthenticated && <BackdropLoader />}
      <main className="w-4/5 lg:w-3/4 ">
        <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-primary-lightGrey shadow-lg rounded-2xl ">
          <FormSidebar title="Login" tag="Best book store in Dehradun" />

          <div className="flex-1 overflow-hidden">
            <div className="text-center py-10 px-4 sm:px-14">
              <form onSubmit={handleLogin}>
                <div className="flex flex-col w-full gap-5">
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    id="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    InputProps={
                      showPassword
                        ? {
                            endAdornment: (
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={handleShowPassword}
                              >
                                <VisibilityOffIcon />
                              </IconButton>
                            ),
                          }
                        : {
                            endAdornment: (
                              <IconButton
                                aria-label="Show password"
                                onClick={handleShowPassword}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ),
                          }
                    }
                  />

                  <div className="flex flex-col gap-4 mt-2 mb-32">
                    <button
                      type="submit"
                      className="text-white py-3 w-full bg-primary-darkBlue shadow hover:shadow-lg rounded-xl font-poppins500"
                    >
                      Login
                    </button>
                    <Link
                      to="/password/forgot"
                      className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-xl font-poppins500"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </form>
              <Link
                to="/register"
                className="font-poppins500 text-sm text-primary-blue"
              >
                New here? Create account
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
