import React from "react";
import {
  aboutUs1,
  aboutUs2,
  aboutUs3,
  aboutUs4,
  aboutUs5,
  aboutUs6,
} from "../../utils/images";

const AboutUs = () => {
  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 font-poppins400">
      <div className="lg:w-10/12 w-full">
        <p className="text-sm leading-3 text-indigo-700 dark:text-indigo-500 hover:text-indigo-800 cursor-pointer">
          About
        </p>
        <div className="xl:w-8/12 lg:w-10/12 w-full font-poppins500 text-gray-800  lg:text-4xl text-3xl lg:leading-10 leading-9 mt-2">
          About Us
        </div>

        <p className=" text-base leading-6 text-gray-600 mt-6">
          For over six decades, we've been dedicated to bringing the joy of
          reading to our community. Since our establishment in 1960, we've grown
          to become a cornerstone of the book-loving culture in Dehradun, with
          two distinct locations that cater to the diverse needs of our
          customers.
        </p>
        <p className=" text-base leading-6 text-gray-600 mt-3">
          While our Nehru Colony store may not be our inaugural location, it
          surpasses our first store in excellence. It stands proudly as the
          pioneering book mall of the UK, offering an immersive experience for
          book enthusiasts of all ages. Meanwhile, our Moti Bazar location holds
          the esteemed title of being our very first legacy store, a testament
          to our commitment to serving our customers with excellence.
        </p>
        <p className=" text-base leading-6 text-gray-600 mt-3">
          With a rich history spanning over 64 years, our commitment to
          providing the widest variety of books remains unwavering. We take
          pride in being the trailblazers of offering maximum discounts and
          pioneering the availability of medical, ayurvedic, and engineering
          books in our region.
        </p>
        <p className=" text-base leading-6 text-gray-600 mt-3">
          At our stores, you'll find a treasure trove of literature covering
          every field imaginable. From educational books to captivating novels,
          from competitive exam guides to corporate gifts and stationery, we
          strive to cater to every reading taste and need. What sets us apart is
          our promise: If a book isn't available in our stores, it simply isn't
          available in Doon. With both offline and online shopping options, we
          ensure that our customers have access to their favorite reads whenever
          and wherever they desire.
        </p>
        <p className=" text-base leading-6 text-gray-600 mt-3">
          Join us on our journey as we continue to foster a love for reading and
          learning in our beloved community. Thank you for choosing us as your
          trusted destination for everything related to books and education.
        </p>
      </div>

      <div className="lg:mt-14 sm:mt-10 mt-12">
        <img
          className="lg:block hidden w-full"
          src={aboutUs1}
          alt="Group of people Chilling"
        />
        <img
          className="lg:hidden sm:block hidden w-full"
          src={aboutUs2}
          alt="Group of people Chilling"
        />
        <img
          className="sm:hidden block w-full"
          src={aboutUs3}
          alt="Group of people Chilling"
        />
      </div>

      <div className="lg:mt-16 sm:mt-12 mt-16 flex lg:flex-row justify-between flex-col lg:gap-8 gap-12">
        <div className="w-full xl:w-5/12 lg:w-6/12">
          <div className="font-poppins500 lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 ">
            Our Story
          </div>
          <p className=" text-base leading-6 text-gray-600  mt-4">
            Once upon a time, on a chilly December day in 1960, Shri. Santram
            Suneja decided to embark on an adventure. With a twinkle in his eye
            and a dream in his heart, he opened a small bookshop in the heart of
            town. This wasn't just any bookshop; it was a haven for students,
            offering everything from stationary to BA and BCom books. And this
            is how the Ramesh Book Depot came to life. But here's where the tale
            takes an interesting turn. You see, Shri Santram Suneja was so
            passionate about his bookshop that he decided to name his son after
            it. A fitting tribute, don't you think?
          </p>
          <p className=" text-base leading-6 text-gray-600  mt-6">
            As the years passed, Ramesh Suneja, the son of Santram Suneja, took
            the reins of the business. With dedication and perseverance, he grew
            the variety of offerings organically, ensuring that Ramesh Book
            Depot remained a beloved fixture in the community. And now, as the
            story continues, we find ourselves in the present day. The third
            generation has joined the business, carrying forward the legacy with
            pride and passion. As we look towards the future, we envision Ramesh
            Book Depot as a beacon of knowledge and inspiration, enriching lives
            through the power of books. Our mission is clear: to curate a
            diverse collection of books that enlighten, educate, and entertain,
            ensuring that everyone finds their literary companion within our
            walls. And as the sun sets on another day, we embrace the new dawn
            on the horizon. With the third generation by our side, Ramesh Book
            Depot is poised for a bright and promising future, continuing to
            serve our community with warmth and dedication, one story at a time.
          </p>
        </div>
        <div className="lg:flex items-center w-full lg:w-1/2">
          <img
            className="lg:block hidden w-full"
            src={aboutUs4}
            alt="people discussing on board"
          />
          <img
            className="lg:hidden sm:block hidden w-full h-3/4"
            src={aboutUs5}
            alt="people discussing on board"
          />
          <img
            className="sm:hidden block w-full"
            src={aboutUs6}
            alt="people discussing on board"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
