import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getBestSellerProducts,
} from "../../actions/productAction";
import { useSnackbar } from "notistack";

const useHome = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { productsFound, error, loading } = useSelector(
    (state) => state.bestSellers
  );

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [dispatch, error, enqueueSnackbar]);

  useEffect(() => {
    dispatch(getBestSellerProducts());
  }, [dispatch]);

  return { loading, productsFound };
};

export default useHome;
