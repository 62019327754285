import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearErrors, getProducts } from "../../actions/productAction";

const useProducts = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const categoryParam = searchParams.get("category");
  const examParam = searchParams.get("exam");
  const keywordParam = searchParams.get("keyword");
  const bestSellerParam = searchParams.get("bestSeller");

  const [price, setPrice] = useState([0, 50000]);

  const [category, setCategory] = useState(categoryParam || "");
  const [ratings, setRatings] = useState(0);
  const [exam, setExam] = useState(examParam || "");
  const [keyword, setKeyword] = useState(keywordParam || "");
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryToggle, setCategoryToggle] = useState(true);
  const [ratingsToggle, setRatingsToggle] = useState(true);
  const [examsToggle, setExamsToggle] = useState(false);
  const [bestSeller, setBestSeller] = useState(bestSellerParam || false);

  const [toggleFilterSidebar, setToggleFilterSidebar] = useState(false);

  const updateQueryParams = () => {
    const query = new URLSearchParams();
    if (category) query.set("category", category);
    if (exam) query.set("exam", exam);
    if (bestSeller) query.set("bestSeller", bestSeller);
    if (keyword) query.set("keyword", keyword);
    if (price) query.set("price", price);
    if (ratings) query.set("ratings", ratings);
    if (currentPage) query.set("page", currentPage);
    navigate(`?${query.toString()}`);
  };

  const handleCategoryChange = (e) => {
    const query = new URLSearchParams();
    query.set("category", e.target.value);
    navigate(`?${query.toString()}`);
    if (e.target.value === category) {
      setCategory("");
    } else {
      setKeyword("");
      setCategory(e.target.value);
    }
    setCurrentPage(1);
  };

  const handleExamChange = (e) => {
    const query = new URLSearchParams();
    query.set("exam", e.target.value);
    navigate(`?${query.toString()}`);
    if (e.target.value === exam) {
      setExam("");
    } else {
      setKeyword("");
      setExam(e.target.value);
    }
    setCurrentPage(1);
  };

  const handleRatingsChange = (e) => {
    const query = new URLSearchParams();
    query.set("ratings", e.target.value);
    navigate(`?${query.toString()}`);
    if (e.target.value === ratings) {
      setRatings(0);
    } else {
      setKeyword("");
      setRatings(e.target.value);
    }
    setCurrentPage(1);
  };

  const handleBestSellerChange = (e) => {
    const query = new URLSearchParams();
    query.set("bestSeller", e.target.checked);
    navigate(`?${query.toString()}`);
    setKeyword("");
    if (e.target.checked) {
      setBestSeller(!bestSeller);
    }
    setCurrentPage(1);
  };

  const toggleFilterSidebarHandler = () => {
    setToggleFilterSidebar(!toggleFilterSidebar);
  };

  const closeFilterSidebarHandler = () => {
    setToggleFilterSidebar(false);
  };

  const {
    products,
    loading,
    error,
    resultPerPage,
    filteredProductsCount,
    totalPages,
    productsFound,
  } = useSelector((state) => state.products);

  const priceHandler = (e, newPrice) => {
    e.preventDefault();
    setPrice(newPrice);
    setCurrentPage(1);
  };

  const clearQueryParams = () => {
    const query = new URLSearchParams();
    navigate(`?${query.toString()}`);
  };

  const clearFilters = () => {
    setPrice([0, 50000]);
    setCategory("");
    setRatings(0);
    setExam("");
    setBestSeller(false);
    setKeyword("");
    setCurrentPage(1);
    clearQueryParams();
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  useEffect(() => {
    updateQueryParams();
    // eslint-disable-next-line
  }, [category, exam, keyword, bestSeller, price, ratings, currentPage]);

  useEffect(() => {
    if (keyword !== keywordParam) {
      setCurrentPage(1);
      if (keywordParam) {
        setKeyword(keywordParam);
        setCategory("");
        setExam("");
        setRatings(0);
        setBestSeller(false);
        setPrice([0, 50000]);
      }
    }
  }, [keyword, keywordParam]);

  useEffect(() => {
    dispatch(
      getProducts(
        keyword,
        exam,
        bestSeller,
        category,
        price,
        ratings,
        currentPage
      )
    );
  }, [
    dispatch,
    keyword,
    exam,
    bestSeller,
    category,
    price,
    ratings,
    currentPage,
  ]);

  return {
    price,
    category,
    exam,
    ratings,
    bestSeller,
    currentPage,
    categoryToggle,
    examsToggle,
    ratingsToggle,
    products,
    loading,
    resultPerPage,
    filteredProductsCount,
    toggleFilterSidebar,
    productsFound,
    totalPages,
    setToggleFilterSidebar,
    toggleFilterSidebarHandler,
    closeFilterSidebarHandler,
    priceHandler,
    clearFilters,
    handleCategoryChange,
    handleExamChange,
    handleRatingsChange,
    handleBestSellerChange,
    setCurrentPage,
    setCategoryToggle,
    setExamsToggle,
    setRatingsToggle,
  };
};

export default useProducts;
