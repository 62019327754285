import React from "react";

const Cancellation = () => {
  return (
    <div className="flex flex-col gap-5 mt-5 p-5 bg-primary-lightGrey rounded-xl">
      <div className="text-2xl font-poppins600  text-primary-blue align-center">
        Cancellation & Returns Policy
      </div>
      <div className="flex flex-col justify-between gap-2 text-gray-600 font-poppins400">
        <p className="font-poppins600 text-lg">
          At BooksDehra by Ramesh Book Depot, we want you to be completely
          satisfied with your purchases. Here's our policy regarding order
          cancellation, exchanges, and returns:
        </p>
        <ul className="list-disc pl-5">
          <li className="mb-3">
            <strong>Order Cancellation:</strong>You may cancel your order at any
            time before it has been dispatched for delivery. Once your order has
            been dispatched, it cannot be cancelled.
          </li>
          <li className="mb-3">
            <strong>Exchange Policy:</strong>If you receive a damaged or
            defective item, please contact us within 3 days of receiving your
            order to arrange for an exchange. We will replace the item free of
            charge.
          </li>
          <li className="mb-3">
            <strong>Return Policy:</strong> We do not accept returns for any
            reason other than damaged or defective items. All sales are final.
          </li>
          <li className="mb-3">
            <strong>Contact Us:</strong> If you have any questions or concerns
            about our Cancellation & Returns Policy, please contact us at{" "}
            <a
              href="mailto:help@booksdehra.com"
              className=" text-primary-midBlue hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              help@booksdehra.com
            </a>{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Cancellation;
