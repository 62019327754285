import { MetaData, Loader } from "../../utils/imports";
import OrderItem from "./OrderItem";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import useMyOrders from "../../hooks/order/myOrders";
import { CloseIcon, FilterListIcon, SearchIcon } from "../../utils/iconImports";

const MyOrders = () => {
  const {
    status,
    orderTime,
    search,
    filteredOrders,
    loading,
    orderStatus,
    ordertime,
    orders,
    toggleFilterSidebar,
    clearSearch,
    searchOrders,
    clearFilters,
    handleStatusChange,
    handleTimeChange,
    handleSearchChange,
    toggleFilterSidebarHandler,
    closeFilterSidebarHandler,
  } = useMyOrders();

  return (
    <>
      <MetaData title="My Orders | BooksDehra" />

      <main className="w-full mt-16 md:mt-24">
        <div className="flex flex-col sm:flex-row sm:gap-3 mt-2 sm:mx-3 m-auto mb-7">
          <button
            onClick={toggleFilterSidebarHandler}
            className={`sm:hidden bg-gray-700 w-8 h-8 ml-2 mb-1 rounded-full shadow text-white flex items-center justify-center`}
          >
            <FilterListIcon />
          </button>
          <div
            className={`fixed inset-0 z-20 px-1 transform transition-transform w-1/3 md:w-1/4 lg:w-1/5 ${
              toggleFilterSidebar
                ? "overflow-y-auto max-h-full mt-3 translate-x-0 w-4/5 z-[5000]"
                : "-translate-x-full"
            } sm:translate-x-0 sm:static bg-white sm:bg-transparent `}
          >
            <div className="flex flex-col rounded-sm shadow ">
              <div className="flex items-center justify-between gap-5 px-4 py-2 border-b">
                <p className="text-lg font-poppins500">Filters</p>
                {toggleFilterSidebar ? (
                  <button
                    className="uppercase text-primary-blue text-xs cursor-pointer font-poppins500"
                    onClick={() => clearFilters()}
                  >
                    clear all
                  </button>
                ) : (
                  <button
                    className="uppercase text-primary-blue text-xs cursor-pointer font-poppins500 hidden sm:block"
                    onClick={() => clearFilters()}
                  >
                    clear all
                  </button>
                )}
                {toggleFilterSidebar && (
                  <button
                    onClick={closeFilterSidebarHandler}
                    className="sm:hidden bg-gray-700 w-6 h-6 rounded-full shadow text-white flex items-center justify-center"
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
              <div className="flex flex-col gap-2 py-3 overflow-hidden text-sm">
                <span className="font-poppins500 px-4">ORDER STATUS</span>
                <div className="flex flex-col gap-3 px-4 mt-1 pb-3 border-b">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="orderstatus-radio-buttons-group"
                      onClick={handleStatusChange}
                      name="orderstatus-radio-buttons"
                      value={status}
                    >
                      {orderStatus?.map((el, i) => (
                        <FormControlLabel
                          value={el}
                          control={<Radio size="small" />}
                          key={el}
                          label={<span className="text-sm">{el}</span>}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="flex flex-col pb-2 text-sm">
                <span className="font-poppins500 px-4">ORDER TIME</span>
                <div className="flex flex-col gap-3 mt-1 px-4 pb-3">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="ordertime-radio-buttons-group"
                      onClick={handleTimeChange}
                      name="ordertime-radio-buttons"
                      value={orderTime}
                    >
                      {ordertime?.map((el, i) => (
                        <FormControlLabel
                          value={el}
                          control={<Radio size="small" />}
                          key={el}
                          label={
                            <span className="text-sm">
                              {i === 0 ? "This Month" : el}
                            </span>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            {loading ? (
              <Loader />
            ) : (
              <div className="flex flex-col gap-2 md:gap-3 sm:mr-4 overflow-hidden">
                <form
                  onSubmit={searchOrders}
                  className="flex items-center justify-between mx-1 sm:mx-0 sm:w-10/12 bg-white border rounded-xl hover:shadow"
                >
                  <input
                    value={search}
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        searchOrders(e);
                      }
                    }}
                    type="text"
                    placeholder="Search your orders here"
                    className="p-2 text-sm outline-none flex-1 rounded-l overflow-auto text-ellipsis"
                  />
                  {search && (
                    <button
                      onClick={clearSearch}
                      className="text-gray-500 hover:text-gray-700 mr-1"
                    >
                      <CloseIcon />
                    </button>
                  )}
                  <button
                    type="submit"
                    className="h-full text-sm px-1 sm:px-4 py-2.5 text-white bg-primary-blue hover:bg-blue-600 rounded-r-xl flex items-center gap-1"
                  >
                    <SearchIcon sx={{ fontSize: "22px" }} />
                    Search Orders
                  </button>
                </form>
                {orders && filteredOrders?.length === 0 && (
                  <div className="flex items-center flex-col gap-2 p-8 bg-white">
                    <img
                      draggable="false"
                      src="https://rukminim1.flixcart.com/www/100/100/promos/23/08/2020/c5f14d2a-2431-4a36-b6cb-8b5b5e283d4f.png"
                      alt="Empty Orders"
                    />
                    <span className="text-lg font-poppins500">
                      Sorry, no results found
                    </span>
                    <p>Edit search or clear all filters</p>
                  </div>
                )}

                {!orders && (
                  <div className="flex items-center flex-col gap-2 p-8 bg-white">
                    <img
                      draggable="false"
                      src="https://rukminim1.flixcart.com/www/100/100/promos/23/08/2020/c5f14d2a-2431-4a36-b6cb-8b5b5e283d4f.png"
                      alt="Empty Orders"
                    />
                    <span className="text-lg font-poppins500">
                      You haven't placed any orders yet
                    </span>
                    <p>Go ahead and place your first order</p>
                  </div>
                )}

                {orders &&
                  filteredOrders
                    ?.map((order) => {
                      const {
                        _id,
                        orderStatus,
                        orderItems,
                        createdAt,
                        deliveredAt,
                      } = order || {};

                      // return orderItems?.map((item) => (
                      //   <OrderItem
                      //     {...item}
                      //     key={item._id}
                      //     orderId={_id}
                      //     orderStatus={orderStatus}
                      //     createdAt={createdAt}
                      //     deliveredAt={deliveredAt}
                      //   />
                      // ));
                      return (
                        <OrderItem
                          orderItems={orderItems}
                          key={_id}
                          orderId={_id}
                          orderStatus={orderStatus}
                          createdAt={createdAt}
                          deliveredAt={deliveredAt}
                        />
                      );
                    })
                    .reverse()}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default MyOrders;
