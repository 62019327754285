import { Link } from "react-router-dom";
import { BackdropLoader, MetaData, FormSidebar } from "../../utils/imports";
import { TextField, IconButton } from "@mui/material";
import { VisibilityIcon, VisibilityOffIcon } from "../../utils/iconImports";
import useRegister from "../../hooks/user/register";

const Register = () => {
  const {
    name,
    email,
    phone,
    password,
    cpassword,
    agree,
    showPassword,
    loading,
    isAuthenticated,
    handleDataChange,
    handleRegister,
    handleAgree,
    handleShowPassword,
  } = useRegister();

  return (
    <div className="flex flex-col items-center justify-center mt-16 md:mt-24">
      <MetaData title="Register | BooksDehra" />
      {loading && isAuthenticated && <BackdropLoader />}
      <main className="w-4/5 lg:w-3/4  ">
        <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-primary-lightGrey shadow-lg rounded-2xl">
          <FormSidebar
            title="Looks like you're new here!"
            tag="Sign up with your mobile number to get started"
          />
          <div className="flex-1 overflow-hidden ">
            <form onSubmit={handleRegister} className="p-5 sm:p-10">
              <div className="flex flex-col gap-4 justify-between items-start w-full">
                <TextField
                  fullWidth
                  id="full-name"
                  label="Full Name"
                  name="name"
                  value={name}
                  onChange={handleDataChange}
                  required
                />
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleDataChange}
                  required
                />
                <TextField
                  fullWidth
                  id="phone"
                  label="Phone"
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={handleDataChange}
                  required
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleDataChange}
                  required
                  fullWidth
                />
                <TextField
                  id="confirm-password"
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  name="cpassword"
                  value={cpassword}
                  onChange={handleDataChange}
                  required
                  fullWidth
                  InputProps={
                    showPassword
                      ? {
                          endAdornment: (
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleShowPassword}
                            >
                              <VisibilityOffIcon />
                            </IconButton>
                          ),
                        }
                      : {
                          endAdornment: (
                            <IconButton
                              aria-label="Show password"
                              onClick={handleShowPassword}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          ),
                        }
                  }
                />
                <div className="flex items-center gap-4">
                  <input
                    type="checkbox"
                    id="agreeCheckbox"
                    className="mr-2 cursor-pointer"
                    onChange={handleAgree}
                    checked={agree}
                  />
                  <p className=" font-poppins500 text-xs  text-left">
                    By continuing, you agree to BookDehra's{" "}
                    <Link to="/terms-of-service" className="text-primary-blue">
                      Terms of Use
                    </Link>{" "}
                    and{" "}
                    <Link to="/privacy" className="text-primary-blue">
                      Privacy Policy.
                    </Link>
                  </p>
                </div>
                <button
                  type="submit"
                  className="text-white py-3 w-full bg-primary-darkBlue shadow hover:shadow-lg rounded-xl font-poppins500"
                  onClick={handleRegister}
                >
                  Signup
                </button>
                <Link
                  to="/login"
                  className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-xl font-poppins500"
                >
                  Existing User? Log in
                </Link>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Register;
