import { useState } from "react";
import { getDiscount } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../actions/wishlistAction";
import { useSnackbar } from "notistack";

const useProduct = (_id) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { wishlistItems, loading, error } = useSelector(
    (state) => state.wishlist
  );
  const itemInWishlist = wishlistItems?.some((i) => i?._id === _id);
  const userToken = localStorage.getItem("userToken");

  const [heartColor, setHeartColor] = useState(
    itemInWishlist ? "#FF0000" : "#CCCCCC"
  );

  const addToWishlistHandler = () => {
    if (!userToken) {
      enqueueSnackbar("Please login to add to wishlist", {
        variant: "error",
      });
      return;
    }
    if (itemInWishlist) {
      setHeartColor("#CCCCCC");
      dispatch(removeFromWishlist(_id));
      if (error) {
        setHeartColor("#FF0000");
        enqueueSnackbar(error, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Removed From Wishlist", {
          variant: "info",
        });
      }
    } else {
      setHeartColor("#FF0000");
      dispatch(addToWishlist(_id));
      if (error) {
        setHeartColor("#CCCCCC");
        enqueueSnackbar(error, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Added To Wishlist", {
          variant: "success",
        });
      }
    }
  };

  return {
    addToWishlistHandler,
    itemInWishlist,
    getDiscount,
    loading,
    heartColor,
  };
};

export default useProduct;
