import { useState } from "react";
import { getDiscount } from "../../../utils/functions";
import Rating from "@mui/material/Rating";
import { FavoriteIcon } from "../../../utils/iconImports";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../actions/wishlistAction";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

const Product = (props) => {
  const {
    _id,
    name,
    images,
    ratings,
    numOfReviews,
    price,
    discountPrice,
    author,
  } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { wishlistItems, loading, error } = useSelector(
    (state) => state.wishlist
  );

  const itemInWishlist = wishlistItems?.some((i) => i?._id === _id);

  const [heartColor, setHeartColor] = useState(
    itemInWishlist ? "#FF0000" : "#CCCCCC"
  );

  const userToken = localStorage.getItem("userToken");

  const addToWishlistHandler = () => {
    if (!userToken) {
      enqueueSnackbar("Please login to add to wishlist", {
        variant: "error",
      });
      return;
    }
    if (itemInWishlist) {
      setHeartColor("#CCCCCC");
      dispatch(removeFromWishlist(_id));
      if (error) {
        setHeartColor("#FF0000");
        enqueueSnackbar(error, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Removed From Wishlist", {
          variant: "info",
        });
      }
    } else {
      setHeartColor("#FF0000");
      dispatch(addToWishlist(_id));
      if (error) {
        setHeartColor("#CCCCCC");
        enqueueSnackbar(error, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Added To Wishlist", {
          variant: "success",
        });
      }
    }
  };

  return (
    <div className="flex flex-col items-center gap-2 px-2 py-6 relative">
      <Link
        to={`/product/${_id}`}
        className="flex flex-col items-center text-center group"
      >
        <div className="w-36 h-36">
          <img
            draggable="false"
            className="w-full h-full object-contain"
            src={images[0]?.url}
            alt={name}
          />
        </div>
        <div className="text-sm mt-4 group-hover:text-primary-blue">
          {name?.length > 50 ? `${name?.substring(0, 50)}...` : name}
        </div>
        <span className="text-gray-500 text-sm mt-2 font-poppins500">
          {author}
        </span>
      </Link>
      <div className="flex flex-col gap-2 items-center">
        <span className="text-sm text-gray-500 font-poppins500 flex gap-2 items-center">
          <span className="text-xs px-1.5 py-0.5  rounded-sm  flex items-center gap-0.5">
            <Rating
              name="read-only"
              value={ratings}
              readOnly
              size="small"
              precision={0.5}
            />
          </span>
          <span>({numOfReviews?.toLocaleString()})</span>
        </span>
        <div className="flex items-center gap-1.5 text-base font-poppins500">
          <span>₹{discountPrice?.toLocaleString()}</span>
          <span className="text-gray-500 line-through text-xs">
            ₹{price?.toLocaleString()}
          </span>
          <span className="text-xs text-primary-green">
            {getDiscount(price, discountPrice)}%&nbsp;off
          </span>
        </div>
      </div>
      <button
        onClick={addToWishlistHandler}
        className={`${
          loading ? "pointer-events-none" : ""
        } hover:shadow-xl absolute top-5 right-2 cursor-pointer`}
      >
        <FavoriteIcon sx={{ fontSize: "16px", color: heartColor }} />
      </button>
    </div>
  );
};

Product.propTypes = {
  _id: PropTypes.string,
  name: PropTypes.string,
  images: PropTypes.array,
  ratings: PropTypes.number,
  numOfReviews: PropTypes.number,
  price: PropTypes.number,
  discountPrice: PropTypes.number,
  author: PropTypes.string,
};

export default Product;
