import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  forgotPassword,
  clearMessage,
} from "../../actions/userAction";
import { useSnackbar } from "notistack";

const useForgotPassword = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { error, message, loading } = useSelector(
    (state) => state.forgotPassword
  );

  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(forgotPassword(email));
    setEmail("");
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (message) {
      enqueueSnackbar(message, { variant: "success" });
      dispatch(clearMessage());
    }
  }, [dispatch, error, message, enqueueSnackbar]);

  return {
    email,
    setEmail,
    handleSubmit,
    loading,
  };
};

export default useForgotPassword;
