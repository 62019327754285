import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import usePurchaseItem from "../../hooks/purchasing/purchaseItem";
import { Confirmation } from "../../utils/imports";

const PurchaseItem = ({
  _id,
  name,
  author,
  price,
  discountPrice,
  images,
  stock,
  quantity,
}) => {
  const {
    confirmationOpen,
    setConfirmationOpen,
    increaseQuantity,
    decreaseQuantity,
    getDeliveryDate,
    getDiscount,
    handleRemove,
  } = usePurchaseItem();

  return (
    <div
      className="flex flex-col gap-3 py-5 pl-2 sm:pl-6 border-b items-center md:items-start "
      key={_id}
    >
      <Link
        to={`/product/${_id}`}
        className="flex flex-col sm:flex-row gap-5 items-stretch  group"
      >
        <div className="w-full sm:w-1/6 h-28 flex-shrink-0">
          <img
            draggable="false"
            className="h-full w-full object-contain"
            src={images[0].url}
            alt={name}
          />
        </div>
        <div className="flex flex-col sm:gap-5 w-full pr-6">
          <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start pr-5 gap-1 sm:gap-0">
            <div className="flex flex-col gap-0.5 sm:w-3/5">
              <p className="group-hover:text-primary-blue text-lg">
                {name.length > 42 ? `${name.substring(0, 42)}...` : name}
              </p>
              <span className="text-sm text-gray-500">{author}</span>
            </div>

            <div className="flex flex-col sm:gap-2">
              <p className="text-sm">
                Delivery by {getDeliveryDate()} |{" "}
                <span className="text-primary-green">Free</span>{" "}
                <span className="line-through">₹{quantity * 40}</span>
              </p>
              <span className="text-xs text-gray-500">
                7 Days Replacement Policy
              </span>
            </div>
          </div>
          <div className="flex items-baseline gap-2 text-xl font-poppins500">
            <span>₹{(discountPrice * quantity).toLocaleString()}</span>
            <span className="text-sm text-gray-500 line-through font-normal">
              ₹{(price * quantity).toLocaleString()}
            </span>
            <span className="text-sm text-primary-green">
              {getDiscount(price, discountPrice)}%&nbsp;off
            </span>
          </div>
        </div>
      </Link>
      <div className="flex justify-between pr-4 sm:pr-0 sm:justify-start sm:gap-6">
        <div className="flex gap-1 items-center">
          <button
            onClick={() => decreaseQuantity(_id, quantity)}
            className="w-7 h-7 text-xl font-poppins300 bg-red-200 rounded-full border flex items-center justify-center cursor-pointer hover:font-poppins500 hover:bg-red-300"
          >
            <p>-</p>
          </button>
          <input
            className="w-11 border outline-none text-center rounded-sm py-0.5 text-gray-700 font-poppins600 text-sm qtyInput"
            value={quantity}
            disabled
          />
          <button
            onClick={() => increaseQuantity(_id, quantity, stock)}
            className="w-7 h-7 text-xl font-poppins300 bg-blue-200 rounded-full border flex items-center justify-center cursor-pointer hover:font-poppins500 hover:bg-blue-300"
          >
            +
          </button>
        </div>
        <Confirmation
          open={confirmationOpen}
          confirmationText="Are you sure you want to remove this item from purchase"
          onClose={() => setConfirmationOpen(false)}
          onConfirm={() => handleRemove(_id)}
          task="Remove from purchase"
        />
      </div>
    </div>
  );
};

PurchaseItem.propTypes = {
  _id: PropTypes.string,
  name: PropTypes.string,
  author: PropTypes.string,
  price: PropTypes.number,
  discountPrice: PropTypes.number,
  images: PropTypes.array,
  stock: PropTypes.number,
  quantity: PropTypes.number,
};

export default PurchaseItem;
