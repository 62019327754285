import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, registerUser } from "../../actions/userAction";
import { useNavigate } from "react-router-dom";

const useRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, isAuthenticated, error } = useSelector(
    (state) => state.user
  );

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    cpassword: "",
  });

  const [agree, setAgree] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { name, email, phone, password, cpassword } = user;

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleAgree = () => {
    setAgree(!agree);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      enqueueSnackbar("Password length must be atleast 8 characters", {
        variant: "warning",
      });
      return;
    }
    if (password !== cpassword) {
      enqueueSnackbar("Password Doesn't Match", {
        variant: "error",
      });
      return;
    }

    if (!agree) {
      enqueueSnackbar("Please agree to the terms of use and privacy policy", {
        variant: "error",
      });
      return;
    }
    dispatch(registerUser(user));
  };

  const handleDataChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      navigate("/");
    }
  }, [dispatch, error, isAuthenticated, navigate, enqueueSnackbar]);

  return {
    name,
    email,
    phone,
    password,
    cpassword,
    agree,
    showPassword,
    loading,
    isAuthenticated,
    handleDataChange,
    handleRegister,
    handleAgree,
    handleShowPassword,
  };
};

export default useRegister;
