import { Routes, Route } from "react-router-dom";
import {
  Footer,
  Header,
  NotFound,
  Login,
  Register,
  UpdateProfile,
  UpdatePassword,
  ForgotPassword,
  ResetPassword,
  Account,
  Home,
  ProductDetails,
  Products,
  Cart,
  Shipping,
  OrderConfirm,
  Payment,
  OrderSuccess,
  MyOrders,
  OrderDetails,
  Dashboard,
  MainData,
  OrderTable,
  UpdateOrder,
  ProductTable,
  NewProduct,
  UpdateProduct,
  UserTable,
  UpdateUser,
  ReviewsTable,
  Wishlist,
  ManageAddress,
  AddShippingInfo,
  Privacy,
  TermsofService,
  Cancellation,
  ContactUs,
  AboutUs,
  Container,
  ProtectedRoute,
  UserDashboard,
  BackdropLoader,
} from "./utils/imports";

import useApp from "./hooks/app";

function App() {
  const { loading } = useApp();
  return (
    <>
      {loading && <BackdropLoader />}
      <Header />
      <div className="md:min-h-[50vh] ">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          <Route
            path="/product/:id"
            element={
              <Container>
                <ProductDetails />
              </Container>
            }
          />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:keyword" element={<Products />} />

          <Route path="/cart" element={<Cart />} />

          <Route
            path="/shipping"
            element={
              <ProtectedRoute>
                <Shipping />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/order/confirm"
            element={
              <ProtectedRoute>
                <OrderConfirm />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/process/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/order/success"
            element={<OrderSuccess success={true} />}
          />
          <Route
            path="/order/failed"
            element={<OrderSuccess success={false} />}
          />

          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <MyOrders />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/order_details/:id"
            element={
              <ProtectedRoute>
                <OrderDetails />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <UserDashboard activeTab={"profile"}>
                  <Account />
                </UserDashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/account/update"
            element={
              <ProtectedRoute>
                <UpdateProfile />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/password/update"
            element={
              <ProtectedRoute>
                <UpdatePassword />
              </ProtectedRoute>
            }
          ></Route>

          <Route path="/password/forgot" element={<ForgotPassword />} />

          <Route path="/password/reset/:token" element={<ResetPassword />} />

          <Route
            path="/wishlist"
            element={
              <ProtectedRoute>
                <UserDashboard activeTab={"wishlist"}>
                  <Wishlist />
                </UserDashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/address"
            element={
              <ProtectedRoute>
                <UserDashboard activeTab={"address"}>
                  <ManageAddress />
                </UserDashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/add-address"
            element={
              <ProtectedRoute>
                <AddShippingInfo />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/privacy"
            element={
              <Container>
                <Privacy />
              </Container>
            }
          />

          <Route
            path="/terms-of-service"
            element={
              <Container>
                <TermsofService />{" "}
              </Container>
            }
          />
          <Route
            path="/cancellation"
            element={
              <Container>
                <Cancellation />
              </Container>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Container>
                <ContactUs />{" "}
              </Container>
            }
          />
          <Route
            path="/about-us"
            element={
              <Container>
                <AboutUs />{" "}
              </Container>
            }
          />

          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={0}>
                  <MainData />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/admin/orders"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={1}>
                  <OrderTable />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/admin/order/:id"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={1}>
                  <UpdateOrder />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/admin/products"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={2}>
                  <ProductTable />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/admin/new_product"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={3}>
                  <NewProduct />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/admin/product/:id"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={2}>
                  <UpdateProduct />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/admin/users"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={4}>
                  <UserTable />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/admin/user/:id"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={4}>
                  <UpdateUser />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/admin/reviews"
            element={
              <ProtectedRoute isAdmin={true}>
                <Dashboard activeTab={5}>
                  <ReviewsTable />
                </Dashboard>
              </ProtectedRoute>
            }
          ></Route>

          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </div>

      <Footer />
    </>
  );
}

export default App;
