import { useState } from "react";
import { Link } from "react-router-dom";
import { getDiscount } from "../../utils/functions";
import { DeleteIcon } from "../../utils/iconImports";
import { removeFromWishlist } from "../../actions/wishlistAction";
import { useDispatch, useSelector } from "react-redux";
import { Confirmation } from "../../utils/imports";
import { useSnackbar } from "notistack";
import Rating from "@mui/material/Rating";
import PropTypes from "prop-types";

const WishlistProduct = (props) => {
  const { _id, name, price, discountPrice, images, ratings, numOfReviews } =
    props;

  const { enqueueSnackbar } = useSnackbar();

  const { error } = useSelector((state) => state.wishlist);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const dispatch = useDispatch();

  const handleRemove = () => {
    dispatch(removeFromWishlist(_id));
    if (error) {
      enqueueSnackbar(`Facing some issue ${error}`, { variant: "error" });
    } else {
      enqueueSnackbar("Product removed from wishlist", { variant: "success" });
    }
    setConfirmationOpen(false);
  };

  return (
    <div className=" flex flex-col sm:flex-row justify-between border-b p-4 sm:pb-8 ">
      <div className="flex gap-4 group ">
        <div className=" flex-shrink-0 ">
          <img
            draggable="false"
            className="object-contain h-20 w-20 my-auto rounded-xl"
            src={images[0]?.url}
            alt={name}
          />
        </div>
        <div className="flex flex-col gap-5 p-0.5 md:p-1">
          <div className="flex justify-between sm:pr-5">
            <Link
              to={`/product/${_id}`}
              className="flex flex-col gap-0.5 overflow-auto"
            >
              <p className="group-hover:text-primary-blue w-56 md:w-full break-words">
                {name?.length > 85 ? `${name?.substring(0, 85)}...` : name}
              </p>
              <span className="text-sm text-gray-500 font-poppins500 flex gap-2 items-center">
                <Rating
                  name="read-only"
                  value={ratings}
                  readOnly
                  size="medium"
                  precision={0.5}
                  sx={{
                    color: "#FFC107",
                  }}
                />
                <span>({numOfReviews})</span>
              </span>
            </Link>
          </div>
          <div className="flex items-center gap-2 text-lg font-poppins500">
            <span>₹{discountPrice?.toLocaleString()}</span>
            <span className="text-sm text-gray-500 line-through font-poppins400 mt-1">
              ₹{price?.toLocaleString()}
            </span>
            <span className="text-sm text-primary-green mt-1">
              {getDiscount(price, discountPrice)}%&nbsp;off
            </span>
          </div>
        </div>
      </div>
      <div>
        <button
          className="flex items-center gap-2 text-sm font-poppins600 border rounded-xl text-red-500 px-2 md:px-4 py-1 md:py-2 cursor-pointer hover:bg-red-500 hover:text-white "
          onClick={() => setConfirmationOpen(true)}
        >
          <DeleteIcon />
          <span> Delete </span>
        </button>
      </div>
      <Confirmation
        open={confirmationOpen}
        confirmationText="Are you sure you want to remove this product from your wishlist?"
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleRemove}
        task="Remove item"
      />
    </div>
  );
};

WishlistProduct.propTypes = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  discountPrice: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  ratings: PropTypes.number.isRequired,
  numOfReviews: PropTypes.number.isRequired,
};

export default WishlistProduct;
