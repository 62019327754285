import axios from "axios";
import {
  ALL_ORDERS_FAIL,
  ALL_ORDERS_REQUEST,
  ALL_ORDERS_SUCCESS,
  CLEAR_ERRORS,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  MY_ORDERS_FAIL,
  MY_ORDERS_REQUEST,
  MY_ORDERS_SUCCESS,
  NEW_ORDER_FAIL,
  NEW_ORDER_REQUEST,
  NEW_ORDER_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  EXPORT_ORDERS_REQUEST,
  EXPORT_ORDERS_SUCCESS,
  EXPORT_ORDERS_FAIL,
} from "../constants/orderConstants";

export const newOrder = (order) => async (dispatch) => {
  try {
    dispatch({ type: NEW_ORDER_REQUEST });
    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    const { data } = await axios.post("/api/v1/order/new", order, config);

    dispatch({
      type: NEW_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const myOrders = () => async (dispatch) => {
  try {
    dispatch({ type: MY_ORDERS_REQUEST });
    const token = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: token,
      },
    };
    const { data } = await axios.get("/api/v1/orders/me", config);

    dispatch({
      type: MY_ORDERS_SUCCESS,
      payload: data.orders,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: MY_ORDERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };
    const { data } = await axios.get(`/api/v1/order/${id}`, config);

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data.order,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const cancelOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: CANCEL_ORDER_REQUEST });

    const { data } = await axios.put(`/api/v1/order/${id}`);

    dispatch({
      type: CANCEL_ORDER_SUCCESS,
      payload: data.cancelDate,
    });
  } catch (error) {
    dispatch({
      type: CANCEL_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllOrders =
  (page, pageSize, filters, sort) => async (dispatch) => {
    try {
      dispatch({ type: ALL_ORDERS_REQUEST });

      const token = localStorage.getItem("userToken");

      const config = {
        "Content-Type": "application/json",
        headers: {
          Authorization: token,
        },
      };

      const reqbody = {
        filters,
        sort,
      };

      const { data } = await axios.post(
        `/api/v1/admin/orders?page=${page}&limit=${pageSize}`,
        reqbody,
        config
      );

      dispatch({
        type: ALL_ORDERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_ORDERS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const updateOrder = (id, order) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ORDER_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    const { data } = await axios.put(
      `/api/v1/admin/order/${id}`,
      order,
      config
    );

    dispatch({
      type: UPDATE_ORDER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const deleteOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ORDER_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.delete(`/api/v1/admin/order/${id}`, config);

    dispatch({
      type: DELETE_ORDER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const exportOrdersData = () => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_ORDERS_REQUEST });

    const token = localStorage.getItem("userToken");

    const config = {
      headers: {
        Authorization: token,
      },
    };

    const { data } = await axios.get("/api/v1/admin/orders/export", config);

    dispatch({
      type: EXPORT_ORDERS_SUCCESS,
      payload: data.orderData,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: EXPORT_ORDERS_FAIL,
      payload: error.response.data.message,
    });
  }
};
