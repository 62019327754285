import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";

const ProductDetailsModal = ({ isOpen, onClose, product }) => {
  if (!isOpen) return null;
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-readonly="true"
      aria-label="product-details"
      aria-setsize={100}
      aria-modal="true"
    >
      <DialogTitle>{product?.name}</DialogTitle>
      <DialogContent>
        <div className="flex items-start gap-2">
          <div className="w-40 h-40 ">
            <img
              src={product?.images[0]?.url}
              alt={product?.name}
              className="w-full h-full  object-cover"
            />
          </div>
          <div className="flex flex-col justify-center gap-0.5 md:gap-1.5 font-poppins500 text-sm md:text-base">
            <p>
              <span className="font-poppins600">Product Alias:</span>{" "}
              {product?.alias}
            </p>
            <p>
              <span className="font-poppins600">Item Details:</span>{" "}
              {product?.itemDetails}
            </p>
            <p>
              <span className="font-poppins600">Best Seller:</span>{" "}
              {product?.bestSeller ? "true" : "false"}
            </p>
            <p>
              <span className="font-poppins600">Description:</span>{" "}
              {product?.description}
            </p>
            <p>
              <span className="font-poppins600">Categories:</span>{" "}
              {product?.categories}
            </p>
            <p>
              <span className="font-poppins600">Exams:</span> {product?.exams}
            </p>
            <p>
              <span className="font-poppins600">Author:</span> {product?.author}
            </p>
            <p>
              <span className="font-poppins600">Publisher:</span>{" "}
              {product?.publisher}
            </p>
            <p>
              <span className="font-poppins600">Display Publisher:</span>{" "}
              {product?.displayPublisher}
            </p>
            <p>
              <span className="font-poppins600">Edition:</span>{" "}
              {product?.edition}
            </p>
            <p>
              <span className="font-poppins600">Stock:</span> {product?.stock}
            </p>
            <p>
              <span className="font-poppins600">Price:</span> ₹{product?.price}
            </p>
            <p>
              <span className="font-poppins600">Discount Price:</span> ₹
              {product?.discountPrice}
            </p>
            <p>
              <span className="font-poppins600">Rating:</span> {product?.rating}
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          onClick={onClose}
          className="py-2 px-6 rounded-xl shadow bg-gray-400 hover:bg-gray-500 text-white"
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
};

ProductDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.object,
};

export default ProductDetailsModal;
