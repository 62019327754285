import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import {
  REMOVE_PRODUCT_DETAILS,
  UPDATE_PRODUCT_RESET,
} from "../../constants/productConstants";
import {
  clearErrors,
  getProductDetails,
  updateProduct,
} from "../../actions/productAction";

const useUpdateProduct = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();

  const { loading, product, error } = useSelector(
    (state) => state.productDetails
  );
  const {
    loading: updateLoading,
    isUpdated,
    error: updateError,
  } = useSelector((state) => state.product);

  const [highlights, setHighlights] = useState([]);
  const [highlightInput, setHighlightInput] = useState("");
  const [specs, setSpecs] = useState([]);
  const [specsInput, setSpecsInput] = useState({
    title: "",
    description: "",
  });

  const [name, setName] = useState("");
  const [itemDetails, setItemDetails] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [author, setAuthor] = useState("");
  const [publisher, setPublisher] = useState("");
  const [displayPublisher, setDisplayPublisher] = useState("");
  const [edition, setEdition] = useState("");
  const [alias, setAlias] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [stock, setStock] = useState(0);
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [selectedExams, setSelectedExams] = useState([]);
  const [bestSeller, setBestSeller] = useState(false);
  const [tags, setTags] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);

  const productId = params.id;

  const handleSpecsChange = (e) => {
    setSpecsInput({ ...specsInput, [e.target.name]: e.target.value });
  };

  const addSpecs = () => {
    if (!specsInput.title.trim() || !specsInput.description.trim()) return;
    setSpecs([...specs, specsInput]);
    setSpecsInput({ title: "", description: "" });
  };

  const addHighlight = () => {
    if (!highlightInput.trim()) return;
    setHighlights([...highlights, highlightInput]);
    setHighlightInput("");
  };

  const deleteHighlight = (index) => {
    setHighlights(highlights.filter((h, i) => i !== index));
  };

  const deleteSpec = (index) => {
    setSpecs(specs.filter((s, i) => i !== index));
  };

  const handleProductImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((oldData) => [...oldData, reader.result]);
          setImages((oldData) => [...oldData, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleDeleteOldImage = (public_id) => {
    setOldImages(oldImages.filter((img) => img.public_id !== public_id));
    setImages(images.filter((img) => img !== public_id));
    setDeletedImages([...deletedImages, public_id]);
  };

  const handleDeletePreviewImage = (url) => {
    setImagesPreview(imagesPreview.filter((img) => img !== url));
    setImages(images.filter((img) => img !== url));
  };

  const updateProductSubmitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.set("name", name);
    formData.set("itemDetails", itemDetails);
    formData.set("description", description);
    formData.set("price", price);
    formData.set("stock", stock);
    formData.set("discount", discount);
    formData.set("author", author);
    formData.set("publisher", publisher);
    formData.set("displayPublisher", displayPublisher);
    formData.set("edition", edition);
    formData.set("alias", alias);
    formData.set("bestSeller", bestSeller);

    selectedCategories.forEach((category) => {
      formData.append("categories", category);
    });

    selectedExams.forEach((exam) => {
      formData.append("exams", exam);
    });

    tags.forEach((tag) => {
      formData.append("tags", tag);
    });

    images.forEach((image) => {
      formData.append("images", image);
    });

    deletedImages.forEach((id) => {
      formData.append("deletedImages", id);
    });

    highlights.forEach((h) => {
      formData.append("highlights", h);
    });

    specs.forEach((s) => {
      formData.append("specifications", JSON.stringify(s));
    });

    dispatch(updateProduct(productId, formData));
  };

  useEffect(() => {
    if (product?._id !== productId) {
      dispatch(getProductDetails(productId));
    } else {
      setName(product?.name);
      setItemDetails(product?.itemDetails);
      setDescription(product?.description);
      setPrice(product?.price);
      const disc = Math.round(
        ((product?.price - product?.discountPrice) / product?.price) * 100
      );
      setDiscount(disc);
      setStock(product?.stock);
      setSelectedCategories(product?.categories);
      setAuthor(product?.author);
      setPublisher(product?.publisher);
      setDisplayPublisher(product?.displayPublisher);
      setHighlights(product?.highlights);
      setSpecs(product?.specifications);
      setOldImages(product?.images);
      setEdition(product?.edition);
      setSelectedExams(product?.exams);
      setAlias(product?.alias);
      setBestSeller(product?.bestSeller);
      setTags(product?.tags);
    }
  }, [dispatch, productId, product]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (updateError) {
      enqueueSnackbar(` ${updateError}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (isUpdated) {
      enqueueSnackbar("Product Updated Successfully", {
        variant: "success",
      });
      dispatch({ type: UPDATE_PRODUCT_RESET });
      dispatch({ type: REMOVE_PRODUCT_DETAILS });
      navigate("/admin/products");
    }
  }, [error, updateError, isUpdated, navigate, enqueueSnackbar, dispatch]);

  return {
    loading,
    highlights,
    highlightInput,
    specs,
    specsInput,
    selectedExams,
    name,
    description,
    price,
    alias,
    discount,
    selectedCategories,
    stock,
    author,
    publisher,
    oldImages,
    imagesPreview,
    edition,
    bestSeller,
    updateLoading,
    tags,
    displayPublisher,
    itemDetails,
    setSelectedCategories,
    setItemDetails,
    setDisplayPublisher,
    setStock,
    setAuthor,
    setPublisher,
    setEdition,
    setAlias,
    setDiscount,
    setPrice,
    setDescription,
    setName,
    setBestSeller,
    setHighlightInput,
    setSelectedExams,
    setTags,
    addHighlight,
    deleteHighlight,
    handleSpecsChange,
    addSpecs,
    deleteSpec,
    handleProductImageChange,
    updateProductSubmitHandler,
    handleDeleteOldImage,
    handleDeletePreviewImage,
  };
};

export default useUpdateProduct;
