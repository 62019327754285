//eslint-disable-next-line
import Chart from "chart.js/auto";
import { Doughnut, Line, Pie, Bar } from "react-chartjs-2";
import { MetaData, BackdropLoader } from "../../utils/imports";
import useMainData from "../../hooks/admin/mainData";

const MainData = () => {
  const {
    totalAmount,
    lineState,
    pieState,
    doughnutState,
    barState,
    usersCount,
    productsCount,
    ordersCount,
    loading,
  } = useMainData();

  return (
    <>
      {loading && <BackdropLoader />}
      <MetaData title="Admin Dashboard | BooksDehra" />
      <div className="flex flex-col items-center gap-2 ">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-6  ">
          <div className="flex flex-col bg-purple-600 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
            <div className="text-gray-100 font-poppins500">
              Total Sales Amount
            </div>
            <div className="text-2xl font-poppins600">
              ₹{totalAmount?.toLocaleString()}
            </div>
          </div>
          <div className="flex flex-col bg-red-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
            <div className="text-gray-100 font-poppins500">Total Orders</div>
            <div className="text-2xl font-poppins600">{ordersCount}</div>
          </div>
          <div className="flex flex-col bg-yellow-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
            <div className="text-gray-100 font-poppins500">Total Products</div>
            <div className="text-2xl font-poppins600">{productsCount}</div>
          </div>
          <div className="flex flex-col bg-green-500 text-white gap-2 rounded-xl shadow-lg hover:shadow-xl p-6">
            <div className="text-gray-100 font-poppins500">Total Users</div>
            <div className="text-2xl font-poppins600">{usersCount}</div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-3 md:gap-8 min-w-full">
          <div className="bg-white rounded-xl h-auto w-full shadow-lg p-2">
            <Line data={lineState} />
          </div>

          <div className="bg-white rounded-xl shadow-lg p-4 text-center">
            <span className="font-poppins500 uppercase text-gray-800">
              Order Status
            </span>
            <Pie data={pieState} />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-3 md:gap-8 min-w-full mb-6">
          <div className="bg-white rounded-xl min-h-full w-full shadow-lg p-2 text-xs">
            <Bar data={barState} />
          </div>

          <div className="bg-white rounded-xl shadow-lg p-4 text-center">
            <span className="font-poppins500 uppercase text-gray-800">
              Stock Status
            </span>
            <Doughnut data={doughnutState} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainData;
