import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { saveShippingInfo, clearErrors } from "../../actions/addressAction";
import { useNavigate } from "react-router-dom";

const useAddShippingInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("IN");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const { error } = useSelector((state) => state.address);

  const saveAddress = async (e) => {
    e.preventDefault();

    if (phoneNo.length < 10 || phoneNo.length > 11) {
      enqueueSnackbar("Invalid Phone Number", {
        variant: "error",
      });
      return;
    }
    dispatch(
      saveShippingInfo({ address, city, country, state, pincode, phoneNo })
    );
    if (error) {
      enqueueSnackbar(`Facing some issue ${error}`, { variant: "error" });
    } else {
      enqueueSnackbar("Address Saved successfully", {
        variant: "success",
      });
    }
    navigate("/address");
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
  }, [error, enqueueSnackbar, dispatch]);

  return {
    address,
    setAddress,
    city,
    setCity,
    country,
    setCountry,
    state,
    setState,
    pincode,
    setPincode,
    phoneNo,
    setPhoneNo,
    saveAddress,
  };
};

export default useAddShippingInfo;
