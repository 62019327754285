import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProducts } from "../../actions/productAction";
import { enqueueSnackbar } from "notistack";

const useSearch = () => {
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const currentKeyword = query.get("keyword");
    if (currentKeyword !== keyword) {
      setKeyword(currentKeyword || "");
    }
    // eslint-disable-next-line
  }, [location.search]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const str = keyword.trim();
    if (str) {
      if (location.pathname === "/products") {
        dispatch(getProducts(str, "", false, "", [0, 50000], 0, 1));
        const query = new URLSearchParams();
        query.set("keyword", str);
        navigate(`?${query.toString()}`);
      } else {
        navigate(`/products?keyword=${str}`);
      }
    } else {
      enqueueSnackbar("Please enter a search keyword", { variant: "error" });
    }
  };

  const handleClear = () => {
    setKeyword("");
    if (location.pathname === "/products") {
      dispatch(getProducts("", "", false, "", [0, 50000], 0, 1));
    }
  };

  return {
    keyword,
    setKeyword,
    handleSubmit,
    handleClear,
  };
};

export default useSearch;
