import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMainData } from "../../actions/productAction";

import { months } from "../../utils/constants";
import { categoryNames } from "../../utils/adminConstants";

const useMainData = () => {
  const dispatch = useDispatch();

  const {
    loading,
    totalAmount,
    productsCount,
    inStock,
    outOfStock,
    ordersCount,
    usersCount,
    orders,
    categoryCounts,
  } = useSelector((state) => state.mainData);

  useEffect(() => {
    dispatch(getMainData());
  }, [dispatch]);

  const date = new Date();
  const lineState = {
    labels: months,
    datasets: [
      {
        label: `Sales in ${date.getFullYear() - 2}`,
        borderColor: "#8A39E1",
        backgroundColor: "#8A39E1",
        data: months.map((m, i) =>
          orders
            ?.filter(
              (od) =>
                new Date(od.createdAt).getMonth() === i &&
                new Date(od.createdAt).getFullYear() === date.getFullYear() - 2
            )
            .reduce((total, od) => total + od.totalPrice, 0)
        ),
      },
      {
        label: `Sales in ${date.getFullYear() - 1}`,
        borderColor: "orange",
        backgroundColor: "orange",
        data: months.map((m, i) =>
          orders
            ?.filter(
              (od) =>
                new Date(od.createdAt).getMonth() === i &&
                new Date(od.createdAt).getFullYear() === date.getFullYear() - 1
            )
            .reduce((total, od) => total + od.totalPrice, 0)
        ),
      },
      {
        label: `Sales in ${date.getFullYear()}`,
        borderColor: "#4ade80",
        backgroundColor: "#4ade80",
        data: months.map((m, i) =>
          orders
            ?.filter(
              (od) =>
                new Date(od.createdAt).getMonth() === i &&
                new Date(od.createdAt).getFullYear() === date.getFullYear()
            )
            .reduce((total, od) => total + od.totalPrice, 0)
        ),
      },
    ],
  };

  const statuses = ["Processing", "Shipped", "Delivered", "Cancelled"];

  const pieState = {
    labels: statuses,
    datasets: [
      {
        backgroundColor: ["#9333ea", "#facc15", "#4ade80", "#ef4444"],
        hoverBackgroundColor: ["#a855f7", "#fde047", "#86efac", "#f87171"],
        data: statuses.map(
          (status) =>
            orders?.filter((item) => item.orderStatus === status).length
        ),
      },
    ],
  };

  const doughnutState = {
    labels: ["Out of Stock", "In Stock"],
    datasets: [
      {
        backgroundColor: ["#ef4444", "#22c55e"],
        hoverBackgroundColor: ["#dc2626", "#16a34a"],
        data: [outOfStock, inStock],
      },
    ],
  };

  const barState = {
    labels: categoryNames,
    datasets: [
      {
        label: "Products",
        borderColor: "#9333ea",
        backgroundColor: "#9333ea",
        hoverBackgroundColor: "#6b21a8",
        data: categoryNames.map(
          (name) => categoryCounts?.find((item) => item._id === name)?.count
        ),
      },
    ],
  };

  return {
    totalAmount,
    lineState,
    pieState,
    doughnutState,
    barState,
    usersCount,
    productsCount,
    ordersCount,
    loading,
  };
};

export default useMainData;
