import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { logoutUser } from "../../actions/userAction";
import {
  ShoppingBagIcon,
  FavoriteIcon,
  HomeIcon,
} from "../../utils/iconImports";

const usePrimaryDropdown = (setTogglePrimaryDropDown, user) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { wishlistItems } = useSelector((state) => state.wishlist);

  const userToken = localStorage.getItem("userToken");

  const handleLogout = async () => {
    if (!userToken) return;
    await dispatch(logoutUser());
    enqueueSnackbar("Logout Successfully", {
      variant: "success",
    });

    navigate("/login");
    setTogglePrimaryDropDown(false);
  };
  const orderRedirect = userToken ? "/orders" : "/login";
  const wishlistRedirect = userToken ? "/wishlist" : "/login";
  const addressRedirect = userToken ? "/address" : "/login";

  const navs = [
    {
      title: "Orders",
      icon: <ShoppingBagIcon sx={{ fontSize: "18px" }} />,
      redirect: orderRedirect,
    },
    {
      title: "Wishlist",
      icon: <FavoriteIcon sx={{ fontSize: "18px" }} />,
      redirect: wishlistRedirect,
    },
    {
      title: "Addresses",
      icon: <HomeIcon sx={{ fontSize: "18px" }} />,
      redirect: addressRedirect,
    },
  ];

  return {
    handleLogout,
    wishlistItems,
    navs,
  };
};

export default usePrimaryDropdown;
