import {
  FolderIcon,
  AccountCircleIcon,
  PowerSettingsNewIcon,
  FolderSharedIcon,
  PersonIcon,
  ChevronRightIcon,
} from "../../utils/iconImports";
import { Link } from "react-router-dom";
import useSidebar from "../../hooks/user/sidebar";
import { Confirmation } from "../../utils/imports";
import PropTypes from "prop-types";

const Sidebar = ({ activeTab, isMobile, closeSidebar, toggleSidebar }) => {
  const {
    confirmationOpen,
    user,
    setConfirmationOpen,
    handleLogout,
    handleClick,
  } = useSidebar(isMobile, closeSidebar);

  return (
    <div
      className={`flex flex-col gap-1 md:gap-3 mt-4 bg-primary-lightestBlue shadow rounded-2xl   ${
        isMobile
          ? `absolute top-4 sm:top-1 left-0 bottom-0 z-20 inset-0 px-1 w-3/4 max-h-[90vh] transform transition-transform duration-300 ease-in-out ${
              toggleSidebar ? "translate-x-0" : "-translate-x-full"
            }`
          : "w-1/4"
      }  `}
    >
      <div className="flex flex-col gap-2 mt-2 lg:mt-4">
        <div className="flex items-center gap-1 xl:gap-4 p-1 md:p-2 bg-primary-lightBlue rounded-lg shadow-lg my-1 mx-1 lg:my-2 lg:mx-3">
          <div className="rounded-full text-primary-blue">
            <AccountCircleIcon style={{ fontSize: "3rem" }} />
          </div>
          <div className="flex items-baseline text-sm xl:text-base text-wrap  ">
            <p className="font-poppins500 break-words">
              Hello,&nbsp;{user.name}
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 mt-2 xl:mt-6 font-poppins400">
          <div className="flex items-baseline gap-5 p-2 md:p-4 border-b">
            <span className="text-primary-blue ">
              <FolderIcon />
            </span>
            <Link
              className="flex items-center justify-between font-poppins500 text-gray-500 hover:text-primary-blue"
              to="/orders"
              onClick={handleClick}
            >
              <span className="text-sm xl:text-base">My Orders</span>
              <span className="text-primary-blue">
                <ChevronRightIcon />
              </span>
            </Link>
          </div>

          <div className="flex items-baseline gap-5 p-2 xl:p-4">
            <span className="text-primary-blue">
              <PersonIcon />
            </span>
            <p className="flex text-sm xl:text-base justify-between font-poppins500 text-gray-500">
              Account Settings
            </p>
          </div>
          <div className="flex flex-col pb-0 xl:pb-3 border-b text-xs xl:text-sm">
            <Link
              to="/account"
              onClick={handleClick}
              className={`${
                activeTab === "profile"
                  ? "bg-primary-lightBlue font-poppins500"
                  : "hover:bg-primary-lightBlue hover:font-poppins500"
              } py-3 pl-8 md:pl-14 `}
            >
              Profile Information
            </Link>
            <Link
              className={`${
                activeTab === "address"
                  ? "bg-primary-lightBlue font-poppins500"
                  : "hover:bg-primary-lightBlue  hover:font-poppins500 "
              } py-3 pl-8 md:pl-14 `}
              to="/address"
              onClick={handleClick}
            >
              Manage Addresses
            </Link>
          </div>

          <div className="flex items-center gap-5 p-2 xl:p-4">
            <span className="text-primary-blue">
              <FolderSharedIcon />
            </span>
            <p className="flex justify-between font-poppins500 text-gray-500 text-sm xl:text-base">
              My Stuff
            </p>
          </div>
          <div className="flex flex-col pb-0 xl:pb-3 border-b text-xs xl:text-sm">
            <Link
              to="/wishlist"
              onClick={handleClick}
              className={`${
                activeTab === "wishlist"
                  ? "bg-primary-lightBlue font-poppins500"
                  : "hover:bg-primary-lightBlue hover:font-poppins500"
              } py-3 pl-8 md:pl-14`}
            >
              My Wishlist
            </Link>
            <Link
              to="/cart"
              onClick={handleClick}
              className={`${
                activeTab === "cart"
                  ? "bg-primary-lightBlue  font-poppins500"
                  : "hover:bg-primary-lightBlue hover:font-poppins500"
              } py-3 pl-8 md:pl-14`}
            >
              My Cart
            </Link>
          </div>

          <div className="flex items-center gap-5 p-2 xl:p-4 ">
            <span className="text-primary-blue">
              <PowerSettingsNewIcon />
            </span>
            <button
              className="flex justify-between font-poppins500 text-gray-500 hover:text-primary-blue cursor-pointer text-sm xl:text-base"
              onClick={() => setConfirmationOpen(true)}
            >
              Logout
            </button>
            <Confirmation
              open={confirmationOpen}
              confirmationText="Are you sure you want to logout?"
              onClose={() => setConfirmationOpen(false)}
              onConfirm={handleLogout}
              task="Logout"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggleSidebar: PropTypes.bool,
  isMobile: PropTypes.bool,
  closeSidebar: PropTypes.func,
};

export default Sidebar;
