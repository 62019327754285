import { Step, StepLabel, Stepper } from "@mui/material";
import { CircleIcon } from "../../utils/iconImports";
import { formatDate } from "../../utils/functions";
import PropTypes from "prop-types";

const TrackStepper = ({
  activeStep,
  orderOn,
  shippedAt,
  deliveredAt,
  cancelledAt,
}) => {
  const steps = [
    {
      status: "Ordered",
      dt: formatDate(orderOn),
    },
    {
      status: "Shipped",
      dt: formatDate(shippedAt),
    },
    {
      status: "Delivered",
      dt: formatDate(deliveredAt),
    },
  ];

  const completedIcon = (
    <span className="text-primary-green animate-pulse">
      <CircleIcon sx={{ fontSize: "16px" }} />
    </span>
  );
  const pendingIcon = (
    <span className="text-gray-400">
      <CircleIcon sx={{ fontSize: "16px" }} />
    </span>
  );

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {activeStep === 3 && (
        <Step active={activeStep === 3}>
          <StepLabel icon={pendingIcon}>
            <div className="flex flex-col">
              <span className="text-red-500 font-poppins500">Cancelled</span>
              {cancelledAt && (
                <span className="text-red-500 font-poppins500">
                  {cancelledAt}
                </span>
              )}
            </div>
          </StepLabel>
        </Step>
      )}
      {activeStep !== 3 &&
        steps.map((item, index) => (
          <Step
            key={item.status}
            active={activeStep === index}
            completed={activeStep >= index}
          >
            <StepLabel icon={activeStep >= index ? completedIcon : pendingIcon}>
              {activeStep >= index ? (
                <div className="flex flex-col">
                  <span className="text-primary-green font-poppins500">
                    {item?.status}
                  </span>
                  {item.dt !== "Invalid Date" && (
                    <span className="text-primary-green font-poppins500">
                      {item?.dt}
                    </span>
                  )}
                </div>
              ) : (
                <span className="text-gray-400 font-poppins500">
                  {item?.status}
                </span>
              )}
            </StepLabel>
          </Step>
        ))}
    </Stepper>
  );
};

TrackStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  orderOn: PropTypes.string.isRequired,
  shippedAt: PropTypes.string,
  deliveredAt: PropTypes.string,
  cancelledAt: PropTypes.string,
};

export default TrackStepper;
