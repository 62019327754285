import React, { useState } from "react";
import { exams, colors } from "../../../utils/constants";
import { Link } from "react-router-dom";
import { ExpandMoreIcon, ExpandLessIcon } from "../../../utils/iconImports";
import PropTypes from "prop-types";

const ExamCard = ({ exam, index }) => {
  return (
    <div
      className="h-full flex flex-col items-center rounded-2xl cursor-pointer py-5 px-3 lg:px-0 lg:py-0 2xl:whitespace-nowrap lg:overflow-hidden lg:text-wrap"
      style={{
        backgroundColor: colors[index % colors.length],
      }}
    >
      <p className="text-base sm:text-lg md:text-3xl lg:text-5xl font-poppins700 text-center text-primary-brown my-0 md:my-8 lg:my-32 mx-0 md:mx-24 lg:mx-24">
        {exam.toUpperCase()}
      </p>
    </div>
  );
};

ExamCard.propTypes = {
  exam: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

const Exams = () => {
  const [showAllExams, setShowAllExams] = useState(false);
  const initialExamCount = 5;
  const visibleExams = showAllExams ? exams : exams.slice(0, initialExamCount);

  const handleViewMore = () => {
    setShowAllExams(true);
  };

  const handleViewLess = () => {
    setShowAllExams(false);
  };

  return (
    <div className="flex flex-col gap-6 xl:gap-14" id="exams">
      <div className="flex flex-col gap-6 xl:gap-10">
        <div className="text-xl sm:text-3xl md:text-5xl lg:text-6xl  xl:text-7xl font-poppins600 text-primary-brown ">
          Shop by exams
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-6 ">
          {!showAllExams &&
            visibleExams.map((exam, index) => (
              <Link
                key={exam}
                to={`/products?exam=${exam}`}
                className={`${exam.length > 15 ? "col-span-2" : ""}`}
              >
                <ExamCard exam={exam} index={index} />
              </Link>
            ))}
          {showAllExams &&
            exams.map((exam, index) => (
              <Link
                key={exam}
                to={`/products?exam=${exam}`}
                className={`${exam.length > 15 ? "col-span-2" : ""}`}
              >
                <ExamCard exam={exam} index={index} />
              </Link>
            ))}
        </div>
      </div>
      <div className="flex justify-center">
        {!showAllExams && exams.length > initialExamCount && (
          <button
            className="w-1/5 text-base md:text-base lg:text-lg font-poppins700 rounded-full py-3 px-14 sm:px-0 border border-gray-950 hover:text-white hover:bg-black transition-all duration-150 ease-in-out hover:shadow-xl tracking-normal sm:tracking-wider flex justify-center items-center group"
            onClick={handleViewMore}
          >
            <span className="text-nowrap"> VIEW ALL </span>
            <ExpandMoreIcon className="text-2xl" />
          </button>
        )}
        {showAllExams && (
          <button
            className="w-1/5 text-base md:text-base lg:text-lg font-poppins700 rounded-full py-3 px-14 sm:px-0 border border-gray-950 hover:text-white hover:bg-black transition-all duration-150 ease-in-out hover:shadow-xl tracking-normal sm:tracking-wider flex justify-center items-center group"
            onClick={handleViewLess}
          >
            <span className="text-nowrap"> VIEW LESS </span>
            <ExpandLessIcon className="text-2xl" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Exams;
