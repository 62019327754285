import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { clearErrors, loginUser } from "../../actions/userAction";
import { useSnackbar } from "notistack";

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const { loading, isAuthenticated, error } = useSelector(
    (state) => state.user
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    dispatch(loginUser(email, password));
    if (loading) enqueueSnackbar("Logging in", { variant: "info" });

    if (!loading && isAuthenticated) {
      enqueueSnackbar("Logged in successfully", { variant: "success" });
    }
  };
  const redirect = location.search ? location.search.split("=")[1] : "";

  useEffect(() => {
    if (error) {
      enqueueSnackbar(` ${error}`, {
        variant: "error",
      });
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      navigate(`/${redirect}`);
    }
  }, [dispatch, error, isAuthenticated, redirect, navigate, enqueueSnackbar]);

  return {
    email,
    setEmail,
    password,
    showPassword,
    handleShowPassword,
    setPassword,
    handleLogin,
    loading,
    isAuthenticated,
  };
};

export default useLogin;
